import * as React from 'react';
import { Text } from './input/text';
import { FinanceModelData, FinanceModel, ModelInput, CheckoutModel } from './model';
import { CheckBox } from './input/checkbox';
import { translate as trans } from '../../lang';
import { InfoTextGroup } from '../santander.core';
import { InputProps, Input } from './input/input';
import { autobind } from '../../decorator';
import { ExchangeForm } from './exchangeForm';
import { Row } from './common';

interface Props {
  model: FinanceModelData;
  change: (key: keyof FinanceModel, model: ModelInput) => void;
  blur: (key: keyof FinanceModel) => void;
  createBooking: () => void;
  disabled: boolean;
  consentTexts: InfoTextGroup | null | undefined;
  hideInputs?: boolean;
  showExchange?: boolean;
}

export function SantanderForm(props: Props) {
  const { model, change, blur, disabled, consentTexts, hideInputs, showExchange } = props;

  return (
    <div className='empori-checkout-form'>
      <div className='empori-form-desc' dangerouslySetInnerHTML={{ __html: trans('finance.form.infoHtml') }}></div>

      <Row>
        <Text<FinanceModel>
          label={trans('finance.form.pnr')}
          id='orgNr'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled}
          placeholder={trans('finance.form.pnr.placeholder')} />

        <Text<FinanceModel>
          label={trans('finance.form.email')}
          id='email'
          type='email'
          model={model}
          autoComplete='email'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />
      </Row>

      <Row>
        <Text<FinanceModel>
          label={trans('finance.form.phone')}
          id='phone'
          model={model}
          autoComplete='tel'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />

        <Text<FinanceModel>
          label={trans('finance.form.deliveryDate')}
          id='deliveryDate'
          model={model}
          type='date'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />
      </Row>

      {showExchange && <ExchangeForm {...props} />}

      <Row>
        <div className='empori-gdpr-container'>
          <CheckBox<FinanceModel>
            label={trans('finance.form.gdprLabel')}
            id='gdpr'
            model={model}
            change={change}
            blur={blur}
            disabled={disabled}
            required={true}
            text={trans('finance.form.gdprTerms')} />

          {consentTexts && <>
            <Consent
              textGroup={consentTexts}
              label='Medgivande Santander'
              id='consent'
              model={model}
              change={change}
              disabled={disabled}
              required={true} />
          </>}
        </div>
      </Row>

      <Row>
        <input
          className={`bankid-input ${hideInputs ? 'disable-button' : '' }`}
          type='submit'
          disabled={disabled}
          onClick={e => {
            e.preventDefault();
            props.createBooking();
          }}
          value={trans('finance.form.send')} />

        <img className='bankid' src='//cdn.empori.se/vendor/santander/bankid-logo.png' />
      </Row>

    </div>
  );
}

interface ConsentProps extends InputProps<FinanceModel> {
  textGroup: InfoTextGroup;
}

interface ConsentState {
  model: Partial<ConsentModel>;
}

interface ConsentObj {
  [key: string]: ModelInput | undefined;
}

interface ConsentModel extends CheckoutModel {
  texts: ConsentObj;
}

class Consent extends React.Component<ConsentProps, ConsentState> {

  constructor(props: ConsentProps) {
    super(props);
    this.state = {
      model: {}
    };
  }

  @autobind
  handleChange(key: string, model: ModelInput) {
    this.setState({
      model: {
        ...this.state.model,
        texts: {
          ...this.state.model.texts,
          [key]: model
        }
      }
    }, () => {
      const keys = this.props.textGroup.texts.map(tg => this.state.model.texts?.[tg.id + '']?.value);

      this.props.change(this.props.id, {
        ...model,
        value: (keys.length === this.props.textGroup.texts.length && keys.every(k => k === 'true')) + ''
      });
    });
  }

  render() {
    return (
      <Input {...this.props} cls='empori-consent-container'>
        {this.props.textGroup.texts.map(tg =>
          <CheckBox<ConsentModel>
            key={tg.id}
            label={tg.name}
            id={tg.id as any}
            model={this.state.model.texts ?? {} as any}
            change={this.handleChange}
            disabled={this.props.disabled}
            text={tg.text} />
        )}
      </Input>
    );
  }
}
