export enum PaymentMethod {
  finance,
  cash
}

export interface CheckoutModel {
  orgNr: string;
  email: string;
  phone: string;
  deliveryDate: string;
  gdpr: string;
  exchange?: string;
  exchangeRegNr?: string;
  exchangeMilage?: string;
  exchangeMessage?: string;
}

export interface FinanceModel extends CheckoutModel {
  consent: string;
}

export interface CashModel extends CheckoutModel {
  firstname: string;
  lastname: string;
  address: string;
  zipcode: string;
  city: string;
}

export interface ModelInput {
  dirty: boolean;
  required: boolean;
  initialValue: string;
  value?: string;
  error?: string | null;
}

export type CheckoutModelData<T extends CheckoutModel> = {
  [key in keyof T]?: ModelInput
};

export type FinanceModelData = CheckoutModelData<FinanceModel>;
export type CashModelData = CheckoutModelData<CashModel>;