import * as React from 'react';
import * as core from '../../model/core';
import * as api from '../../api/vehicleFilterApi';
import { KeyCodes, stringListSeparator, setBaseUrl, getEnumTypes, className } from '../../util';
import { FaIcon } from '../../icon';
import { autobind, debounce } from '../../decorator';
import { SortObject, OrderOptions, parseSortOrder, sortOrderToString } from './sorting';
import * as query from '../../query';
import { ViewType, getViewHandler } from '../handler';
import { SearchResult } from './searchResult';
import { SearchResultSkeleton } from '../../skeleton';
import { FilterOptions, ExtraFilterOptions } from './filterOptions';
import { Checkbox } from './checkbox';
import { FilterCategory } from './filterCategory';
import { queryStringSchema, defaultQueryParams, setGroupQuery } from './query';
import { ConditionSelection } from './conditionSelection';
import { TypeSelection } from './typeSelection';
import { translate as trans, translateFormat as transFormat, setLanguage } from '../../lang';
import { ViewToggler } from './viewToggler';

export interface ObjectActionCallback {
  (object: core.Vehicle): string | undefined | null;
}

export interface FilterSectionSettings {
  query?: Partial<core.ObjectQueryParameters>;
  groups?: string[];
  searchButton?: (queryString: string, queryObject: Partial<core.ObjectQueryParameters>) => string | undefined | null;
  showSearchResult?: boolean;
  objectClick?: ObjectActionCallback;
  objectLink?: ObjectActionCallback;
  vehicleTypes?: (string | number)[];
}

export interface SelectObjectCallback {
  (object: core.Vehicle): void;
}

export interface ObjectFilterViewProps {
  selectObject: SelectObjectCallback;
  scrollPos: number;
  settings?: FilterSectionSettings;
  setView?: (listview: boolean) => void;
  config: core.GeneralConfig;
}

export interface ObjectFilterViewState {
  objects: core.Vehicle[];
  queryParams: Partial<core.ObjectQueryParameters>;
  filterData?: core.FilterData;
  loading: boolean;
  showExtraFilter: boolean;
  listView: boolean;
  pageTitle: string | undefined;
}

export class ObjectFilterView extends React.Component<ObjectFilterViewProps, ObjectFilterViewState> {

  private showSearchResult: boolean;
  private currentQuery?: string | null;

  constructor(props: ObjectFilterViewProps) {
    super(props);

    this.showSearchResult = props.settings?.showSearchResult ?? true;

    const queryParams = this.createInitialQuery(props);

    // Always override and set group when given externally.
    if (props.settings?.groups?.length) {
      setGroupQuery(props.settings.groups, queryParams);
    }

    this.state = {
      objects: [],
      loading: true,
      showExtraFilter: false,
      queryParams,
      listView: localStorage.getItem('dsoViewToggle') === null
        ? !props.config.defaultGridView
        : localStorage.getItem('dsoViewToggle') === 'list',
      pageTitle: document.querySelector('title')?.text
    };
  }

  createInitialQuery(props: ObjectFilterViewProps) {
    const queryParams = defaultQueryParams(props);

    const prevView = getViewHandler().getView();
    const viewParams = query.queryStringToObject<core.ObjectQueryParameters>(prevView?.query ?? '', queryStringSchema);

    // Use query from current url.
    if (viewParams && Object.keys(viewParams).length > 0) {
      return { ...queryParams, ...viewParams };
    }

    const initialQuery = props.settings?.query;

    // Use query from script settings.
    if (initialQuery && Object.keys(initialQuery).length > 0) {
      return { ...queryParams, ...initialQuery };
    }

    return queryParams;
  }

  async componentDidMount() {
    await this.fetchFilterData();
    await this.rehydrateObjects();

    if (this.props.scrollPos > 0) {
      setTimeout(() => {
        window.scrollTo({ top: this.props.scrollPos, left: 0 });
      }, 50);
    }
  }

  async fetchFilterData() {
    const vehicleTypes = getEnumTypes(core.VehicleType, this.props.settings?.vehicleTypes ?? []);

    const filterData = await api.getFilterData({
      groups: this.state.queryParams.groups,
      vehicleTypes: vehicleTypes.length > 0 ? vehicleTypes : void 0
    });

    if (filterData) {
      let { type } = this.state.queryParams;

      if (!type && filterData.types?.length > 0) {
        type = filterData.types[0].type;
      }

      if (filterData.language) {
        setLanguage(filterData.language);
      }

      if (filterData.baseUrl) {
        setBaseUrl(filterData.baseUrl);
      }

      this.setState({
        filterData,
        queryParams: {
          ...this.state.queryParams,
          type
        }
      });
    }
  }

  async rehydrateObjects() {
    // No need to fetch objects if we are not showing results.
    if (!this.showSearchResult) {
      return;
    }

    const { queryParams } = this.state;
    const queryString = query.objectToQueryString(queryParams);

    if (this.currentQuery === queryString) {
      return;
    }

    getViewHandler().setView(ViewType.ObjectFilter, queryString);
    this.currentQuery = queryString;

    const result = await api.getObjects(queryParams);
    const objects = result?.items ?? [];

    this.setState({ objects, loading: false });
  }

  @debounce(300)
  async debouncedRehydrateObjects() {
    await this.rehydrateObjects();
  }

  @autobind
  handleSortOrder(sorting: SortObject) {
    this.setState({
      queryParams: {
        ...this.state.queryParams,
        sortOrder: sortOrderToString(sorting)
      }
    }, () => this.rehydrateObjects());
  }

  @autobind
  handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    this.setState({
      queryParams: {
        ...this.state.queryParams,
        searchText: e.target.value
      }
    }, () => this.debouncedRehydrateObjects());
  }

  @autobind
  handleSearchTextKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === KeyCodes.enter) {
      e.preventDefault();
    }
  }

  @autobind
  handleResetFilter(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    const savedVehicleType = this.state.queryParams.type;

    this.setState({
      queryParams: {...defaultQueryParams(this.props, this.state), type: savedVehicleType}
    }, () => this.rehydrateObjects());
  }

  @autobind
  handleToggleExtraFilter(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.setState({ showExtraFilter: !this.state.showExtraFilter });
  }

  toggleStringList(key: keyof core.ObjectQueryParameters, value: string) {
    const items = (this.state.queryParams[key] as string | undefined ?? '')
      .split(stringListSeparator)
      .filter(v => v.length > 0);

    const index = items.indexOf(value);

    if (index >= 0) {
      items.splice(index, 1);
    } else {
      if (items.length) {
        items.push(value);
      } else {
        items[0] = value;
      }
    }

    this.setState({
      queryParams: {
        ...this.state.queryParams,
        [key]: items.join(stringListSeparator)
      }
    }, () => this.rehydrateObjects());
  }

  @autobind
  handleCategoryToggle(cat: core.FilterCategory) {
    return () => {
      this.toggleStringList('categories', cat.id);
    };
  }

  @autobind
  handleUpdateFilterOptions(updatedQuery: Partial<core.ObjectQueryParameters>) {
    this.setState({ queryParams: updatedQuery }, () => this.rehydrateObjects());
  }

  @autobind
  handleConditionSelect(condition: core.Condition) {
    this.setState({
      queryParams: {
        ...this.state.queryParams,
        condition
      }
    }, () => this.rehydrateObjects());
  }

  @autobind
  handleSearchButtonClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    const queryString = query.objectToQueryString(this.state.queryParams);
    const buttonLink = this.props.settings?.searchButton?.(queryString, this.state.queryParams);

    if (buttonLink) {
      window.location.href = buttonLink;
    }
  }

  @autobind
  handleVehicleTypeChange(type: core.VehicleType) {
    if (type === this.state.queryParams.type) {
      return;
    }

    this.setState({
      queryParams: {
        ...defaultQueryParams(this.props, this.state),
        type
      }
    }, () => this.rehydrateObjects());
  }

  render() {
    const { settings } = this.props;
    const { objects, filterData, queryParams, loading, showExtraFilter } = this.state;
    const { searchText, campaignOnly, sortOrder, categories, condition } = queryParams;

    const sorting = parseSortOrder(sortOrder);
    const selectedCategories = (categories ?? '').split(stringListSeparator);
    const objectLink = settings?.objectLink;

    const vehicleType = filterData?.types.find(t => t.type === queryParams.type);

    // Returns true if there is only one vehicle type, thus no need to show TypeSelection
    const containsOneType = filterData?.types.every(v => v === filterData?.types[0]);

    // Might need other logic - hide "Show more filter alternatives" button if there are no extra filters
    const showExtraFilterButton =
    (vehicleType?.type !== core.VehicleType.boat) &&
    (vehicleType?.type !== core.VehicleType.seascooter);

    let canonical: HTMLLinkElement | null = !!document.querySelector("link[rel='canonical']")
      ? document.querySelector("link[rel='canonical']")
      : document.createElement('link');

    if (canonical) {
      canonical.setAttribute('rel', 'canonical');
      canonical.setAttribute('href', window.location.origin + window.location.pathname);

      if (!document.querySelector("link[rel='canonical']")) {
        document.head.appendChild(canonical);
      }
    }

    let followMeta: HTMLMetaElement | null = !!document.querySelector("meta[name='robots']")
      ? document.querySelector("meta[name='robots']")
      : document.createElement('meta');

    if (followMeta) {
      followMeta.setAttribute('name', 'robots');
      followMeta.setAttribute('content', 'index, follow');

      if (!document.querySelector("meta[name='robots']")) {
        document.head.appendChild(followMeta);
      }
    }

    return (
      <div className='dso-article-filter-container'>
        <div className='dso-object-filter-container'>
          <div className='dso-filter-sections'>
            {!containsOneType && <>
              <div className='dso-filter-section'>
                {vehicleType && <>
                  <TypeSelection
                    allTypes={filterData!.types}
                    selectedType={vehicleType.type}
                    select={this.handleVehicleTypeChange} />
                </>}
              </div>
            </>}

            <div className='dso-filter-section'>
              <div className={className(
                'dso-filter-categories',
                {'dso-lots-of-categories': vehicleType && vehicleType?.categories.length > 5})}>
                {vehicleType && vehicleType.categories.map(cat =>
                  <FilterCategory
                    key={cat.id}
                    {...cat}
                    click={this.handleCategoryToggle(cat)}
                    selected={selectedCategories.indexOf(cat.id) !== -1}
                    vehicleType={vehicleType.type} />)}
              </div>
            </div>

            <div className='dso-filter-section'>
              {vehicleType && vehicleType.type === core.VehicleType.car ? <>
                {/* Any other filter alternative for cars here? */}
              </> :
                <ConditionSelection
                  selectedCondition={condition ?? core.Condition.all}
                  select={this.handleConditionSelect} />
              }

              <div className={className(
                'dso-search-container',
                {'dso-search-fullwidth': vehicleType && vehicleType.type === core.VehicleType.car})}>
                <FaIcon name='search' />
                <input
                  type='text'
                  className='dso-freetext-search'
                  placeholder={trans('filter.searchPlaceholder')}
                  value={searchText ?? ''}
                  onKeyDown={this.handleSearchTextKeyDown}
                  onChange={this.handleSearchTextChange} />
              </div>
            </div>

            <div className='dso-filter-section'>
              <FilterOptions
                key={queryParams.type}
                queryParams={queryParams}
                vehicleType={+queryParams.type!}
                filterData={filterData}
                update={this.handleUpdateFilterOptions} />
            </div>

            {showExtraFilterButton &&
              <div className='dso-filter-section'>
                <ExtraFilterOptions
                  key={queryParams.type}
                  visible={showExtraFilter}
                  queryParams={queryParams}
                  vehicleType={+queryParams.type!}
                  filterData={filterData}
                  update={this.handleUpdateFilterOptions} />
              </div>
            }

            <div className='dso-filter-section'>
              <div className='dso-filter-buttons'>
                <div className='dso-filter-button-group'>
                  {showExtraFilterButton &&
                    <a href='#' className='dso-toggle-extra-filter' onClick={this.handleToggleExtraFilter}>
                      <FaIcon name='binoculars' /> {showExtraFilter ? <>
                        <span className='dso-filter-hide'>{trans('filter.extra.hide')}
                          <FaIcon name={'angle-up'} />
                        </span>
                      </> : <>
                        <span>{trans('filter.extra.show')}
                          <FaIcon name={'angle-down'} />
                        </span>
                      </>}
                    </a>}
                  <a href='#' className='dso-reset-filter' onClick={this.handleResetFilter}>
                    <FaIcon name='trash' /> {trans('filter.reset')}
                  </a>
                </div>
              </div>

              <Checkbox
                checked={campaignOnly ?? false}
                label={transFormat('filter.campaignOnly', trans(
                  this.state.queryParams.type === core.VehicleType.boat ||
                  this.state.queryParams.type === core.VehicleType.seascooter
                    ? 'general.object'
                    : 'general.vehicle'
                ).toLocaleLowerCase())}
                toggle={() => {
                  this.setState({
                    queryParams: {
                      ...this.state.queryParams,
                      campaignOnly: !campaignOnly
                    }
                  }, () => this.rehydrateObjects());
                }} />
            </div>
          </div>

          <div className='dso-filter-section'>
            {settings?.searchButton && <>
              <div className='dso-filter-button-group'>
                <button
                  className='dso-search-button'
                  onClick={this.handleSearchButtonClick}
                  title={trans('filter.search.title')}>

                  <FaIcon name='search' /> {trans('filter.search')}
                </button>
              </div>
            </>}
          </div>
        </div>

        {this.showSearchResult && <>
          <div className='dso-middle-container'>
            <OrderOptions
              vehicleType={vehicleType?.type}
              onSort={this.handleSortOrder}
              selectedSorting={sorting} />

            <ViewToggler
              setView={(e) => this.setState({ listView: e }) }
              listView={this.state.listView} />

            {objects.length > 0
              ? <div className='dso-result-count'>{
                transFormat('filter.search.result', objects.length, trans(
                  queryParams.type === core.VehicleType.boat ||
                  queryParams.type === core.VehicleType.seascooter
                    ? 'general.object'
                    : 'general.vehicle'
                ).toLocaleLowerCase())}</div>
              : null}
          </div>

          {loading ? <>
            <SearchResultSkeleton />
          </> : <>
          {vehicleType && filterData &&
            <SearchResult
              objects={objects}
              selectObject={this.props.selectObject}
              objectLink={objectLink}
              objectType={queryParams.type}
              listView={!this.state.listView}
              config={this.props.config}
              objectTypeStr={vehicleType.name}
              language={filterData.language} />}
          </>}
        </>}
      </div>
    );
  }
}
