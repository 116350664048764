function push(obj: any) {
  (window.dataLayer || (window.dataLayer = [])).push(obj);
}

function formSubmit(formType: string) {
  push({ event: 'formSubmit', formType });
}

export function interestFormSubmit() {
  formSubmit('intresse');
}

export function cashCheckoutFormSubmit() {
  formSubmit('kontantkop');
}

export function financeCheckoutFormSubmit() {
  formSubmit('finansiering-kop');
}