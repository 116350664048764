import * as React from 'react';
import * as core from '../../model/core';
import * as api from '../../api/vehicleFilterApi';
import { SelectObjectCallback, ObjectActionCallback } from '../filter/objectFilterView';
import { SearchResult } from '../filter/searchResult';
import { SearchResultSkeleton } from '../../skeleton';
import { setLanguage } from '../../lang';

interface Props {
  selectObject: SelectObjectCallback;
  objectLink: ObjectActionCallback;
  query?: Partial<core.ObjectQueryParameters>;
}

interface State {
  config: core.GeneralConfig | null;
  loading: boolean;
  objects: core.Vehicle[];
}

export class QueryObjects extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      config: null,
      loading: true,
      objects: []
    };
  }

  componentDidMount() {
    this.fetchDsoSettings();
    this.fetchObjects();
  }

  async fetchObjects() {
    const result = await api.getObjects(this.props.query ?? {});
    const objects = result?.items ?? [];
    const lang = result?.language;

    if (lang) {
      setLanguage(lang);
    }

    this.setState({ objects, loading: false });
  }

  async fetchDsoSettings() {
    const config = await api.getGeneralConfig();
    this.setState({ config: config });
  }

  render() {
    const { selectObject, objectLink } = this.props;
    const { objects, loading } = this.state;

    // Temporary solution for box-viewing the query results
    const vehicleType = objects.map(t => t.type);

    if (loading) {
      return <SearchResultSkeleton />;
    }

    return (
      <div className='dso-chosen-objects'>
        {this.state.config &&
          <SearchResult
            objects={objects}
            selectObject={selectObject}
            objectLink={objectLink}
            objectType={vehicleType[0]}
            listView={this.state.config.defaultGridView}
            config={this.state.config} />}
      </div>
    );
  }
}