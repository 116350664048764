import * as React from 'react';
import * as core from '../../model/core';
import { stringListSeparator, className } from '../../util';
import { MultiSelectOption, OptionItemData, IntervalSelectOption } from './option';
import { translate as trans, translateAny } from '../../lang';

interface FilterOptionsProps {
  queryParams: Partial<core.ObjectQueryParameters>;
  vehicleType: core.VehicleType;
  filterData?: core.FilterData;
  update: (queryParams: Partial<core.ObjectQueryParameters>) => void;
}

interface StringListSelection {
  all: string[];
  selected: string[];
}

type IntervalSelection = {
  [P in keyof core.ObjectQueryParameters]?: (interval: core.FilterIntervalOption) => number | undefined | null;
};

function stringListSelection<T extends core.FilterDataType>(
  filterData: T,
  queryParams: Partial<core.ObjectQueryParameters>) {

  return function (key: keyof T & keyof core.ObjectQueryParameters) {
    const all = filterData[key] as string[] ?? [];
    const selected = (queryParams[key] as string)?.split(/[;\|]+/g) ?? [];

    if (selected.length === 1 && !selected[0]) {
      selected.length = 0;
    }

    return { all, selected } as StringListSelection;
  };
}

function handleListSelect(props: FilterOptionsProps, key: keyof core.ObjectQueryParameters) {
  return (values: any[]) => {
    props.update({
      ...props.queryParams,
      [key]: values.join(stringListSeparator)
    });
  };
}

function handleIntervalSelect(props: FilterOptionsProps, obj: IntervalSelection) {
  return (interval: core.FilterIntervalOption) => {
    const queryParam = { ...props.queryParams };
    Object.keys(obj).forEach(key => {
      (queryParam as any)[key] = (obj as any)[key](interval);
    });
    props.update(queryParam);
  };
}

export function FilterOptions(props: FilterOptionsProps) {
  if (!props.filterData) {
    return null;
  }

  const filterType = props.filterData.types.find(t => t.type === props.vehicleType);

  if (!filterType) {
    return null;
  }

  const getListSelection = stringListSelection(filterType, props.queryParams);
  const filialSelection = getListSelection('filials');
  const brandSelection = getListSelection('brands');
  const layoutsSelection = getListSelection('layouts');

  return (
    <div className='dso-filter-options'>

      {filterType.priceInterval &&
        <IntervalSelectOption
          label={trans('filter.input.price')}
          select={handleIntervalSelect(props, {
            priceFrom: interval => interval.min,
            priceTo: interval => interval.max
          })}
          selectedInterval={{
            ...filterType.priceInterval,
            min: props.queryParams.priceFrom,
            max: props.queryParams.priceTo
          }}
          interval={{
            ...filterType.priceInterval,
            unit: trans('filter.input.price.unit')
          }} />}

      {(props.vehicleType === core.VehicleType.caravan || props.vehicleType === core.VehicleType.mobileHome) &&
        <MultiSelectOption
          label={trans('filter.input.layouts')}
          items={layoutsSelection.all.map(name => ({
            label: translateAny('filter.input.layouts.' + name.toLowerCase()),
            value: name.toLowerCase()
          } as OptionItemData))}
          selectedValues={layoutsSelection.selected}
          select={handleListSelect(props, 'layouts')} />}

      <MultiSelectOption
        label={trans('filter.input.brand')}
        items={brandSelection.all.map(name => ({
          label: name,
          value: name.toLowerCase()
        } as OptionItemData))}
        selectedValues={brandSelection.selected}
        select={handleListSelect(props, 'brands')} />

      {filterType.modelYearInterval &&
        <IntervalSelectOption
          label={trans('filter.input.year')}
          select={handleIntervalSelect(props, {
            yearFrom: interval => interval.min,
            yearTo: interval => interval.max
          })}
          selectedInterval={{
            ...filterType.modelYearInterval,
            min: props.queryParams.yearFrom,
            max: props.queryParams.yearTo
          }}
          interval={filterType.modelYearInterval} />}

      {filterType.weightInterval &&
      (props.vehicleType !== core.VehicleType.seascooter) &&
      (props.vehicleType !== core.VehicleType.boat) &&
        <IntervalSelectOption
          label={trans('filter.input.weight')}
          select={handleIntervalSelect(props, {
            weightMin: interval => interval.min,
            weightMax: interval => interval.max
          })}
          selectedInterval={{
            ...filterType.weightInterval,
            min: props.queryParams.weightMin,
            max: props.queryParams.weightMax
          }}
          interval={filterType.weightInterval} />}

      {filterType.filials.length > 0 &&
        <MultiSelectOption
          label={trans('filter.input.filial')}
          items={filialSelection.all.map(name => ({
            label: name,
            value: name.toLowerCase()
          } as OptionItemData))}
          selectedValues={filialSelection.selected}
          select={handleListSelect(props, 'filials')} />}

      {props.vehicleType === core.VehicleType.boat &&
        <BoatExtraFilterOptions visible={true}
          {...{ ...props, filterType: filterType as core.BoatFilterDataType }} />}

      {props.vehicleType === core.VehicleType.seascooter &&
        <SeascooterExtraFilterOptions visible={true}
          {...{ ...props, filterType: filterType as core.SeascooterFilterDataType }} />}

    </div>
  );
}

interface ExtraFilterOptionsProps extends FilterOptionsProps {
  visible: boolean;
}

export function ExtraFilterOptions(props: ExtraFilterOptionsProps) {
  const { visible, vehicleType, filterData } = props;
  const filterType = filterData?.types.find(t => t.type === vehicleType);

  if (!filterType) {
    return null;
  }

  const getOptions = () => {
    switch (vehicleType) {
      case core.VehicleType.caravan:
        return <CaravanExtraFilterOptions
          {...{ ...props, filterType: filterType as core.CaravanFilterDataType }} />;

      case core.VehicleType.mobileHome:
        return <MobileHomeExtraFilterOptions
          {...{ ...props, filterType: filterType as core.MobileHomeFilterDataType }} />;

      case core.VehicleType.car:
        return <CarExtraFilterOptions
          {...{ ...props, filterType: filterType as core.CarFilterDataType }} />;

      case core.VehicleType.transport:
        return <TransportExtraFilterOptions
          {...{ ...props, filterType: filterType as core.TransportFilterDataType }} />;

      case core.VehicleType.snowmobile:
        return <SnowmobileExtraFilterOptions
          {...{ ...props, filterType: filterType as core.SnowmobileFilterDataType }} />;

      case core.VehicleType.trailer:
        return <TrailerExtraFilterOptions
          {...{ ...props, filterType: filterType as core.TrailerFilterDataType }} />;

        // "Extra" filter options are moved to the visible area

        // case core.VehicleType.boat:
        //   return <BoatExtraFilterOptions
        //     {...{ ...props, filterType: filterType as core.BoatFilterDataType }} />;

        // case core.VehicleType.seascooter:
        //   return <SeascooterExtraFilterOptions
        //     {...{ ...props, filterType: filterType as core.SeascooterFilterDataType }} />;

      case core.VehicleType.mcAtv:
        return <McAtvExtraFilterOptions
          {...{ ...props, filterType: filterType as core.McAtvFilterDataType }} />;

      default:
        return null;
    }
  };

  return (
    <div className={className('dso-filter-options dso-extra-options', { 'dso-visible': visible })}>
      {getOptions()}
    </div>
  );
}

interface VehicleExtraFilterProps<T extends core.FilterDataType> extends ExtraFilterOptionsProps {
  filterType: T;
}

function CaravanExtraFilterOptions(props: VehicleExtraFilterProps<core.CaravanFilterDataType>) {
  const getListSelection = stringListSelection(props.filterType, props.queryParams);
  const heatingSelection = getListSelection('heating');

  return (
    <>
      <IntervalSelectOption
        label={trans('filter.input.beds')}
        select={handleIntervalSelect(props, {
          bedsMin: interval => interval.min,
          bedsMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.bedsInterval,
          min: props.queryParams.bedsMin,
          max: props.queryParams.bedsMax
        }}
        interval={{
          ...props.filterType.bedsInterval,
          unit: trans('filter.input.amount.unit')
        }} />

      <IntervalSelectOption
        label={trans('filter.input.length')}
        select={handleIntervalSelect(props, {
          lengthMin: interval => interval.min,
          lengthMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.lengthInterval,
          min: props.queryParams.lengthMin,
          max: props.queryParams.lengthMax
        }}
        interval={props.filterType.lengthInterval} />

      <IntervalSelectOption
        label={trans('filter.input.width')}
        select={handleIntervalSelect(props, {
          widthMin: interval => interval.min,
          widthMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.widthInterval,
          min: props.queryParams.widthMin,
          max: props.queryParams.widthMax
        }}
        interval={props.filterType.widthInterval} />

      <IntervalSelectOption
        label={trans('filter.input.height')}
        select={handleIntervalSelect(props, {
          heightMin: interval => interval.min,
          heightMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.heightInterval,
          min: props.queryParams.heightMin,
          max: props.queryParams.heightMax
        }}
        interval={props.filterType.heightInterval} />

      {heatingSelection.all.length > 0 && <>
        <MultiSelectOption
          label={trans('filter.input.heating')}
          items={heatingSelection.all.map(name => ({
            label: name,
            value: name.toLowerCase()
          } as OptionItemData))}
          selectedValues={heatingSelection.selected}
          select={handleListSelect(props, 'heating')} />
      </>}
    </>
  );
}

function MobileHomeExtraFilterOptions(props: VehicleExtraFilterProps<core.MobileHomeFilterDataType>) {
  const getListSelection = stringListSelection(props.filterType, props.queryParams);
  const gearboxSelection = getListSelection('gearbox');
  const heatingSelection = getListSelection('heating');

  return (
    <>
      <IntervalSelectOption
        label={trans('filter.input.beds')}
        select={handleIntervalSelect(props, {
          bedsMin: interval => interval.min,
          bedsMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.bedsInterval,
          min: props.queryParams.bedsMin,
          max: props.queryParams.bedsMax
        }}
        interval={{
          ...props.filterType.bedsInterval,
          unit: trans('filter.input.amount.unit')
        }} />

      <IntervalSelectOption
        label={trans('filter.input.passengers')}
        select={handleIntervalSelect(props, {
          passengersMin: interval => interval.min,
          passengersMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.passengerInterval,
          min: props.queryParams.passengersMin,
          max: props.queryParams.passengersMax
        }}
        interval={{
          ...props.filterType.passengerInterval,
          unit: trans('filter.input.amount.unit')
        }} />

      <MultiSelectOption
        label={trans('filter.input.gearbox')}
        items={gearboxSelection.all.map(name => ({
          label: translateAny('filter.input.gearbox.' + name.toLowerCase()),
          value: name.toLowerCase()
        } as OptionItemData))}
        selectedValues={gearboxSelection.selected}
        select={handleListSelect(props, 'gearbox')} />

      <IntervalSelectOption
        label={trans('filter.input.milage')}
        select={handleIntervalSelect(props, {
          milageMin: interval => interval.min,
          milageMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.milageInterval,
          min: props.queryParams.milageMin,
          max: props.queryParams.milageMax
        }}
        interval={{
          ...props.filterType.milageInterval,
          unit: trans('filter.input.milage.unit')
        }} />

      <IntervalSelectOption
        label={trans('filter.input.length')}
        select={handleIntervalSelect(props, {
          lengthMin: interval => interval.min,
          lengthMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.lengthInterval,
          min: props.queryParams.lengthMin,
          max: props.queryParams.lengthMax
        }}
        interval={props.filterType.lengthInterval} />

      <IntervalSelectOption
        label={trans('filter.input.width')}
        select={handleIntervalSelect(props, {
          widthMin: interval => interval.min,
          widthMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.widthInterval,
          min: props.queryParams.widthMin,
          max: props.queryParams.widthMax
        }}
        interval={props.filterType.widthInterval} />

      <IntervalSelectOption
        label={trans('filter.input.height')}
        select={handleIntervalSelect(props, {
          heightMin: interval => interval.min,
          heightMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.heightInterval,
          min: props.queryParams.heightMin,
          max: props.queryParams.heightMax
        }}
        interval={props.filterType.heightInterval} />

      <IntervalSelectOption
        label={trans('filter.input.effect')}
        select={handleIntervalSelect(props, {
          effectFrom: interval => interval.min,
          effectTo: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.effectInterval,
          min: props.queryParams.effectFrom,
          max: props.queryParams.effectTo
        }}
        interval={{
          ...props.filterType.effectInterval,
          unit: trans('filter.input.effect.unit')
        }} />

      {heatingSelection.all.length > 0 && <>
        <MultiSelectOption
          label={trans('filter.input.heating')}
          items={heatingSelection.all.map(name => ({
            label: name,
            value: name.toLowerCase()
          } as OptionItemData))}
          selectedValues={heatingSelection.selected}
          select={handleListSelect(props, 'heating')} />
      </>}
    </>
  );
}

function CarExtraFilterOptions(props: VehicleExtraFilterProps<core.CarFilterDataType>) {
  const getListSelection = stringListSelection(props.filterType, props.queryParams);
  const gearboxSelection = getListSelection('gearbox');
  const fuelSelection = getListSelection('fuel');

  return (
    <>
      <MultiSelectOption
        label={trans('filter.input.gearbox')}
        items={gearboxSelection.all.map(name => ({
          label: name,
          value: name.toLowerCase()
        } as OptionItemData))}
        selectedValues={gearboxSelection.selected}
        select={handleListSelect(props, 'gearbox')} />

      <MultiSelectOption
        label={trans('filter.input.fuel')}
        items={fuelSelection.all.map(name => ({
          label: name,
          value: name.toLowerCase()
        } as OptionItemData))}
        selectedValues={fuelSelection.selected}
        select={handleListSelect(props, 'fuel')} />

      <IntervalSelectOption
        label={trans('filter.input.milage')}
        select={handleIntervalSelect(props, {
          milageMin: interval => interval.min,
          milageMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.milageInterval,
          min: props.queryParams.milageMin,
          max: props.queryParams.milageMax
        }}
        interval={props.filterType.milageInterval} />

      <IntervalSelectOption
        label={trans('filter.input.effect')}
        select={handleIntervalSelect(props, {
          effectFrom: interval => interval.min,
          effectTo: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.effectInterval,
          min: props.queryParams.effectFrom,
          max: props.queryParams.effectTo
        }}
        interval={props.filterType.effectInterval} />
    </>
  );
}

function TransportExtraFilterOptions(props: VehicleExtraFilterProps<core.TransportFilterDataType>) {
  return <CarExtraFilterOptions {...props} />;
}

function SnowmobileExtraFilterOptions(props: VehicleExtraFilterProps<core.SnowmobileFilterDataType>) {
  return (
    <>
      <IntervalSelectOption
        label={trans('filter.input.milage')}
        select={handleIntervalSelect(props, {
          milageMin: interval => interval.min,
          milageMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.milageInterval,
          min: props.queryParams.milageMin,
          max: props.queryParams.milageMax
        }}
        interval={props.filterType.milageInterval} />

      <IntervalSelectOption
        label={trans('filter.input.effect')}
        select={handleIntervalSelect(props, {
          effectFrom: interval => interval.min,
          effectTo: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.effectInterval,
          min: props.queryParams.effectFrom,
          max: props.queryParams.effectTo
        }}
        interval={props.filterType.effectInterval} />
    </>
  );
}

function TrailerExtraFilterOptions(props: VehicleExtraFilterProps<core.TrailerFilterDataType>) {
  return (
    <>
      <IntervalSelectOption
        label={trans('filter.input.length')}
        select={handleIntervalSelect(props, {
          lengthMin: interval => interval.min,
          lengthMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.lengthInterval,
          min: props.queryParams.lengthMin,
          max: props.queryParams.lengthMax
        }}
        interval={props.filterType.lengthInterval} />
    </>
  );
}

function McAtvExtraFilterOptions(props: VehicleExtraFilterProps<core.McAtvFilterDataType>) {
  return (
    <>
      <IntervalSelectOption
        label={trans('filter.input.milage')}
        select={handleIntervalSelect(props, {
          milageMin: interval => interval.min,
          milageMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.milageInterval,
          min: props.queryParams.milageMin,
          max: props.queryParams.milageMax
        }}
        interval={props.filterType.milageInterval} />

      <IntervalSelectOption
        label={trans('filter.input.effect')}
        select={handleIntervalSelect(props, {
          effectFrom: interval => interval.min,
          effectTo: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.effectInterval,
          min: props.queryParams.effectFrom,
          max: props.queryParams.effectTo
        }}
        interval={props.filterType.effectInterval} />
    </>
  );
}

function BoatExtraFilterOptions(props: VehicleExtraFilterProps<core.BoatFilterDataType>) {
  return (
    <>
      <IntervalSelectOption
        label={trans('filter.input.length')}
        select={handleIntervalSelect(props, {
          lengthMin: interval => interval.min,
          lengthMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.lengthInterval,
          min: props.queryParams.lengthMin,
          max: props.queryParams.lengthMax
        }}
        interval={props.filterType.lengthInterval} />

      <IntervalSelectOption
        label={trans('filter.input.effect')}
        select={handleIntervalSelect(props, {
          effectFrom: interval => interval.min,
          effectTo: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.effectInterval,
          min: props.queryParams.effectFrom,
          max: props.queryParams.effectTo
        }}
        interval={props.filterType.effectInterval} />

      <IntervalSelectOption
        label={trans('filter.input.passengers')}
        select={handleIntervalSelect(props, {
          passengersMin: interval => interval.min,
          passengersMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.passengerInterval,
          min: props.queryParams.passengersMin,
          max: props.queryParams.passengersMax
        }}
        interval={{
          ...props.filterType.passengerInterval,
          unit: trans('filter.input.amount.unit')
        }} />

      <IntervalSelectOption
        label={trans('filter.input.beds')}
        select={handleIntervalSelect(props, {
          bedsMin: interval => interval.min,
          bedsMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.bedsInterval,
          min: props.queryParams.bedsMin,
          max: props.queryParams.bedsMax
        }}
        interval={{
          ...props.filterType.bedsInterval,
          unit: trans('filter.input.amount.unit')
        }} />
    </>
  );
}

function SeascooterExtraFilterOptions(props: VehicleExtraFilterProps<core.SeascooterFilterDataType>) {
  return (
    <>
      <IntervalSelectOption
        label={trans('filter.input.length')}
        select={handleIntervalSelect(props, {
          lengthMin: interval => interval.min,
          lengthMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.lengthInterval,
          min: props.queryParams.lengthMin,
          max: props.queryParams.lengthMax
        }}
        interval={props.filterType.lengthInterval} />

      <IntervalSelectOption
        label={trans('filter.input.effect')}
        select={handleIntervalSelect(props, {
          effectFrom: interval => interval.min,
          effectTo: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.effectInterval,
          min: props.queryParams.effectFrom,
          max: props.queryParams.effectTo
        }}
        interval={props.filterType.effectInterval} />

      <IntervalSelectOption
        label={trans('filter.input.passengers')}
        select={handleIntervalSelect(props, {
          passengersMin: interval => interval.min,
          passengersMax: interval => interval.max
        })}
        selectedInterval={{
          ...props.filterType.passengerInterval,
          min: props.queryParams.passengersMin,
          max: props.queryParams.passengersMax
        }}
        interval={{
          ...props.filterType.passengerInterval,
          unit: trans('filter.input.amount.unit')
        }} />
    </>
  );
}