import * as React from 'react';
import { BuyOnlineType, ICostCalculation } from '../../model/core';

export const Row: React.FC = props =>
  <div className='empori-checkout-row'>{props.children}</div>;

export interface CheckoutProps {
  articleNr: string;
  costCalc: ICostCalculation;
  showExchange?: boolean;
  buyType: BuyOnlineType;
  showFinancingFirst?: boolean;
}