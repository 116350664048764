import * as React from 'react';
import * as core from '../../model/core';
import { renderToStaticMarkup } from 'react-dom/server';
import { printToPdf } from '../../api/vehicleFilterApi';
import { Specification } from './specification';
import { translate, translateFormat as transFormat } from '../../lang';
import { formatNumber } from '../../util';
import { DescListItem, DivideDesc, EquipmentItem } from './objectView';
import { Seller } from './form/seller';
import { CalculationSummary, CapLoanRange } from './paymentCalculator';
import { InitialPriceCalc } from '../priceCalculations';

function paintHtml(
  vehicle: core.VehicleExtended,
  config: core.GeneralConfig,
  effectiveRate?: number) {

  const baseUrl = vehicle.baseUrl;
  const vehicleLink = window.location.href; // Needs rework in order to work with favourites
  const styleUrl = baseUrl + '/dso/style.css';

  const logo = () => {
    const logoPath = config.print.logoPath;

    if (logoPath.length === 0) {
      return '';
    }
    if (logoPath.includes('http')) {
      return logoPath;
    }
    return baseUrl + logoPath;
  };

  const imageSource = vehicle.images
    ? vehicle.images
      .map(img => img.includes('http') ? img : baseUrl + img)
      .map(img => img.slice(0, img.indexOf('?mw')).replace('/i/', '/'))
    : null;

  const dividedDesc = DivideDesc({desc: vehicle.description, lang: vehicle.language});
  let descriptionItems = vehicle.descriptionEquipments;

  if (vehicle.description && (vehicle.type !== core.VehicleType.car) && dividedDesc.shortItems) {
    descriptionItems = vehicle.descriptionEquipments.concat(dividedDesc.shortItems);
  }

  const calc = InitialPriceCalc({
    price: vehicle.price,
    salePrice: vehicle.salePrice ?? 0,
    calcValues: vehicle.costCalculationValues
  });;

  const vehicleMaxAge = CapLoanRange(
    vehicle.costCalculationValues.vehicleMaxAge,
    vehicle.year,
    new Date()
  );

  const html = (<>
    <html>
      <head>
        <title>{vehicle.title + ' - Page 1'}</title>
        <link href="https://cdn.empori.se/fontawesome/css/all.min.css" rel="stylesheet" />
        <link rel='stylesheet' href={styleUrl} />
        <meta charSet='UTF-8'/>
      </head>
      <body id='dsoMainContainer'>
        <div className='dso-print-container dso-object-container'>

          <div className='header'>
            <div className='title'>
              <h1>{vehicle.title}</h1>
            </div>
            {logo() && <img src={logo()} />}
          </div>

          {imageSource &&
            <div className='image-gallery'>
              {imageSource.length >= 3
                ?
                <div className='several-images'>
                  <div className='image first-image'>
                    <img src={imageSource[0]} />
                  </div>
                  <div className='gallery-content'>
                    <div className='image middle-image'>
                      <img src={imageSource[1]} />
                    </div>
                    <div className='image last-image'>
                      <img src={imageSource[2]} />
                    </div>
                  </div>
                </div>
                :
                <div className='image one-image'>
                  <img src={imageSource[0]} />
                </div>}
            </div>}

          {vehicle.adInfo &&
            <div className='dso-object-adinfo-container'>
              <h2>{translate('object.descHeader')}</h2>
              <div dangerouslySetInnerHTML={{ __html: vehicle.adInfo }}></div>
            </div>}

          {vehicle.filial &&
            <div className='dso-object-top-bar'>
              <div className='filial-container'>
                {transFormat('object.filialDesc', translate(
                  vehicle.type === core.VehicleType.boat ||
                  vehicle.type === core.VehicleType.seascooter
                    ? 'general.object'
                    : 'general.vehicle'
                ))}
                <strong> {vehicle.filial}</strong>
              </div>
            </div>}

          <div className='dso-object-main-container'>
            <div className='dso-company-info'>
              {vehicle.dealershipName &&
                <div className='company'>{vehicle.dealershipName}</div>}
              {vehicle.mainAddress &&
                <div>{vehicle.mainAddress}</div>}
              {vehicle.mainPhoneNumber &&
                <div><a href={`tel:${vehicle.mainPhoneNumber}`}>{vehicle.mainPhoneNumber}</a></div>}
              {vehicle.mainEmailAddress &&
                <div><a href={`mailto:${vehicle.mainEmailAddress}`}>{vehicle.mainEmailAddress}</a></div>}
            </div>

            <div className='dso-object-price-container'>
              <div className='dso-object-price'>
                {transFormat('object.price', formatNumber(calc.price))}
              </div>

              {vehicle.equipment && vehicle.equipment.length > 0 && <>
                <div className='dso-object-price-details'>
                  {translate('object.priceDetails')}
                </div>
              </>}

              {config.suggestedMonthCost &&
                <div className='dso-object-price-month'>
                  {transFormat('object.pricePerMonth',
                    (calc.monthlyCost >= vehicle.costCalculationValues.monthlyPaymentMinimum
                      ? formatNumber(calc.monthlyCost)
                      : formatNumber(vehicle.costCalculationValues.monthlyPaymentMinimum) ) )}
                </div>}

              {((vehicleMaxAge === null) || (vehicleMaxAge && vehicleMaxAge.financingAvailable)) &&
              (vehicle.buyOnline < core.BuyOnlineType.finance) ?
                <div className='dso-portal-content-calculation'>
                  <span className='calculation-title'>{translate('payment.financing.suggestion')}</span>
                  <div className='dso-portal-content-calculation-field'>
                    <span>
                      {transFormat('payment.calcInfo.downPayment', vehicle.costCalculationValues.downPaymentMinimum)}
                    </span>
                    <span className='value'>
                      {formatNumber(calc.downPayment)  + ' ' + translate('payment.default.currency')}
                    </span>
                  </div>
                  <div className='dso-portal-content-calculation-field'>
                    <span>
                      {translate('payment.calcInfo.loan')}
                    </span>
                    <span className='value'>
                      {formatNumber(calc.price - calc.downPayment) + ' ' + translate('payment.default.currency')}
                    </span>
                  </div>
                  <div className='dso-portal-content-calculation-field'>
                    <span>
                      {translate('payment.calcInfo.interestCost')}
                    </span>
                    <span className='value'>
                      {formatNumber(calc.totalInterestCost) + ' ' + translate('payment.default.currency')}
                    </span>
                  </div>
                  <div className='dso-portal-content-calculation-field total'>
                    <span>
                      {translate('payment.calcInfo.total')}
                    </span>
                    <span className='value'>
                      {formatNumber(calc.price + calc.totalInterestCost) + ' ' + translate('payment.default.currency')}
                    </span>
                  </div>
                  <div className='dso-portal-content-calculation-summary'>
                    {vehicle.language === 'nb' && effectiveRate ?
                      <CalculationSummary
                        show={true}
                        downPaymentPercent={vehicle.costCalculationValues.initialDownPayment}
                        nominalRate={vehicle.costCalculationValues.loanInterest}
                        effectiveRate={effectiveRate}
                        loanAmount={calc.totalLoan}
                        months={calc.months}
                        totalCreditCost={calc.totalCreditCost} />
                      :
                      transFormat('payment.calcInfo.summary',
                        formatNumber(calc.totalLoan), calc.months,
                        formatNumber(calc.monthlyCost), vehicle.costCalculationValues.loanInterest)}
                  </div>
                </div>:
                <div className='dso-financing-not-available'>
                  <span>{translate('payment.financing.notAvaiable')}</span>
                </div>}
            </div>
          </div>
        </div>
      </body>
    </html>
    <html>
      <head>
        <title>{vehicle.title + ' - Page 2'}</title>
        <link href="https://cdn.empori.se/fontawesome/css/all.min.css" rel="stylesheet" />
        <link rel='stylesheet' href={styleUrl} />
        <meta charSet='UTF-8'/>
      </head>
      <body id='dsoMainContainer'>
        <div className='dso-print-container dso-object-container'>

          <div className='header'>
            <div className='title'>
              <h1>{vehicle.title}</h1>
            </div>
            {logo() !== '' && <img src={logo()} />}
          </div>

          <Specification
            vehicle={vehicle}
            showArticleNr={config.showArticleNr} />

          {(vehicle.equipment && vehicle.equipment.length > 0) ||
          (descriptionItems && descriptionItems.length > 0) ?
            <h2 className='equipment-title'>{translate('object.equipmentTitle')}</h2> : ''}

          {vehicle.equipment && vehicle.equipment.length > 0 &&
            <div className='dso-object-extra-equipment-container'>
              <div className='dso-object-extra-equipment-list'>
                {vehicle.equipment.map(item =>
                  <EquipmentItem key={item.articleNr} item={item} />)}
              </div>
              <div className='dso-object-equipment-total-price-container'>
                <span className='dso-object-equipment-total-price-desc'>
                  {transFormat('object.equipmentDesc', translate(
                    vehicle.type === core.VehicleType.boat ||
                      vehicle.type === core.VehicleType.seascooter
                      ? 'general.object'
                      : 'general.vehicle'
                  ).toLocaleLowerCase())}
                </span>
                <span className='dso-object-equipment-total-price-sum'>{formatNumber(
                  vehicle.equipment.reduce(
                    (prev, cur) => prev + cur.price, 0)
                )} {translate('payment.default.currency')}</span>
              </div>
            </div>}

          {descriptionItems &&
            <div className='dso-object-inclusive-equipment-container'>
              <div className='dso-object-description-equipment'>
                <ul className='dso-object-description-equipment-list'>
                  {descriptionItems.filter(item => (item.name !== '') && (item.name !== ' ')).map(spec =>
                    <DescListItem key={spec.name} item={spec.name} />)}
                </ul>
              </div>
            </div>}

          {vehicle.language === 'nb' ? <>
            {dividedDesc.longItems && <>
              <div className='dso-object-description-equipment-desc'
                dangerouslySetInnerHTML={{ __html: dividedDesc.longItems as string }}></div>
            </>}
          </>:<>
            {(vehicle.type === core.VehicleType.car) && (vehicle.source !== 'bilvision') ? <>
              {vehicle.description && <>
                <div className='dso-object-description-equipment-desc'
                  dangerouslySetInnerHTML={{ __html: vehicle.description }}></div>
              </>}
            </>:<>
              {dividedDesc.longItems && (dividedDesc.longItems.length > 0) &&
              (vehicle.source !== 'bilvision') && <>
                <div className='dso-object-description-equipment-desc'>
                  {(dividedDesc.longItems as string[]).map(item =>
                    <DescListItem key={item} item={item} long={true} />
                  )}
                </div>
              </>}
            </>}
          </>}

          <div className='dso-object-main-container'>
            {vehicle.seller && vehicle.seller.length > 0 &&
            <div className='pdf-sellers'>
              {vehicle.seller && vehicle.seller.map((seller, index) =>
                <Seller key={seller.email + index} seller={seller} objectType={vehicle.type} />)}
            </div>}
            <div className='web-ad'>
              <span>{translate('object.pdf.adLink')}</span>
              <a href={vehicleLink}>{vehicleLink}</a>
            </div>
          </div>
        </div>
      </body>
    </html>
  </>);
  return renderToStaticMarkup(html);
}

function saveFile(blob: Blob, filename: string) {
  if (window.navigator.msSaveOrOpenBlob) { // IE fallback
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => { // Fix for MS Edge and large files
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

export interface PrintVehiclesProps {
  vehicles: core.VehicleExtended[];
  config: core.GeneralConfig;
  effectiveRate?: number;
}

export async function PrintVehicles(props: PrintVehiclesProps, status: (status: string) => void) {

  let vehiclesHtmlStr = '';

  if (props.vehicles.length === 0) {
    status(translate('object.action.pdf.failed'));
    return null;
  } else {

    status(translate('object.action.pdf.processing'));

    props.vehicles.forEach(vehicle => {
      vehiclesHtmlStr += paintHtml(vehicle, props.config, props.effectiveRate);
    });

    try {
      const blob: Blob = await printToPdf({html: vehiclesHtmlStr});
      saveFile(
        blob,
        props.vehicles.length > 1
          ? `PDF-objects-${new Date().toISOString().slice(0, 10)}.pdf`
          : `PDF-object-${new Date().toISOString().slice(0, 10)+'-'+props.vehicles[0].uniqueId.replace(' ','_')}.pdf`
      );
      status(translate('object.action.pdf.succeeded'));
    } catch (err) {
      status(translate('object.action.pdf.failed'));
    }
  }
}