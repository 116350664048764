import * as React from 'react';
import { InputProps, createDefaultModelInput, Input } from './input';
import { CheckoutModel } from '../model';

interface CheckBoxProps<T extends CheckoutModel> extends InputProps<T> {
  text?: string;
}

export function CheckBox<T extends CheckoutModel>(props: CheckBoxProps<T>) {
  const model = props.model[props.id]! || createDefaultModelInput(props);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked + '';

    props.change(props.id, {
      ...model,
      value,
      dirty: value !== model.initialValue
    });
  };

  return (
    <Input {...props}>
      <label className='empori-checkbox-container checkbox'>
        <input
          data-checkout-key={props.id}
          className='empori-input'
          type='checkbox'
          onChange={handleChange}
          required={props.required}
          disabled={props.disabled}
          checked={model.value === 'true'} />

        <label className='empori-checkbox-input-text' dangerouslySetInnerHTML={{ __html: props.text ?? '' }}></label>
        <span className='checkmark'></span>

      </label>
    </Input>
  );
}

