import * as React from 'react';
import * as coreModel from '../../model/core';
import { FinanceModelData, ModelInput, CheckoutModel, CheckoutModelData, CashModelData, PaymentMethod, FinanceModel, CashModel } from './model';
import { autobind } from '../../decorator';
import { validateField, checkoutSchema, CheckoutKey } from './input/validation';
import { createDefaultModelInput } from './input/input';
import * as santanderApi from './api/santanderApi';
import * as core from '../santander.core';
import { SantanderForm } from './santanderForm';
import { CashForm } from './cashForm';
import { SantanderBooking } from './santanderBooking';
import { PaymentCalculator } from './input/paymentCalculator';
import { CashPaymentCalculator } from './input/cashPayment';
import { formatNumber } from '../../util';
import { PaymentToggler } from './paymentSelect';
import { FaIcon } from '../../icon';
import { translate as trans } from '../../lang';
import * as gtm from '../../gtm';
import * as dsoApi from '../../api/vehicleFilterApi';
import { CheckoutProps } from './common';

interface Props extends CheckoutProps {
}

interface ModelDataObj {
  [paymentMethod: number]: CheckoutModelData<CheckoutModel>;
  [PaymentMethod.finance]: FinanceModelData;
  [PaymentMethod.cash]: CashModelData;
}

interface State {
  modelData: ModelDataObj;
  booking: core.CreateBookingResponse | null;
  article: core.ArticleResponse | null;
  submittingBooking: boolean;
  bookingError: string | null;
  downPayment: number;
  month: number;
  paymentMethod: PaymentMethod;
  cashBookingComplete?: boolean;
  texts: core.InfoTextResponse | null;
  approvedWithError: boolean;
}

export class SantanderCheckout extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      paymentMethod: this.props.showFinancingFirst
        ? PaymentMethod.finance
        : PaymentMethod.cash,
      modelData: {
        [PaymentMethod.finance]: this.createModel('finance'),
        [PaymentMethod.cash]: this.createModel('cash')
      },
      booking: null,
      article: null,
      submittingBooking: false,
      bookingError: null,
      downPayment: 0,
      month: 0,
      texts: null,
      approvedWithError: false
    };
  }

  private createModel<T extends FinanceModel>(checkoutKey: CheckoutKey) {
    const model: CheckoutModelData<T> = {};
    const schema = checkoutSchema[checkoutKey];

    // Create all inputs based of the validation schema.
    Object.keys(schema.fields).forEach(key => {
      model[key as keyof T] = createDefaultModelInput();
    });

    return model;
  }

  async componentDidMount() {
    const article = await santanderApi.getArticle(this.props.articleNr);
    const texts = await santanderApi.getArticleInfoTexts(this.props.articleNr, {
      creditTimeMonths: this.props.costCalc.initialLoanPaymentYears * 12,
      downPayment: this.props.costCalc.initialDownPayment
    });

    this.setState({ article, texts });
  }

  @autobind
  handleInputChange<T extends CheckoutModel>(key: keyof T, model: ModelInput) {
    const { modelData, paymentMethod } = this.state;

    this.setState({
      modelData: {
        ...modelData,
        [paymentMethod]: {
          ...modelData[paymentMethod],
          [key]: model
        }
      },
      approvedWithError: false
    });
  }

  @autobind
  handleInputBlur<T extends CheckoutModel>(key: keyof T) {
    const modelData = this.state.modelData[this.state.paymentMethod];
    const modelInput = (modelData as any)[key] as ModelInput;

    if (modelInput) {
      validateField(this.state.paymentMethod, key, modelInput);
      this.handleInputChange(key, modelInput);
    }
  }

  generateDataModel(validateAll = true) {
    const { paymentMethod } = this.state;
    const modelData = this.state.modelData[paymentMethod];
    const model: Partial<CheckoutModel> = {};
    const modelKeys = Object.keys(modelData) as (keyof CheckoutModel)[];
    let anyErrors = false;

    for (let key of modelKeys) {
      model[key] = modelData[key]!.value;
    }

    if (validateAll) {
      anyErrors = modelKeys
        .map(key => validateField(paymentMethod, key, modelData[key]!))
        .some(valid => !valid);

      if (anyErrors) {
        this.setState({
          modelData: {
            ...this.state.modelData
          }
        });
      }
    }

    return { model, anyErrors };
  }

  @autobind
  async createBooking() {
    const { model, anyErrors } = this.generateDataModel();

    if (anyErrors) {
      return;
    }

    this.setState({ submittingBooking: true });

    try {
      switch (this.state.paymentMethod) {
        case PaymentMethod.finance:
          await this.createFinanceBooking(model);
          this.setState({approvedWithError: true});
          break;

        case PaymentMethod.cash:
          await this.createCashBooking(model);
          break;
      }
    } catch (ex) {
      this.setState({
        booking: null,
        bookingError: trans('finance.status.error')
      });
    } finally {
      this.setState({ submittingBooking: false });
    }
  }

  async createFinanceBooking(model: Partial<FinanceModel>) {
    let orgNrAdjust = model.orgNr ?? '';

    if (orgNrAdjust?.includes('-')) {
      orgNrAdjust = orgNrAdjust.replace('-', '');
    }

    if (orgNrAdjust.length == 12) {
      orgNrAdjust = orgNrAdjust.substring(2);
    }

    const booking = await santanderApi.createFinancing({
      articleNr: this.props.articleNr,
      deliveryDate: new Date(model.deliveryDate ?? ''),
      email: model.email ?? '',
      orgNr: orgNrAdjust,
      phone: model.phone ?? '',
      calculation: this.state.article ? {
        creditTimeMonths: this.state.month,
        downPayment: this.state.downPayment
      } : void 0,
      exchange: model.exchange === 'true',
      exchangeRegNr: model.exchangeRegNr,
      exchangeMilage: model.exchangeMilage,
      exchangeMessage: model.exchangeMessage
    });

    if (!booking.hasError) {
      gtm.financeCheckoutFormSubmit();
    }

    this.setState({
      booking,
      bookingError: booking.hasError ? booking.error : null
    });
  }

  async createCashBooking(model: Partial<CashModel>) {
    const cashBookingComplete = await dsoApi.createCashBooking({
      regNr: '',
      articleNr: this.props.articleNr,
      address: model.address ?? '',
      city: model.city ?? '',
      deliveryDate: new Date(model.deliveryDate ?? ''),
      email: model.email ?? '',
      firsName: model.firstname ?? '',
      lastName: model.lastname ?? '',
      orgNr: model.orgNr ?? '',
      phone: model.phone ?? '',
      zipCode: model.zipcode ?? '',
      exchange: model.exchange === 'true',
      exchangeRegNr: model.exchangeRegNr,
      exchangeMilage: model.exchangeMilage,
      exchangeMessage: model.exchangeMessage
    });

    if (cashBookingComplete) {
      gtm.cashCheckoutFormSubmit();
    }

    this.setState({ cashBookingComplete });
  }

  @autobind
  handleDownPaymentChange(value: number) {
    this.setState({ downPayment: value });
  }

  @autobind
  handleMonthChange(value: number) {
    this.setState({ month: value });
  }

  getInfoText(type: core.InfoTextType) {
    const { texts } = this.state;

    if (!texts) {
      return null;
    }

    return texts.textGroups.find(t => t.textType === type);
  }

  render() {
    const { modelData, paymentMethod, booking, article, submittingBooking, bookingError } = this.state;
    const model = modelData[paymentMethod];
    const financing = paymentMethod === PaymentMethod.finance;
    const costCalc = this.props.costCalc;

    const maxVehicleErr = booking?.errorDetail?.errorParameterValues?.find(v => v.code === 4028);

    return (
      <div className='empori-checkout'>
        {this.state.article == null && <>
          <div className='empori-checkout-submitting loading-forms'>
            <div className='empori-checkout-submitting-content'>
              <div className='empori-checkout-submitting-text'>
                <i className='fas fa-spinner'></i><span>Laddar in...</span>
              </div>
            </div>
          </div>
        </>}

        <div className='empori-checkout-intro-box'>
          <h3 className='empori-checkout-title'>{trans('checkout.title')}</h3>
          <div className='empori-checkout-desc'>

            <span className='recap-title'>{article?.title}</span>

            {financing && article && <>
              <span className='recap-price'>{formatNumber(article.price)} kr</span>
            </>}
          </div>
        </div>

        {(booking && !booking.hasError) ? <>
          <SantanderBooking articleNr={this.props.articleNr} booking={booking} />
        </> : <>
            {article && <>
              <div className='empori-payment-toggler-container' id={financing ? void 0 : 'cash-toggled'}>
                <PaymentToggler
                  paymentMethod={paymentMethod}
                  buyType={this.props.buyType}
                  select={method => this.setState({ paymentMethod: method })} />
              </div>
            </>}

            {financing && <>
              {article && <>

                <PaymentCalculator
                  articleNr={article.articleNr}
                  downPayment={{
                    min: costCalc.downPaymentMinimum,
                    max: costCalc.downPaymentMaximum
                  }}
                  initialDownPayment={costCalc.initialDownPayment}
                  interest={costCalc.loanInterest}
                  months={{
                    min: costCalc.monthSteps ?? 1, // Months cannot be 0, use step as min value.
                    max: costCalc.loanPaymentYearsMaximum * 12,
                    step: costCalc.monthSteps
                  }}
                  startMonth={costCalc.initialLoanPaymentYears * 12}
                  minMonthlyCost={costCalc.monthlyPaymentMinimum}
                  price={article?.price}
                  downPaymentChange={this.handleDownPaymentChange}
                  monthChange={this.handleMonthChange} />

                <SantanderForm
                  model={model}
                  consentTexts={this.getInfoText(core.InfoTextType.consent)}
                  change={this.handleInputChange}
                  blur={this.handleInputBlur}
                  createBooking={this.createBooking}
                  disabled={submittingBooking}
                  showExchange={this.props.showExchange}
                  hideInputs={this.state.approvedWithError} />

                { // Approved, but with an error (pending at Santander)
                (booking?.pendingResponse || maxVehicleErr) ? <>
                  <div
                    className='empori-booking approvement-failed'
                    dangerouslySetInnerHTML={{ __html: trans('checkout.bookingApprovedHtml') }}></div>
                </>:<></>}

                { // BankID timeout
                booking?.bankIdTimeout && <>
                  <div
                    className='empori-booking approvement-failed booking-timeout'
                    dangerouslySetInnerHTML={{ __html: trans('checkout.bookingTimeOutHtml') }}></div>
                </>}

                { // All kinds of errors except pending status, timeout and vehicle reached it's max
                bookingError && !booking?.bankIdTimeout && !booking?.pendingResponse && !maxVehicleErr && <>
                  <div
                    className='empori-booking booking-error'
                    dangerouslySetInnerHTML={{ __html: trans('checkout.bookingErrorHtml') }}></div>
                </>}

                {submittingBooking && <>
                  <div className='empori-checkout-submitting'>
                    <div className='empori-checkout-submitting-content'>
                      <div className='empori-checkout-submitting-text'>
                        <i className='fas fa-spinner'></i><span>{trans('santander.status.submitting')}</span>
                      </div>
                    </div>
                  </div>
                </>}

                <div className='empori-checkout-logo'>
                  {this.getInfoText(core.InfoTextType.general)?.texts
                    .map(t => <span key={t.id} dangerouslySetInnerHTML={{ __html: t.text }}></span>)}

                  <img src='//cdn.empori.se/vendor/santander/logo-santander-small.png' />
                </div>
              </>}
            </>}

            {!financing && article && <>

              <CashPaymentCalculator price={article.price} />

              <CashForm
                model={model}
                change={this.handleInputChange}
                blur={this.handleInputBlur}
                createBooking={this.createBooking}
                showExchange={this.props.showExchange}
                disabled={submittingBooking} />

              {(typeof this.state.cashBookingComplete !== 'undefined' && this.state.cashBookingComplete) && <>
                <div className='empori-cash-result-window-parent' onClick={() => { window.location.reload(false) /*this.setState({ cashBookingComplete: false })*/ }}>
                  <div className='empori-cash-result-window-child'>
                    {this.state.cashBookingComplete ?
                      <div className='empori-cash-result booking-complete'>
                        <FaIcon name={'check'} />
                        <span>{/*trans('checkout.bookingComplete')*/}
                        {/* Avvakta med översättning. Texten nedan ska vara hårdkodad tills vi får översättningarna. Använd då ovanstående "trans('checkout.bookingComplete')" */}
                          Tack för din beställning och förtroendet att leverera ditt fordon. Vi har skickat en bekräftelse till <strong>{this.state.modelData[1].email?.value}</strong>.</span>
                          <span className='empori-plain-text'>Vi kontaktar er inom kort för att gå igenom följande steg i köpprocessen:</span>
                        <ul>
                          <li>Slutgiltigt leveransdatum</li>
                          <li>Betalning</li>
                          <li>Försäkring</li>
                        </ul>
                        <span className='empori-plain-text'>Om ni har frågor så tveka inte att kontakta oss.</span>
                      </div> :
                      <div className='empori-cash-result booking-fail'>
                        <FaIcon name={'times'} />
                        <span>{trans('checkout.bookingFail')}</span>
                      </div>
                    }

                    <div
                      className='empori-cash-result-close'
                      onClick={() => { window.location.reload(false) /*this.setState({ cashBookingComplete: false })*/ }}>
                      <span><FaIcon name={'times'} /> {trans('checkout.closeWindow')}</span>
                    </div>
                  </div>
                </div>
              </>}
            </>}

          </>}
      </div>
    );
  }
}
