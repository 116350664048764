import { translateAny } from './lang';
import * as core from './model/core';
import { getEnumStringValue } from './util';

function GetCurrencyStr(lang: string) {
  switch (lang) {
    case 'de':
      return 'EUR';
    case 'nb':
      return 'NOK';
    case 'sv':
      return 'SEK';
    default:
      return 'SEK';
  }
}

export interface ListSchemaProps {
  vehicles: core.Vehicle[];
  language: string;
  type: string;
  links: string[];
}

export function VehicleListSchema(props: ListSchemaProps) {

  const script = document.createElement('script');
  script.id = props.type + 'Schema';
  script.type = 'application/ld+json';

  let vehicleData: any = [];
  if (props.vehicles.length > 0) {
    let data = props.vehicles.map((v, i) => (
      {
        '@type':'Vehicle',
        '@id': v.uniqueId,
        'name': v.brand + ' ' + v.model + ' - ' + v.year,
        // 'gtin13': v.articleNr,
        'image': !!v.images && v.images.length > 0
          ? v.images.map(img => img.includes('http') ? img : window.location.origin + img)
          : null,
        'brand': {
          '@type': 'Brand',
          'name': v.brand
        },
        'category': translateAny('object.type.' + props.type),
        'offers': {
          '@type': 'Offer',
          'availability': 'https://schema.org/InStock',
          'price': v.salePrice ?? v.price,
          'priceCurrency': GetCurrencyStr(props.language),
          'itemCondition': v.condition === core.Condition.new
            ? 'https://schema.org/NewCondition'
            : 'https://schema.org/UsedCondition',
          'url': window.location.origin + window.location.pathname + props.links[i]
        }
      }
    ));
    vehicleData = data;
  }

  const scriptContent = (
    {
      '@context': 'https://schema.org',
      '@type': 'WebPageElement',
      'offers': {
        '@type': 'Offer',
        'itemOffered': [
          vehicleData
        ]
      }
    }
  );

  script.text = JSON.stringify(scriptContent);

  if (!document.getElementById(script.id)) {
    return document.head.appendChild(script);
  } else {
    return null;
  }
}

export function VehicleSingleSchema(vehicle: core.VehicleExtended) {

  const script = document.createElement('script');
  script.id = vehicle.uniqueId + translateAny(`object.type.${getEnumStringValue(core.VehicleType, vehicle.type)}`);
  script.type = 'application/ld+json';

  const scriptContent = (
    {
      '@context': 'https://schema.org',
      '@type': 'Vehicle',
      'name': vehicle.brand + ' ' + vehicle.model + ' - ' + vehicle.year,
      'description': !!vehicle.adInfo ? vehicle.adInfo : null,
      'brand': {
        '@type': 'Brand',
        'name': vehicle.brand
      },
      'category': translateAny(`object.type.${getEnumStringValue(core.VehicleType, vehicle.type)}`),
      'image': !!vehicle.images && vehicle.images.length > 0
        ? vehicle.images.map(img => img.includes('http') ? img : window.location.origin + img)
        : null,
      // 'gtin13': vehicle.articleNr,
      'offers': {
        '@type': 'Offer',
        'availability': !!vehicle.status ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock',
        'price': vehicle.salePrice ?? vehicle.price,
        'priceCurrency': GetCurrencyStr(vehicle.language),
        'itemCondition': vehicle.condition === core.Condition.new
          ? 'https://schema.org/NewCondition'
          : 'https://schema.org/UsedCondition',
        'url': window.location.href
      }
    }
  );

  script.text = JSON.stringify(scriptContent);

  if (!document.getElementById(script.id)) {
    return document.head.appendChild(script);
  } else {
    return null;
  }

}