import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { autobind } from './decorator';
import { FaIcon } from './icon';
import { KeyCodes, className } from './util';

interface WarningProps {
  visible: boolean;
  onClose?: () => void;
}

export class Warning extends React.Component<WarningProps> {

  private containerElm?: HTMLElement;
  private closeElm?: HTMLElement;

  constructor(props: WarningProps) {
    super(props);
  }

  close() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  @autobind
  handleKeyDown(e: KeyboardEvent) {
    if (e.keyCode === KeyCodes.escape) {
      this.close();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps: WarningProps) {
    if (prevProps.visible !== this.props.visible) {
      document.body.classList.toggle('dso-warning-body', this.props.visible);
    }
  }

  @autobind
  handleClose(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    if (e.target === this.containerElm || e.target === this.closeElm) {
      this.close();
    }
  }

  render() {
    return ReactDOM.createPortal(
      <div className={className('dso-warning-container', { 'dso-visible': this.props.visible })}
        ref={elm => this.containerElm = elm as HTMLElement}
        onClick={this.handleClose}>

        <div className='dso-warning-content-container'>
          <button className='dso-warning-close'
            ref={elm => this.closeElm = elm as HTMLElement}
            onClick={this.handleClose}>
            <FaIcon name='times' />
          </button>

          {this.props.visible && <>
            <div className='dso-warning-content'>
              {this.props.children}
            </div>
          </>}
        </div>
      </div>, document.body);
  }
}