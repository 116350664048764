import * as React from 'react';
import * as core from '../../model/core';
import { className } from '../../util';
import { translate } from '../../lang';

interface ConditionObj {
  [key: number]: string;
}

interface Props {
  selectedCondition: core.Condition;
  select: (condition: core.Condition) => void;
}

export function ConditionSelection(props: Props) {
  const handleClick = (c: core.Condition) => {
    return () => {
      props.select(c);
    };
  };

  const conditions: ConditionObj = {
    [core.Condition.all]: translate('filter.condition.all'),
    [core.Condition.new]: translate('filter.condition.new'),
    [core.Condition.used]: translate('filter.condition.used')
  };

  return (
    <div className='dso-single-selection dso-condition-selection'>
      {Object.keys(conditions).map(key => {
        const c = +key as core.Condition;
        return <ConditionItem
          key={key}
          condition={c}
          conditions={conditions}
          click={handleClick(c)}
          selected={props.selectedCondition === c} />;
      })}
    </div>
  );
}

interface ConditionItemProps {
  selected: boolean;
  condition: core.Condition;
  conditions: ConditionObj;
  click: () => void;
}

function ConditionItem(props: ConditionItemProps) {
  const cls = className('dso-single-selection-item dso-condition-item', { 'dso-selected': props.selected });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.click();
  };

  return (
    <a href='#' onClick={handleClick} className={cls}>{props.conditions[props.condition]}</a>
  );
}