import * as React from 'react';
import { CreateBookingResponse, AuthenticationStatus, ScoreDecision } from '../ecster.core';
import { bookingSignUrl, bookingSignCheck, applyBooking } from './api/ecsterApi';
import { autobind } from '../../decorator';
import { translate as trans } from '../../lang';

interface Props {
  booking: CreateBookingResponse;
}

interface State {
  signUrl?: string;
  signStartTime: number;
  signStatus: AuthenticationStatus;
  signTimedOut: boolean;
  polling: boolean;
  bookingScore?: ScoreDecision;
  bookingError?: string;
  bookingApplying?: boolean;
}

const statusPollIntervalMs = 2000;
const statusPollLimitMs = 1000 * 60 * 60; // 1h
const autoApply = true;

export class EcsterBooking extends React.Component<Props, State> {

  private signWindow: Window | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      signStartTime: 0,
      signTimedOut: false,
      signStatus: AuthenticationStatus.unset,
      polling: false
    };
  }

  componentDidMount() {
    this.openSignPage();
  }

  async openSignPage() {
    const signUrl = await bookingSignUrl(this.props.booking.bookingId);

    if (signUrl) {
      this.signWindow = window.open(signUrl, '_blank');

      this.setState({
        signUrl,
        signStartTime: Date.now(),
        polling: true
      }, () => this.checkSignStatus());
    }

    return !!signUrl;
  }

  async applyBooking() {
    try {
      this.setState({
        bookingApplying: true
      });

      const res = await applyBooking(this.props.booking.bookingId);

      this.setState({
        bookingScore: res.score,
        bookingError: res.hasError ? res.error : void 0,
        bookingApplying: false
      });
    } catch (ex) {
      this.setState({ bookingError: ex + '' });
    }
  }

  @autobind
  checkSignStatus() {
    if (this.state.signStatus > AuthenticationStatus.unset || this.state.signTimedOut) {
      return;
    }

    if (Date.now() >= this.state.signStartTime + statusPollLimitMs) {
      this.setState({ signTimedOut: true, polling: false });
      return;
    }

    setTimeout(async () => {
      const res = await bookingSignCheck(this.props.booking.bookingId);

      if (res) {
        if (res.authStatus === AuthenticationStatus.unset) {
          this.checkSignStatus();
        } else {
          this.setState({
            polling: false,
            signStatus: res.authStatus
          });

          if (autoApply && res.authStatus === AuthenticationStatus.ok) {
            await this.applyBooking();
          }
        }
      }
    }, statusPollIntervalMs);
  }

  render() {
    const { signStatus, polling, bookingApplying, bookingScore, bookingError } = this.state;
    const { booking } = this.props;

    // Score decision from booking result.
    const hasScore = typeof bookingScore !== 'undefined';

    if (booking.hasError) {
      return (
        <div className='empori-booking booking-error'>
          <div className='error-title'>
            {trans('finance.status.error')}
          </div>
          <div className='error-message'>{booking.error}</div>
        </div>
      );
    }

    return (
      <div className='empori-booking'>
        {polling && <>
          <div className='empori-checkout-submitting'>
            <div className='empori-checkout-submitting-content'>
              <div className='empori-checkout-submitting-text'>
                <i className='fas fa-spinner'></i><span>{trans('checkout.ecster.verifying')}</span>
              </div>
            </div>
          </div>
        </>}

        {bookingApplying && <>
          <div className='empori-checkout-submitting'>
            <div className='empori-checkout-submitting-content'>
              <div className='empori-checkout-submitting-text'>
                <i className='fas fa-spinner'></i><span>{trans('checkout.ecster.applying')}</span>
              </div>
            </div>
          </div>
        </>}

        {hasScore ? <>
          <div className='ecster-booking-messages'>
            {bookingScore === ScoreDecision.approved && <>
              <div className='ecster-response success'>
                <i className='fas fa-check'></i> <span dangerouslySetInnerHTML={{ __html: trans('checkout.ecster.bookingApprovedHtml') }}></span>
              </div>
            </>}

            {bookingScore === ScoreDecision.denied && <>
              <div className='ecster-response fail'>
                <span>
                  <i className='fas fa-times'></i> <span dangerouslySetInnerHTML={{__html: trans('checkout.ecster.bookingDeniedHtml')}}></span>
                </span>
                <code>Felkod: {bookingError}</code>
              </div>
            </>}

            {bookingScore === ScoreDecision.pending && <>
              <div className='ecster-response success'>
                <i className='fas fa-check'></i> <span dangerouslySetInnerHTML={{__html: trans('checkout.ecster.bookingPendingHtml')}}></span>
              </div>
            </>}

            {bookingScore === ScoreDecision.unknown && <>
              <div className='ecster-response timeout'>
                <span dangerouslySetInnerHTML={{__html: trans('checkout.bookingFail')}}></span>
                <code>Felkod: {bookingError}</code>
              </div>
            </>}
          </div>
        </> : <>
          {(!autoApply && signStatus === AuthenticationStatus.ok) && <>
            <button className='empori-sign-button dso-action-button' onClick={e => {
              e.preventDefault();
              this.applyBooking();
            }}>Bekräfta bokning</button>
          </>}
        </>}
      </div>
    );
  }
}