export const de = {
  'general.vehicle': 'Fahrzeuge',
  'general.object': 'Objekt',
  'filter.campaignOnly': 'Zeige nur {0} mit Aktionspreis',
  'filter.noResult': 'Keine {0} vorhanden',
  'filter.cat.alcove': 'Alkoven',
  'filter.cat.double axle': 'Tandemachse',
  'filter.cat.half integrated with roofbed': 'Teilintegriert mit Hubbett',
  'filter.cat.half integrated': 'Teilintegriert',
  'filter.cat.hatchback': 'Schrägheck',
  'filter.cat.integrated': 'Integriert',
  'filter.cat.offroad': 'Gelände',
  'filter.cat.other': 'Übrige',
  'filter.cat.single axle': 'Einzelachse',
  'filter.cat.snowmobile': 'Schneemobil',
  'filter.cat.sports coupe': 'Sportcoupé',
  'filter.cat.van': 'Van',
  'filter.cat.daycruiser': 'Daycruiser',
  'filter.condition.all': 'Alle',
  'filter.condition.new': 'Neuwagen',
  'filter.condition.used': 'Gebrauchte',
  'filter.list.all': 'Alle',
  'filter.list.over': 'Über {0} {1}',
  'filter.list.under': 'Unter {0} {1}',
  'filter.extra.hide': 'Weitere Filter ausblenden',
  'filter.extra.show': 'Weitere Filter zeigen',
  'filter.input.amount.unit': ' ',
  'filter.input.beds': 'Anzahl Betten',
  'filter.input.brand': 'Marke',
  'filter.input.effect': 'Leistung',
  'filter.input.effect.unit': 'PS',
  'filter.input.filial': 'Niederlassung',
  'filter.input.gearbox': 'Getriebe',
  'filter.input.gearbox.automat': 'Automatik',
  'filter.input.gearbox.manuell': 'Manuell',
  'filter.input.layouts': 'Grundriss',
  'filter.input.layouts.double_bed': 'Doppelbett',
  'filter.input.layouts.queenbed': 'Doppelbett Mitte',
  'filter.input.layouts.kids_room': 'Kinderzimmer',
  'filter.input.layouts.long_beds': 'Einzelbetten',
  'filter.input.layouts.other': 'Sonstige',
  'filter.input.length': 'Länge',
  'filter.input.width': 'Breite',
  'filter.input.height': 'Höhe',
  'filter.input.milage': 'Anzahl Kilometer',
  'filter.input.milage.unit': 'km',
  'filter.input.passengers': 'Anzahl Passagiere',
  'filter.input.price': 'Preis',
  'filter.input.price.unit': 'EUR',
  'filter.input.weight': 'Gesamtgewicht',
  'filter.input.year': 'Modelljahr',
  'filter.reset': 'Filter löschen',
  'filter.search': 'Suchen',
  'filter.search.result': 'Zeige {0} {1}',
  'filter.search.showMore': 'Zeige mehr {0}',
  'filter.search.title': 'Mit gewählten Kriterien suchen',
  'filter.searchPlaceholder': 'Wonach suchen Sie?',
  'filter.sort.created': 'Vorherige',
  'filter.sort.priceAsc': 'Niedrigster Preis',
  'filter.sort.priceDesc': 'Höchster Preis',
  'filter.sort.titleAsc': 'A bis Z',
  'filter.sortResult': 'Treffer sortieren',
  'filter.typeTitle': 'Fahrzeugtyp wählen',
  'object.action.buy': 'Online kaufen',
  'object.action.call': 'Anrufen',
  'object.action.interestForm': 'Anfrage',
  'object.action.pdf': 'PDF speichern',
  'object.action.pdf.failed': 'PDF konnte nicht gespeichert werden',
  'object.action.pdf.processing': 'Sparen...',
  'object.action.pdf.succeeded': 'PDF Herunterladen!',
  'object.pdf.adLink': 'Aus der Anzeige:',
  'object.action.youTube': 'Video zeigen',
  'object.campaignPrice': '{0} EUR sparen',
  'object.campaignPriceTitle': 'Aktionspreis',
  'object.campaignTitle': 'Aktion',
  'object.equipmentDesc': 'Dieses Fahrzeug hat Sonderausstattung für:',
  'object.equipmentTitle': 'Ausstattung',
  'object.filialDesc': 'Das Fahrzeug steht in unserer Niederlassung in',
  'object.descHeader': 'Beschreibung',
  'object.notFound': 'Das Fahrzeug ist nicht vorhanden',
  'object.orgPrice': 'Ursprünglicher Preis',
  'object.price': '{0} EUR',
  'object.priceDetails': 'Gesamtpreis',
  'object.pricePerMonth': 'Monatsrate {0} EUR',
  'object.similarTitle': 'Vielleicht gefallen Ihnen auch diese {0}',
  'object.showMoreEquipment': 'Die ganze Serienausstattung anzeigen',
  'object.showLessEquipment': 'Weniger Serienausstattung anzeigen',
  'object.specItem.fuel.gasoline': 'Benzin',
  'object.specItem.fuel.diesel': 'Diesel',
  'object.specItem.fuel.hybridGasoline': 'Hybrid (Benzin/Elektro)',
  'object.specItem.fuel.hybridDiesel': 'Hybrid (Diesel/Elektro)',
  'object.specItem.fuel.naturalGas': 'Erdgas (CNG)',
  'object.specItem.fuel.electricity': 'Elektro',
  'object.specItem.fuel.gasolineEthanol': 'Ethanol (FFV, E85 etc.)',
  'object.specItem.fuel.gasolineGas': 'Autogas (LPG)',
  'object.specItem.axleType': 'Achsentyp',
  'object.specItem.beds': 'Schlafplätze',
  'object.specItem.condition': 'Zustand',
  'object.specItem.condition.new': 'Neuwagen',
  'object.specItem.condition.used': 'Gebrauchtwagen',
  'object.specItem.conditionlabel.new': 'Neuwagen',
  'object.specItem.conditionlabel.used': 'Gebrauchtwagen',
  'object.specItem.engineType': 'Motorart',
  'object.specItem.gear': 'Getriebe',
  'object.specItem.gearAuto': 'Automatik',
  'object.specItem.gearManual': 'Manuell',
  'object.specItem.height': 'Höhe',
  'object.specItem.layout': 'Grundriss',
  'object.specItem.length': 'Länge',
  'object.specItem.mileage': 'Kilometerstand',
  'object.specItem.milageUnit': 'km',
  'object.specItem.passengers': 'Anzahl Passagiere',
  'object.specItem.power': 'Leistung',
  'object.specItem.reg': 'Kennzeichen',
  'object.specItem.articleNr': 'Artikelnummer',
  'object.specItem.totalWeight': 'Gesamtgewicht',
  'object.specItem.vehicleType': 'Fahrzeugtyp',
  'object.specItem.width': 'Breite',
  'object.specItem.workingWeight': 'Masse in fahrbereitem Zustand',
  'object.specItem.year': 'Modelljahr',
  'object.specTitle.engineinfo': 'Motor und Antrieb',
  'object.specTitle.engine': 'Motor',
  'object.specTitle.measure': 'Maße und Gewicht',
  'object.specTitle.model': 'Modell',
  'object.status.reserved': 'Reserviert',
  'object.status.sold': 'Verkauft',
  'object.type.boat': 'Boot',
  'object.type.car': 'Auto',
  'object.type.caravan': 'Wohnwagen',
  'object.type.mcAtv': 'Motorrad/ATV',
  'object.type.rv': 'Wohnmobil',
  'object.type.mobileHome': 'Wohnmobil',
  'object.type.trailer': 'Anhänger',
  'object.type.transport': 'Transporter',
  'object.type.other': 'Andere',
  'seller.desc': 'Kontaktieren Sie uns für eine Probefahrt oder mehr Informationen zum Fahrzeug',
  'seller.title': 'Verkäufer',
  'payment.monthly': 'Ratenzahlung',
  'payment.monthlyCost': '{0} EUR Monatsrate',
  'payment.downPayment.title': 'Anzahlung',
  'payment.downPayment.value': '{0} EUR',
  'payment.loanTime.title': 'Laufzeit',
  'payment.loanTime.value': '{0} Monate',
  'payment.calcInfo.title': 'So haben wir gerechnet',
  'payment.calcInfo.limit': 'Die Mindestrate beträgt {0} EUR',
  'payment.calcInfo.downPayment': 'Anzahlung (mindestens {0}%):',
  'payment.calcInfo.loan': 'Darlehen:',
  'payment.calcInfo.interestCost': 'Zinsen insgesamt:',
  'payment.calcInfo.total': 'Gesamtbetrag:',
  'payment.calcInfo.summary': 'Bei einem Darlehen von {0} EUR, das über {1} Monate zu zahlen ist, liegen die monatlichen Kosten bei {2} EUR mit einem Zinssatz von {3}%. Der Finanzierungsvorschlag ist ein Berechnungsbeispiel. Eine Inzahlungnahme gilt als Anzahlung. Kontaktieren Sie uns für eine individuelle Kalkulation.',
  'payment.calcSummary.summary': '{1} % egenkapital. {2} % nom. rente / {3} % eff. rente. <br/>Lånebeløp: {4} {0} i {5} mnd. <br/>Kredittkostnad: {6} {0}. Totalt: {7} {0}.',
  'payment.default.currency': 'EUR',
  'payment.cash.title': 'Preis bei Barzahlung',
  'payment.cash.info': 'Teilen Sie uns Ihre Daten in dem Formular mit. Wir kontaktieren Sie, nachdem Sie Ihre Bestellung abgeschlossen haben.',
  'santander.error.timeout': 'Die Zeit für die Signierung ist abgelaufen. Bitte versuchen Sie es noch einmal.',
  'santander.status.pending': 'Die Signierung wird von Santander behandelt. Statusprüfung…',
  'santander.status.signed': 'Die Signierung wurde erfolgreich durchgeführt!',
  'santander.status.error': 'Die Signierung konnte nicht durchgeführt werden und/oder wurde abgebrochen.',
  'santander.status.mailSent': 'Eine E-Mail mit Link zur Signierung wurde versandt. Status wird überprüft…',
  'santander.status.submitting': 'Wir senden Ihre Anfrage zu Santander',
  'santander.info': 'Die Santander Consumer Bank verfügt über langjährige Erfahrung in der Fahrzeugfinanzierung und arbeitet eng mit Auto- und Freizeitfahrzeughändlern zusammen. Wir sind Teil der globalen Bankengruppe Banco Santander, einer der größten Banken der Welt.',
  'finance.status.error': 'Die Anfrage konnte nicht durchgeführt werden. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie Ihren Fahrzeughändler.',
  'finance.status.signError': 'Die Signierung konnte nicht durchgeführt werden. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie Ihren Fahrzeughändler.',
  'finance.status.reserving': 'Das Fahrzeug wird reserviert…',
  'finance.status.reserved': 'Vielen Dank für Ihre Bestellung! Wir werden Sie umgehend kontaktieren.',
  'finance.status.fetchingUrl': 'Signaturabfrage…',
  'finance.buyer': 'Käufer',
  'finance.seller': 'Verkäufer',
  'finance.payment': 'Bezahlung',
  'finance.termInMonths': 'Kreditlaufzeit',
  'finance.nominalInterest': 'Zinssatz',
  'finance.effectiveInterest': 'effektiver Zinssatz',
  'finance.financedAmount': 'Finanzierungsbetrag',
  'finance.downPayment': 'Anzahlung',
  'finance.administrationFee': 'Bearbeitungsgebühr',
  'finance.arrangementFee': 'Kalkulationsgebühr',
  'finance.unit.month': 'Monat',
  'finance.unit.currency': 'EUR',
  'finance.sign': 'Signieren',
  'finance.order': 'Beställ',
  'finance.status.fetchingSign': 'Signierung wird geladen…',
  'finance.status.signing': 'Signierung wird in neuem Tab durchgeführt. Status wird überprüft…',
  'finance.status.signingInfo1': 'Hier klicken, ',
  'finance.status.signingInfo2': 'falls kein neuer Tab im Browser geöffnet werden konnte.',
  'finance.party.name': 'Name',
  'finance.party.pnr': 'Personennummer',
  'finance.party.email': 'E-Mail',
  'finance.party.phone': 'Telefon',
  'finance.form.infoHtml': 'Wählen Sie, ob Sie das Fahrzeug in Raten oder bar bezahlen möchten. Geben Sie Ihre Daten ein und senden Sie Ihre Anfrage. Der Kauf ist unverbindlich und Sie zahlen nichts, bis Sie Kontakt mit dem Händler hatten. <span>Wenn Sie Finanzierung gewählt haben, bedeutet dies, dass Sie eine Darlehensbewilligung mit online beantragen und eine Antwort direkt auf dem Bildschirm erhalten.</span>',
  'finance.form.pnr': 'Personennummer',
  'finance.form.pnr.placeholder': 'YYYYMMDDXXXX',
  'finance.form.firstname': 'Vorname',
  'finance.form.lastname': 'Name',
  'finance.form.address': 'Straße + Hausnummer',
  'finance.form.message': 'Mitteilung',
  'finance.form.address.placeholder': 'zusätzliche Angaben',
  'finance.form.city': 'Ort',
  'finance.form.zipcode': 'Postleitzahl',
  'finance.form.zipcode.placeholder': 'Postleitzahl',
  'finance.form.email': 'E-Mail',
  'finance.form.phone': 'Telefon',
  'finance.form.deliveryDate': 'Gewünschtes Lieferdatum',
  'finance.form.gdprLabel': 'Speicherung von Kundendaten',
  'finance.form.gdprTerms': 'Ich habe geprüft, dass die obigen Informationen korrekt sind, sowie die Verarbeitung personenbezogener Daten zur Kenntnis genommen und genehmigt. Lesen Sie mehr darüber, wie Ihre persönlichen Daten verarbeitet werden.',
  'finance.form.send': 'Anfrage senden und bestellen',
  'finance.form.reserve': 'Reservieren und bestellen',
  'finance.verification.confirmed': 'Ihre Kreditanfrage wurde bestätigt. Wenn Sie die Bestellung fortsetzen, wird ein Finanzierungsvertrag mit dem Händler erstellt.',
  'checkout.title': 'Online kaufen',
  'checkout.bookingErrorHtml': '<span><strong>Wir konnten Ihre Anfrage leider nicht erfassen.</strong>Bitte überprüfen Sie, ob Sie Ihre Daten korrekt eingegeben und kein erforderliches Feld übersehen haben. Sie können es später erneut testen oder uns direkt kontaktieren. </span>',
  'checkout.bookingApprovedHtml': '<i class=”fas fa-check”></i>Vielen Dank für Ihre Kreditanfrage! Wir werden Sie umgehend kontaktieren.',
  'checkout.bookingTimeOutHtml': '<i class="fas fa-exclamation-circle"></i>Wir haben keine Antwort von BankID zu der angegebenen Personennummer bekommen. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.',
  'checkout.bookingComplete': 'Vielen Dank für Ihre Anfrage! Sie werden in Kürze von uns kontaktiert, um den Kaufvorgang abzuschließen.',
  'checkout.bookingFail': 'Wir konnten Ihre Anfrage leider nicht erfassen. Bitte versuchen Sie es erneut oder kontaktieren Sie uns direkt.',
  'checkout.closeWindow': 'Fenster schließen',
  'checkout.payment.finance': 'Finanzierung',
  'checkout.payment.cash': 'Barzahlung',
  'form.interest.title': 'Anfrage',
  'form.interest.submitted': 'Vielen Dank für Ihre Anfrage!',
  'form.interest.policy': 'Ich bin damit einverstanden, dass {0} meine Daten gemäß den Datenschutzbestimmungen verarbeitet werden.',
  'form.interest.policyWarning': 'Sie müssen {0} Ihr Einverständnis zur Datenverarbeitung erklären, um die Anfrage abzusenden.',
  'form.interest.policyWarningChoise': 'Sie müssen mindestens eine Option wählen, wie {0} Sie erreichen kann.',
  'form.interest.infoHtml': 'Möchten Sie mehr über dieses Fahrzeug erfahren? <br/> Geben Sie die folgenden Details ein und wir werden uns mit Ihnen in Verbindung setzen!',
  'form.interest.contactEmail': 'Kontaktieren Sie mich via E-Mail',
  'form.interest.contactPhone': 'Kontaktieren Sie mich via Telefon',
  'form.interest.name': 'Name',
  'form.interest.similar': 'An ähnlichen Farhzeugen',
  'form.interest.send': 'Absenden',
  'form.interest.error.mandatory': 'Obligatorisch',
  'form.interest.error.email': 'Ogiltig e-postadress',
  'share.main': 'Teilen',
  'share.facebook': 'Teilen auf Facebook',
  'share.messenger': 'Teilen auf Messenger',
  'share.mail': 'Als E-Mail versenden',
  'share.mail.subject': 'Hast du dieses Fahrzeug gesehen?',
  'share.copy': 'Link kopieren',
  'share.copy.copied': 'Link in die Zwischenablage kopiert!'
};
