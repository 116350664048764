import * as React from 'react';
import { ModelInput, CheckoutModelData, CheckoutModel } from '../model';
import { className } from '../../../util';

export function createDefaultModelInput<T extends CheckoutModel>(initialProps?: InputProps<T>) {
  return {
    dirty: false,
    initialValue: '',
    value: '',
    error: null,
    required: (initialProps && initialProps.required) ?? false
  } as ModelInput;
}

export interface InputProps<T extends CheckoutModel> {
  label?: string;
  id: keyof T;
  model: CheckoutModelData<T>;
  change: (key: keyof T, model: ModelInput) => void;
  blur?: (key: keyof T) => void;
  required?: boolean;
  disabled?: boolean;
  cls?: string;
  desc?: string;
}

export function Input<T extends CheckoutModel>(props: React.PropsWithChildren<InputProps<T>>) {
  const model = props.model[props.id]! || createDefaultModelInput(props);

  const hasError = !!model.error && model.error.length > 0;
  let cls = className('empori-checkout-input-container', { 'input-error': hasError });

  if (props.cls) {
    cls += ' ' + props.cls;
  }

  return (
    <div className={cls}>
      <label>
        <span className='empori-input-label'>
          {props.label}

          {props.required && <>
            <span className='empori-input-required'>*</span>
          </>}

          {hasError && <>
            <span className='empori-input-error'>
              {model.error}
            </span>
          </>}

          {props.desc && <>
            <div className='empori-input-desc'>{props.desc}</div>
          </>}
        </span>

        <div className='empori-input-wrapper'>
          {props.children}
        </div>
      </label>
    </div>
  );
}