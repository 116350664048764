import * as React from 'react';
import * as core from '../../model/core';
import { SelectObjectCallback } from './objectFilterView';
import { formatNumber, convertToCSSClassname, getEnumStringValue, baseUrl, className } from '../../util';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { translate as trans, translateAny as transAny, translateFormat as transFormat } from '../../lang';
import { objectToQueryString } from '../../query';
import { ManageVehicleStorage, useFavourites } from '../../favourites';
import { VehicleThumbnailSpec } from './vehicleThumbnailSpec';
import { InitialPriceCalc } from '../priceCalculations';
import { CapLoanRange } from '../object/paymentCalculator';

interface VehicleObjectProps {
  object: core.Vehicle;
  select: SelectObjectCallback;
  link?: string | null | undefined;
  navigationParams?: Partial<core.ObjectQueryParameters>;
  storageVehicles?: string;
  updateStorage: (value: string) => void;
  showSuggestedMonthCost: boolean;
}

export function VehicleObject(props: VehicleObjectProps) {

  const { object, link, storageVehicles, updateStorage } = props;
  const condition = transAny('object.specItem.conditionlabel.' + getEnumStringValue(core.Condition, object.condition));

  const calc = InitialPriceCalc({
    price: object.price,
    salePrice: object.salePrice ?? 0,
    calcValues: object.costCalculationValues
  });

  const vehicleMaxAge = CapLoanRange(
    object.costCalculationValues.vehicleMaxAge,
    object.year,
    new Date()
  );

  const handleSelectObject = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    // Set localStorage for correct navigation back to filter
    localStorage.setItem('queryParams', objectToQueryString(props.navigationParams));

    if (e.target instanceof HTMLElement && !(e.target instanceof HTMLButtonElement)) {
      props.select(object);
    }
  };

  return (
    <div className='dso-article-object' key={object.uniqueId}>

      <a href={link ?? '#'} onClick={handleSelectObject} className='dso-article-object-link'>

        <div className='dso-object-fixed-content'>
          {calc.isCampaign && object.status !== 'sold' && <>
            <div className='dso-object-campaign-container'>
              <div className='dso-object-campaign-title'>{trans('object.campaignPriceTitle')}</div>
            </div>
          </>}

          {object.content > 0 && <>
            <div className='dso-article-media-contents'>
              {(object.content & core.ContentStatus.video) === core.ContentStatus.video && <>
                <div className='media-content video'></div>
              </>}
              {(object.content & core.ContentStatus.images360) === core.ContentStatus.images360 && <>
                <div className='media-content image360'></div>
              </>}
            </div>
          </>}
        </div>

        <VehicleObjectPreview
          images={object.thumbnailImages}
          status={object.status}
          isCampaign={calc.isCampaign} />

        <div className='dso-article-object-info'>
          <div className='dso-object-info-container'>

            <div className='dso-object-title-container'>
              <div className='dso-object-title'>
                <strong>{object.type === core.VehicleType.car
                  ? object.title
                  : object.brand + ' ' + object.model}
                </strong>
              </div>
            </div>

            <div className='dso-object-model'>{condition}, {object.year}</div>

            {object.filial && <><div className='dso-object-filial'>{object.filial}</div></>}

            <VehicleThumbnailSpec vehicle={object} />

          </div>

          <div className={className('dso-object-price-container', {compressed: !props.showSuggestedMonthCost})}>
            <div className='dso-object-price'>
              <div className='dso-object-price-current'>

                {/* The actual price */}
                <span className={`${calc.isCampaign ? 'dso-the-sale-price' : ''}`}>
                  {object.priceExTax
                    ?<>
                      <span className='dso-inc-vat-price'>
                        {transFormat('object.price', formatNumber(calc.price * 1.25))}
                      </span>
                      <span className='dso-ex-vat-price'>
                        {transFormat('object.price', formatNumber(calc.price)) + ' ' + trans('object.priceExVat')}
                      </span>
                    </>
                    :<>
                      <span className='dso-inc-vat-price'>
                        {transFormat('object.price', formatNumber(calc.price))}</span>
                    </> }
                </span>

                {/* Show ordinary price if object is has a campaign flag */}
                {calc.isCampaign &&
                  <div className={`dso-object-price-original ${object.priceExTax ? '' : 'inline-price' }`}>
                    <span className='dso-object-price-original-value'>
                      {object.priceExTax
                        ? transFormat('object.price', formatNumber(calc.orgPrice * 1.25))
                        : transFormat('object.price', formatNumber(calc.orgPrice))}
                    </span>
                    <span>{trans('object.orgPrice')}</span>
                  </div>}

                {/* Show minimum payment / month */}
                {props.showSuggestedMonthCost && <>
                  <div className='dso-object-price-month'>
                    {(vehicleMaxAge === null) || (vehicleMaxAge && vehicleMaxAge.financingAvailable) ?
                      transFormat('object.pricePerMonth',
                        (calc.monthlyCost >= object.costCalculationValues.monthlyPaymentMinimum
                          ? formatNumber(calc.monthlyCost)
                          : formatNumber(object.costCalculationValues.monthlyPaymentMinimum) ) )
                      :
                      trans('payment.financing.notAvaiableShort')}
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      </a>

      {useFavourites &&
        <ManageVehicleStorage
          objectId={object.uniqueId}
          storageVehicles={storageVehicles}
          updateStorage={(str) => updateStorage!(str)}
          inline={true} />}
    </div>
  );
}

interface VehicleObjectPreviewProps {
  images: string[] | null;
  status: string;
  isCampaign: boolean;
}

function VehicleObjectPreview(props: VehicleObjectPreviewProps) {

  const images = props.images && props.images.length > 0 ? props.images : [baseUrl + '/i/blank?mw=360&mh=240'];
  const hasStatus = props.status !== null && props.status !== '';

  let classname = 'dso-object-status-title';
  let overlayText = '';

  if (hasStatus) {
    classname += '-' + convertToCSSClassname(props.status) + '-filter';
    overlayText = transAny('object.status.' + props.status.toLowerCase());
  }

  return (
    <div className='dso-object-preview-container'>
      <div className='dso-object-main-images'>

        {hasStatus && <>
          <div className={classname}>{overlayText}</div>
        </>}

        <ImageGallery
          lazyLoad={true}
          showBullets={false}
          showPlayButton={false}
          showFullscreenButton={false}
          renderItem={(item: ReactImageGalleryItem) => {
            return <div className='main-image-container'>
              {hasStatus && <>
                <div className={props.status}>
                </div>
              </>}
              <img src={item.original}></img>
            </div>;
          }}
          showNav={true}
          showThumbnails={false}
          autoPlay={false}
          items={images.map(img => ({ original: img, originalClass: 'dso-main-image' }) as ReactImageGalleryItem)}/>
      </div>

    </div>
  );
}