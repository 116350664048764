/*
 * Generated from Typing.TypeScriptDefinitionQueue
 */

export interface CreateBooking extends PersonalBooking {
  deliveryDate: Date;
  message: string;
  gdpr: string;
  calculation: CreateBookingCalculation;
}

export interface CreateBookingCalculation {
  type?: InfoTextType;
  creditTimeMonths: number;
  downPayment: number;
}

export enum InfoTextType {
  disclaimer = 0,
  general = 1,
  consent = 2
}

export interface PersonalBooking {
  articleNr: string;
  orgNr: string;
  email: string;
  phone: string;
  exchange: boolean;
  exchangeRegNr: string;
  exchangeMilage: string;
  exchangeMessage: string;
}

export interface ArticleResponse {
  articleNr: string;
  title: string;
  price: number;
  downPaymentMin: number;
  downPaymentMax: number;
  interest: number;
  monthsMin: number;
  monthsMax: number;
  smallCreditPrice: number;
  consent: string;
}

export interface CreateBookingResponse {
  hasError: boolean;
  error: string;
  errorDetail: Error;
  approved: boolean;
  bookingId: string;
  contract: ContractDetail;
  calculation: FinancingCalculation;
  texts: InfoTextGroup[];
  pendingResponse: boolean;
  bankIdTimeout: boolean;
}

export interface Error {
  code: number;
  message: string;
  errorParameterValues: ErrorParameter[];
}

export interface ErrorParameter {
  code: number;
  message: string;
  parameterName: string;
}

export interface ContractDetail extends Contract {
  parties: ContractParty[];
  productType: ContractProductType;
  applicantType: ContractApplicantType;
  applicationStatus: ContractApplicationStatus;
  financedAmount: number;
  residualValue: number;
  downPayment: number;
  effectiveInterest: number;
  nominalInterest: number;
  montlyPayment: number;
  administrationFee: number;
  arrangementFee: number;
  termInMonths: number;
  mileage: number;
  excessMileage: ContractExcessMileage;
}

export interface ContractParty {
  id: number;
  name: string;
  partyIdentifier: string;
  role: Role;
  email: string;
  phone: string;
}

export enum Role {
  buyer = 0,
  coBuyer = 1,
  dealer = 2,
  guarantor = 3,
  beneficialOwner = 4,
  signatory = 5,
  deliveryRecipient = 6
}

export enum ContractProductType {
  undefined = 0,
  loan = 1,
  lease = 2
}

export enum ContractApplicantType {
  undefined = 0,
  consumer = 1,
  company = 2,
  soleProprietorship = 3
}

export enum ContractApplicationStatus {
  undefined = 0,
  received = 1,
  approved = 2,
  rejected = 3,
  control = 4,
  error = 5,
  accepted = 6,
  validated = 7,
  cancelled = 8,
  activated = 9
}

export interface ContractExcessMileage {
  mileageUnit: number;
  pricePerUnit: number;
}

export interface Contract {
  id: number;
  title: string;
  contractNumber: string;
  status: ContractStatus;
  contractType: string;
  registrationNumber: string;
  isDigitalSignable: boolean;
}

export enum ContractStatus {
  cancelled = 0,
  generating = 1,
  ready = 2,
  signStarted = 3,
  signComplete = 4,
  waitingForApproval = 5
}

export interface FinancingCalculation {
  cashPrice: number;
  downPayment: number;
  residualDebt: number;
  creditTime: number;
  interest: number;
}

export interface InfoTextGroup {
  textType: InfoTextType;
  texts: InformationText[];
}

export interface InformationText {
  id: number;
  name: string;
  text: string;
}

export interface ContractSignResponse {
  parties: DigitalSignParty[];
  isDigitalSignable: boolean;
  documents: ContractSignDocument[];
}

export interface DigitalSignParty extends ContractParty {
  availableSignMethods: string[];
  activeSignature: Signature;
}

export interface Signature {
  status: SignatureStatus;
  method: SignatureMethod;
}

export enum SignatureStatus {
  unsigned = 0,
  pending = 1,
  signed = 2,
  failed = 3,
  emailSent = 4
}

export enum SignatureMethod {
  easysign = 0,
  bankId = 1,
  bankIdExternal = 2
}

export interface ContractSignDocument {
  link: string;
  name: string;
}

export interface SignBookingResponse {
  hasError: boolean;
  error: string;
  url: string;
}

export interface InfoTextResponse {
  error: string;
  articleNr: string;
  textGroups: InfoTextGroup[];
}

export interface DigitalSignStatusResponse {
  status: DigitalSignStatusResponseValue;
}

export enum DigitalSignStatusResponseValue {
  unsigned = 0,
  pending = 1,
  signed = 2,
  failed = 3,
  emailSent = 4
}

