/*
 * Generated from Typing.TypeScriptDefinitionQueue
 */

export interface PrintParameters {
  html: string;
}

export interface EffectiveRateQueryParameters {
  nrOfMonthlyPayments: number;
  monthlyPayment: number;
  invoiceFee: number;
  totalCost: number;
  downPayment: number;
  setupFee: number;
  stateFee: number;
}

export interface VehiclePrint {
  active: boolean;
  logoPath: string;
}

export interface GeneralConfig {
  mainPhoneNumber: string;
  suggestedMonthCost: boolean;
  defaultGridView: boolean;
  showArticleNr: boolean;
  share: VehicleShare;
  print: VehiclePrint;
}

export interface VehicleShare {
  facebook: boolean;
  messenger: boolean;
  mail: boolean;
  link: boolean;
}

export interface Favourites {
  email: string;
  vehicles: VehicleExtended[];
}

export interface VehicleExtended extends Vehicle {
  baseUrl: string;
  language: string;
  description: string;
  adInfo: string;
  equipment: VehicleEquipment[];
  descriptionEquipments: DescriptionEquipment[];
  dealershipName: string;
  seller: Seller[];
  relatedObjects: Vehicle[];
  testReport: string[];
  youTube: string;
  images360: string[];
  buyOnline: BuyOnlineType;
  financeType: CheckoutFinanceType;
  showExchange: boolean;
  heating: string[];
  source: string | null;
  files?: FileResource[] | null;
}

export interface VehicleEquipment {
  articleNr: string;
  name: string;
  price: number;
}

export interface DescriptionEquipment {
  name: string;
}

export interface Seller {
  name: string;
  title: string;
  description: string;
  phoneNumber: string;
  email: string;
  image: string;
}

export interface Vehicle {
  uniqueId: string;
  created: Date;
  published: Date;
  brand: string;
  category: string;
  model: string;
  year: number;
  milage: number;
  gearbox: VehicleGearbox;
  fuel: VehicleFuel;
  type: VehicleType;
  effect: number;
  weight: number;
  regNr: string;
  title: string;
  condition: Condition;
  images: string[];
  slideshowImages: string[];
  thumbnailImages: string[];
  price: number;
  salePrice?: number;
  pricePerMonth: number;
  priceExTax: boolean;
  essentialSpecs: VehicleSpec[];
  specifications: VehicleSpec[];
  mainPhoneNumber: string;
  mainEmailAddress: string;
  mainAddress: string;
  pageId?: number;
  pageLink: string;
  friendlyId: string;
  articleNr: string;
  status: string;
  isSold: boolean;
  isReserved: boolean;
  soldDate: Date;
  filial: string;
  content: ContentStatus;
  beds: number;
  layout: string;
  length: number;
  width: number;
  height: number;
  passengers: number;
  costCalculationValues: ICostCalculation;
}

export enum VehicleGearbox {
  manual = 0,
  automatic = 1,
  sequential = 2
}

export enum VehicleFuel {
  unknown = 0,
  gasoline = 1,
  diesel = 2,
  hybridGasoline = 3,
  hybridDiesel = 4,
  naturalGas = 5,
  electricity = 6,
  gasolineEthanol = 7,
  gasolineGas = 8
}

export enum VehicleType {
  any = 0,
  caravan = 1,
  mobileHome = 2,
  car = 3,
  mcAtv = 4,
  snowmobile = 5,
  boat = 6,
  trailer = 7,
  transport = 8,
  seascooter = 9,
  unknown = 99
}

export enum Condition {
  all = 0,
  new = 1,
  used = 2
}

export interface VehicleSpec {
  title: string;
  value: string;
  icon: string;
  category: string;
}

export enum ContentStatus {
  none = 0,
  video = 1,
  images360 = 2
}

export interface ICostCalculation {
  downPaymentMaximum: number;
  downPaymentMinimum: number;
  initialDownPayment: number;
  initialLoanPaymentYears: number;
  vehicleMaxAge: number;
  loanInterest: number;
  loanPaymentYearsMaximum: number;
  monthlyPaymentMinimum: number;
  monthSteps: number;
  stateFee: number;
  setupFee: number;
  invoiceFee: number;
}

export enum BuyOnlineType {
  none = 0,
  cash = 1,
  finance = 2
}

export enum CheckoutFinanceType {
  none = 0,
  santander = 1,
  ecster = 2
}

export interface FileResource {
  name: string;
  url: string;
  ext: string;
  size?: number;
}

export interface InterestForm {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  message: string;
  uniqueId: string;
  referer?: string;
}

export enum ObjectOrder {
  default = 0,
  created = 1,
  price = 2,
  title = 3,
  year = 4,
  mileage = 5,
  effect = 6,
  random = 99
}

export interface ObjectQueryParameters {
  searchText: string;
  max?: number;
  offset?: number;
  sortOrder: string;
  priceFrom?: number;
  priceTo?: number;
  yearFrom?: number;
  yearTo?: number;
  brands: string;
  categories: string;
  weightMin?: number;
  weightMax?: number;
  selectedObjects: string;
  campaignOnly?: boolean;
  hideSold?: boolean;
  groups: string;
  filials: string;
  condition?: Condition;
  type?: VehicleType;
  bedsMin?: number;
  bedsMax?: number;
  layouts: string;
  lengthMin?: number;
  lengthMax?: number;
  widthMin?: number;
  widthMax?: number;
  heightMin?: number;
  heightMax?: number;
  passengersMin?: number;
  passengersMax?: number;
  gearbox: string;
  fuel: string;
  milageMin?: number;
  milageMax?: number;
  effectFrom?: number;
  effectTo?: number;
  heating: string;
}

export interface ObjectQueryResult {
  language: string;
  items: Vehicle[];
}

export enum OrderDirection {
  desc = 0,
  asc = 1
}

export interface SeascooterFilterDataType extends FilterDataType {
  type: VehicleType;
  lengthInterval: FilterIntervalOption;
  effectInterval: FilterIntervalOption;
  passengerInterval: FilterIntervalOption;
}

export interface FilterIntervalOption {
  min?: number | null;
  max?: number | null;
  step?: number | null;
  unit: string;
  formatNumber: boolean;
}

export interface FilterDataType {
  type: VehicleType;
  name: string;
  layouts: string[];
  filials: string[];
  brands: string[];
  categories: FilterCategory[];
  modelYearInterval: FilterIntervalOption;
  priceInterval: FilterIntervalOption;
  weightInterval: FilterIntervalOption;
}

export interface FilterCategory {
  id: string;
  title: string;
  icon: string;
}

export interface TrailerFilterDataType extends FilterDataType {
  type: VehicleType;
  lengthInterval: FilterIntervalOption;
}

export interface CaravanFilterDataType extends FilterDataType {
  type: VehicleType;
  bedsInterval: FilterIntervalOption;
  lengthInterval: FilterIntervalOption;
  widthInterval: FilterIntervalOption;
  heightInterval: FilterIntervalOption;
  heating: string[];
}

export interface CarFilterDataType extends FilterDataType {
  type: VehicleType;
  enginePowerInterval: FilterIntervalOption;
  milageInterval: FilterIntervalOption;
  effectInterval: FilterIntervalOption;
  gearbox: string[];
  fuel: string[];
}

export interface FilterData {
  types: FilterDataType[];
  language: string;
  baseUrl: string;
}

export interface FilterQueryParameters {
  groups: string;
  vehicleTypes?: VehicleType[];
}

export interface McAtvFilterDataType extends FilterDataType {
  type: VehicleType;
  milageInterval: FilterIntervalOption;
  effectInterval: FilterIntervalOption;
}

export interface MobileHomeFilterDataType extends FilterDataType {
  type: VehicleType;
  bedsInterval: FilterIntervalOption;
  lengthInterval: FilterIntervalOption;
  passengerInterval: FilterIntervalOption;
  milageInterval: FilterIntervalOption;
  effectInterval: FilterIntervalOption;
  widthInterval: FilterIntervalOption;
  heightInterval: FilterIntervalOption;
  gearbox: string[];
  heating: string[];
}

export interface BoatFilterDataType extends FilterDataType {
  type: VehicleType;
  effectInterval: FilterIntervalOption;
  lengthInterval: FilterIntervalOption;
  passengerInterval: FilterIntervalOption;
  bedsInterval: FilterIntervalOption;
}

export interface SnowmobileFilterDataType extends FilterDataType {
  type: VehicleType;
  milageInterval: FilterIntervalOption;
  effectInterval: FilterIntervalOption;
}

export interface TransportFilterDataType extends CarFilterDataType {
  type: VehicleType;
}

export interface UnknownFilterDataType extends FilterDataType {
  type: VehicleType;
}

export interface SellerAdmin {
  id: number;
  visible: boolean;
  firstName: string;
  lastName: string;
  title: string;
  description: string;
  phone: string;
  email: string;
  filial: string;
  vehicleType: VehicleType;
  imageUrl: string;
}

export interface SettingVariable {
  unit: SettingUnit;
  name: string;
  variable: string;
  lineNumber: number;
  value: string;
  defaultValue: string;
  isChanged: boolean;
}

export enum SettingUnit {
  color = 0,
  font = 1,
  size = 2
}

export interface CreateCashBooking {
  regNr: string;
  firsName: string;
  lastName: string;
  address: string;
  city: string;
  zipCode: string;
  deliveryDate: Date;
  articleNr: string;
  orgNr: string;
  email: string;
  phone: string;
  exchange?: boolean;
  exchangeRegNr?: string;
  exchangeMilage?: string;
  exchangeMessage?: string;
}

