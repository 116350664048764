import * as React from 'react';
import { SantanderCheckout } from './checkout/santanderCheckout';
import { CheckoutFinanceType } from '../model/core';
import { EcsterCheckout } from './checkout/ecsterCheckout';
import { CheckoutProps } from './checkout/common';

interface Props extends CheckoutProps {
  type: CheckoutFinanceType;
}

export function Checkout(props: Props) {
  const getCheckout = () => {
    switch (props.type) {
      case CheckoutFinanceType.santander:
        return <SantanderCheckout {...props} />;

      case CheckoutFinanceType.ecster:
        return <EcsterCheckout {...props} />;

      default:
        return null;
    }
  };

  return (
    <div id='checkoutContainer'>
      {getCheckout()}
    </div>
  );
}