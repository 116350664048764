import * as React from 'react';
import * as core from '../../model/core';
import ReactPannellum, { getConfig } from 'react-pannellum';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { translate as trans, translateFormat as transFormat } from '../../lang';
import { FaIcon } from '../../icon';

interface Images360Props {
  images: string[] | null;
  objectType: number;
}

export function Images360(props: Images360Props) {
  if (!props.images || !props.images.length) {
    return null;
  }

  return (
    <div className='dso-object-pannellum-container'>
      <div className='dso-object-pannellum-slideshow'>
        <div className='dso-object-main-images'>

          <div className='dso-images360-explanation'>
            <h2><FaIcon name={'street-view'} /> {trans('object.panorama.header')}</h2>
            <span>{transFormat('object.panorama.desc', trans(
              props.objectType === core.VehicleType.boat ||
              props.objectType === core.VehicleType.seascooter
                ? 'general.object'
                : 'general.vehicle'
            ).toLocaleLowerCase())}</span>
          </div>

          {props.images.length > 1 ? <>
            <ImageGallery
              showBullets={false}
              showPlayButton={false}
              showFullscreenButton={false}
              renderItem={(item: ReactImageGalleryItem) => {
                return <div className='main-image-container'>
                  <ReactPannellum
                    id={item.original}
                    sceneId={item.original}
                    imageSource={item.original}
                    config={{
                      width: '100%',
                      height: '300px',
                      pitch: 10,
                      yaw: 180,
                      hfov: 110,
                      autoLoad: true,
                      mouseZoom: false
                    }} />
                </div>;
              }}
              showNav={false}
              showThumbnails={true}
              autoPlay={false}
              items={props.images.map(img => ({
                original: img,
                thumbnail: img,
                originalClass: 'dso-main-image'
              }) as ReactImageGalleryItem)} />
          </> :
            <ReactPannellum
              id={props.images[0]}
              sceneId={props.images[0]}
              imageSource={props.images[0]}
              config={{
                width: '100%',
                height: '300px',
                pitch: 10,
                yaw: 180,
                hfov: 110,
                autoLoad: true,
                mouseZoom: false
              }} />
          }
        </div>
      </div>
    </div>
  );
}