import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { autobind } from './decorator';
import { FaIcon } from './icon';
import { KeyCodes, className } from './util';
import { translate } from './lang';

interface PortalProps {
  visible: boolean;
  id?: string;
  onClose?: () => void;
}

export class Portal extends React.Component<PortalProps> {

  private containerElm?: HTMLElement;
  private closeElm?: HTMLElement;

  constructor(props: PortalProps) {
    super(props);
  }

  close() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  @autobind
  handleKeyDown(e: KeyboardEvent) {
    if (e.keyCode === KeyCodes.escape) {
      this.close();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps: PortalProps) {
    if (prevProps.visible !== this.props.visible) {
      document.body.classList.toggle('dso-portal-body', this.props.visible);
    }
  }

  @autobind
  handleClose(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    if (e.target === this.containerElm || e.target === this.closeElm) {
      this.close();
    }
  }

  render() {
    return ReactDOM.createPortal(
      <div id={this.props.id} className={className('dso-portal-container', { 'dso-visible': this.props.visible })}
        ref={elm => this.containerElm = elm as HTMLElement}
        onClick={this.handleClose}>

        <div className='dso-portal-content-container'>
          <div className='dso-portal-close'
            ref={elm => this.closeElm = elm as HTMLElement}
            onClick={this.handleClose}>
            {translate('checkout.closeWindow')} <FaIcon name='times' />
          </div>

          {this.props.visible && <>
            <div className='dso-portal-content'>
              {this.props.children}
            </div>
          </>}
        </div>
      </div>, document.body);
  }
}