import * as React from 'react';
import { formatNumber } from '../../../util';
import { translate } from '../../../lang';

interface PaymentTogglerProps {
  price: number;
}

export function CashPaymentCalculator(props: PaymentTogglerProps) {

  return (
    <div className='empori-payment-calc'>

      <div className='empori-payment-montly-cost'>
        <h4>{translate('payment.cash.title')}</h4>
        {formatNumber(props.price)} kr
      </div>

      <div className='empori-payment-calc-cashinfo'>
        <span>{translate('payment.cash.info')}</span>
        <i className='fas fa-coins'></i>
      </div>
    </div>
  );
}