import * as React from 'react';
import {
  CreateBookingResponse,
  Role,
  ContractParty,
  ContractSignResponse,
  ContractSignDocument,
  SignatureMethod,
  DigitalSignStatusResponseValue,
  InfoTextType,
  InfoTextGroup
} from '../santander.core';
import { getEnumValueCamel, camelCase, formatNumber } from '../../util';
import { autobind } from '../../decorator';
import * as santanderApi from './api/santanderApi';
import { translate as trans, translateFormat as transFormat } from '../../lang';

enum Status {
  idle,
  fetchingUrl,
  signing,
  pending,
  signComplete,
  reserving,
  reserved,
  error
}

interface BookingProps {
  booking: CreateBookingResponse;
  articleNr: string;
}

interface BookingState {
  sign: ContractSignResponse | null;
  signUrl?: string | null;
  loading: boolean;
  signError?: string | null;
  signMessage?: string | null;
  status: Status;
  signStatus: DigitalSignStatusResponseValue;
  signStartTime: number;
  disclaimer?: InfoTextGroup | null;
}

const statusPollIntervalMs = 2000;
const statusPollLimitMs = 1000 * 60 * 60; // 1h

export class SantanderBooking extends React.Component<BookingProps, BookingState> {

  constructor(props: BookingProps) {
    super(props);
    this.state = {
      sign: null,
      loading: true,
      status: Status.idle,
      signStatus: DigitalSignStatusResponseValue.unsigned,
      signStartTime: 0
    };
  }

  async fetchSignUrl() {
    this.setState({
      loading: true,
      status: Status.fetchingUrl
    });

    const sign = await santanderApi.getBookingSignUrl(this.props.booking.bookingId, SignatureMethod.bankId);

    if (sign.url && !sign.hasError) {
      window.open(sign.url, '_blank');
    }

    const status = sign.hasError ? Status.error : Status.signing;

    this.setState({
      loading: false,
      signUrl: sign.url,
      status,
      signStartTime: Date.now(),
      signError: sign.hasError ? sign.error : null
    });

    if (status === Status.signing) {
      this.checkSignStatus();
    }
  }

  async reserve() {
    this.setState({
      loading: true,
      status: Status.reserving
    });

    const reserveStatus = await santanderApi.reserveBooking(this.props.booking.bookingId);

    this.setState({
      loading: false,
      status: reserveStatus ? Status.reserved : Status.error
    });
  }

  componentDidMount() {
    if (this.props.booking.contract.isDigitalSignable) {
      // Documents are not available if not signable.
      this.fetchSign();
    }

    this.fetchDisclaimer();
  }

  async fetchSign() {
    const sign = await santanderApi.getBookingSign(this.props.booking.bookingId);
    this.setState({ sign, loading: false });
  }

  async fetchDisclaimer() {
    const text = await santanderApi.getArticleInfoTexts(this.props.articleNr, {
      creditTimeMonths: this.props.booking.calculation.creditTime,
      downPayment: this.props.booking.calculation.downPayment,
      type: InfoTextType.disclaimer
    });

    this.setState({ disclaimer: text.textGroups[0] });
  }

  @autobind
  checkSignStatus() {
    if (this.state.status === Status.signComplete || this.state.status === Status.error) {
      return;
    }

    if (Date.now() >= this.state.signStartTime + statusPollLimitMs) {
      this.setState({
        status: Status.error,
        signError: trans('santander.error.timeout')
      });
      return;
    }

    setTimeout(async () => {
      const statusResponse = await santanderApi.getSignStatus(this.props.booking.bookingId);
      const status = getEnumValueCamel(DigitalSignStatusResponseValue, statusResponse);

      if (this.state.signStatus !== status) {
        let bookingStatus = this.state.status;
        let signError = this.state.signError;
        let signMessage = this.state.signMessage;

        switch (status) {
          case DigitalSignStatusResponseValue.pending:
            bookingStatus = Status.pending;
            signMessage = trans('santander.status.pending');
            break;

          case DigitalSignStatusResponseValue.signed:
            bookingStatus = Status.signComplete;
            signError = null;
            signMessage = trans('santander.status.signed');
            break;

          case DigitalSignStatusResponseValue.failed:
            bookingStatus = Status.error;
            signError = trans('santander.status.error');
            break;

          case DigitalSignStatusResponseValue.emailSent:
            bookingStatus = Status.signing;
            signMessage = trans('santander.status.mailSent');
            break;
        }

        this.setState({
          signStatus: status,
          status: bookingStatus,
          signError,
          signMessage
        });
      }

      if (status !== DigitalSignStatusResponseValue.signed &&
          status !== DigitalSignStatusResponseValue.failed) {
        this.checkSignStatus();
      }
    }, statusPollIntervalMs);
  }

  render() {
    const { sign, signError, signMessage, status, signUrl, signStatus, disclaimer } = this.state;
    const { booking } = this.props;
    const { contract } = booking;

    if (booking.hasError) {
      return (
        <div className='empori-booking booking-error'>
          <div className='error-title'>
            {trans('finance.status.error')}
          </div>
          <div className='error-message'>{booking.error}</div>
        </div>
      );
    }

    if (status === Status.error) {
      return (
        <div className='empori-booking sign-error'>
          <div className='error-title'>
            {trans('finance.status.signError')}
          </div>
          <div className='error-message'>{signError}</div>
        </div>
      );
    }

    const buyer = contract.parties.find(p => getEnumValueCamel(Role, p.role) === Role.buyer);
    const dealer = contract.parties.find(p => getEnumValueCamel(Role, p.role) === Role.dealer);

    return (
      <div className='empori-booking'>
        <h3>{contract.title}</h3>

        <div className='empori-booking-pretext'>

          {/* Needs language formatting. */}

          <p>Din förfrågan om finansiering har blivit godkänd. Om du väljer att gå vidare med din beställning reserveras fordonet, och vi kontaktar dig inom kort för att gå igenom följande steg i köpprocessen:</p>
            <ul>
              <li>Slutgiltigt leveransdatum</li>
              <li>Betalning</li>
              <li>Försäkring</li>
            </ul>
          <p>Du har inte förbundit dig till köp och betalar ingenting innan du har haft kontakt med oss.</p>

        </div>

        {!booking.contract.isDigitalSignable && <>
          <div className='empori-booking-verification-confirmed'>
            <span>{trans('finance.verification.confirmed')}</span>
          </div>
        </>}

        {buyer && <>
          <Party label={trans('finance.buyer')} party={buyer} />
        </>}

        {dealer && <>
          <Party label={trans('finance.seller')} party={dealer} />
        </>}

        <div className='empori-booking-details'>
          <strong>{trans('finance.payment')}</strong>
          <Detail label={trans('finance.termInMonths')} value={contract.termInMonths} unit={trans('finance.unit.month')} />
          <Detail label={trans('finance.nominalInterest')} value={contract.nominalInterest} unit='%' format={true} precision={2} />
          <Detail label={trans('finance.effectiveInterest')} value={contract.effectiveInterest} unit='%' format={true} precision={2} />
          <Detail label={trans('finance.financedAmount')} value={contract.financedAmount} unit={trans('finance.unit.currency')} format={true} />
          <Detail label={trans('finance.downPayment')} value={contract.downPayment} unit={trans('finance.unit.currency')} format={true} />
          <Detail label={trans('finance.administrationFee')} value={contract.administrationFee} unit={trans('finance.unit.currency')} format={true} />
          <Detail label={trans('finance.arrangementFee')} value={contract.arrangementFee} unit={trans('finance.unit.currency')} format={true} />
        </div>

        {sign && <>
          <div className='empori-sign-docs'>
            {sign.documents.map(doc =>
              <SignDoc key={doc.link} doc={doc} />)}
          </div>
        </>}

        {disclaimer && <>
          <div className='empori-booking-disclaimer'>
            {disclaimer.texts.map(d =>
              <span key={d.id} dangerouslySetInnerHTML={{ __html: d.text }}></span>)}
          </div>
        </>}

        <div className='empori-booking-signature'>
          {status === Status.idle && <>
            <button className='empori-sign-button dso-action-button' onClick={e => {
              e.preventDefault();

              this.reserve();
              //this.fetchSignUrl();
            }}>{trans('finance.form.reserve')}</button>
          </>}

          {status === Status.reserving && <>
            <strong>{trans('finance.status.reserving')}</strong>
          </>}

          {status === Status.reserved && <>
            <strong>{trans('finance.status.reserved')}</strong>
          </>}

          {status === Status.fetchingUrl && <>
            <strong>{trans('finance.status.fetchingUrl')}</strong>
          </>}

          {status === Status.signing && <>
            <strong>{signMessage ? signMessage : trans('finance.status.signing')}</strong>

            {signUrl && <>
              <div>
                <a href={signUrl ?? ''} target='_blank'>{trans('finance.status.signingInfo1')}</a> {trans('finance.status.signingInfo2')}
              </div>
            </>}
          </>}

          {(status === Status.signComplete || status === Status.pending) && <>
            <strong>{signMessage}</strong>
          </>}
        </div>
      </div>
    );
  }
}

interface PartyProps {
  label: string;
  party: ContractParty;
}

function Party({ label, party }: PartyProps) {
  return (
    <div className={`empori-party ${camelCase(party.role + '')}`}>
      <strong>{label}</strong>

      <PartyRow label={trans('finance.party.name')} value={party.name} />

      {party.role === Role.buyer && <>
        <PartyRow label={trans('finance.party.pnr')} value={party.partyIdentifier} />
      </>}

      <PartyRow label={trans('finance.party.email')} value={party.email} />
      <PartyRow label={trans('finance.party.phone')} value={party.phone} />
    </div>
  );
}

interface PartyRowProps {
  label: string;
  value: string;
}

function PartyRow(props: PartyRowProps) {
  return (
    <div className='empori-party-row'>
      <div className='empori-party-label'>{props.label}</div>
      <div className='empori-party-value'>{props.value}</div>
    </div>
  );
}

interface SignDocProps {
  doc: ContractSignDocument;
}

function SignDoc({ doc }: SignDocProps) {
  return (
    <a href={doc.link} target='_blank' className='empori-sign-doc'>
      <strong>{doc.name}</strong>
      <img src={`/i/${doc.link}?mw=150`} />
    </a>
  );
}

interface DetailProps {
  label: string;
  value: string | number;
  unit?: string;
  format?: boolean;
  precision?: number;
}

function Detail(props: DetailProps) {
  return (
    <div className='empori-detail-row'>
      <div className='empori-detail-label'>{props.label}</div>
      <div className='empori-detail-value'>
        {props.format ? formatNumber(+props.value, props.precision) : props.value}
        {props.unit && <>
          <span className='empori-detail-unit'>{props.unit}</span>
        </>}
      </div>
    </div>
  );
}