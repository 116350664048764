import * as React from 'react';
import { InterestInput, DsoCheckbox, ActionButton, InterestInputMessage } from './input';
import * as core from '../../../model/core';
import * as api from '../../../api/vehicleFilterApi';
import { Warning } from '../../../warning';
import { Seller } from './seller';
import { autobind } from '../../../decorator';
import * as validation from './validation';
import { translate as trans, translateFormat as transFormat } from '../../../lang';
import * as gtm from '../../../gtm';

interface InterestFormProps {
  vehicle: core.VehicleExtended;
  lite?: boolean;
}

interface InterestFormState {
  showIntegrityModal: boolean;
  integrityPolicy: boolean;
  interestForm: core.InterestForm;
  interestFormValidation: Partial<core.InterestForm>;
  formPosted: boolean;
}

export class InterestForm extends React.Component<InterestFormProps, InterestFormState> {

  public element: HTMLElement | null = null;

  constructor(props: InterestFormProps) {
    super(props);

    this.state = {
      showIntegrityModal: false,
      integrityPolicy: false,
      interestForm: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        uniqueId: props.vehicle.uniqueId
      },
      interestFormValidation: {},
      formPosted: false
    };
  }

  async validateFields() {
    const schema = this.props.lite
      ? validation.interestFormSchemaLite
      : validation.interestFormSchemaAll;

    const { interestForm, integrityPolicy } = this.state;
    const { result, errorThrown } = validation.validateFields(interestForm, schema);

    this.setState({ interestFormValidation: result });

    if (integrityPolicy && !errorThrown) {
      const formPosted = await this.postForm();

      if (formPosted) {
        gtm.interestFormSubmit();
      }

      this.setState({ formPosted });
    } else {
      this.setState({ showIntegrityModal: !integrityPolicy });
    }
  }

  @autobind
  async postForm() {
    return await api.postInterest(this.state.interestForm);
  }

  @autobind
  handleChange(key: keyof core.InterestForm) {
    return (text: string) => {
      this.setState({
        interestForm: {
          ...this.state.interestForm,
          [key]: text
        },
        interestFormValidation: {
          ...this.state.interestFormValidation,
          [key]: ''
        }
      });
    };
  }

  render() {
    const { lite, vehicle } = this.props;
    const {
      interestForm,
      interestFormValidation,
      integrityPolicy,
      showIntegrityModal,
      formPosted
    } = this.state;

    if (formPosted) {
      return (
        <div className='dso-object-notification-of-interest-container'>
          <h2>{trans('form.interest.title')}</h2>
          <div className='dso-object-notification-of-interest-data-container'>{trans('form.interest.submitted')}</div>
        </div>
      );
    }

    return (
      <div className='dso-object-notification-of-interest-container' ref={elm => this.element = elm}>
        <h2>{trans('form.interest.title')}</h2>
        <div className='dso-object-notification-of-interest-data-container'>
          <div className='dso-object-notification-of-interest-data-column'>
            {!lite && <>
              <div className='dso-object-notification-of-interest-customer-data-row'>
                <InterestInput
                  name={trans('finance.form.firstname')}
                  change={this.handleChange('firstName')}
                  error={interestFormValidation.firstName}
                  value={interestForm.firstName}
                  cssClass='dso-input-container'
                  autoComplete='given-name'
                  visible={true} />

                <InterestInput
                  name={trans('finance.form.lastname')}
                  change={this.handleChange('lastName')}
                  error={interestFormValidation.lastName}
                  value={interestForm.lastName}
                  cssClass='dso-input-container'
                  autoComplete='family-name'
                  visible={true} />
              </div>
            </>}

            <div className='dso-object-notification-of-interest-customer-data-row'>
              <InterestInput
                name={trans('finance.form.phone')}
                change={this.handleChange('phone')}
                error={interestFormValidation.phone}
                value={interestForm.phone}
                cssClass='dso-input-container'
                autoComplete='tel'
                visible={true} />

              <InterestInput
                name={trans('finance.form.email')}
                change={this.handleChange('email')}
                error={interestFormValidation.email}
                value={interestForm.email}
                cssClass='dso-input-container'
                autoComplete='email'
                visible={true} />
            </div>

            <div className='dso-object-notification-of-interest-customer-data-row textarea-row'>
              <InterestInputMessage
                name={trans('finance.form.message')}
                change={this.handleChange('message')}
                error={interestFormValidation.message}
                value={interestForm.message}
                cssClass='dso-input-container'
                visible={true} />
            </div>

            <div className='dso-object-notification-of-interest-customer-accept-terms'>
              <DsoCheckbox
                toggle={() => this.setState({ integrityPolicy: !integrityPolicy })}
                checked={this.state.integrityPolicy}
                cssClass='checkbox'
                id='integrityPolicy'>

                {transFormat('form.interest.policy', vehicle.dealershipName)}
              </DsoCheckbox>
            </div>

            <div className='dso-object-notification-of-interest-send-button'>
              <ActionButton
                label={trans('form.interest.send')}
                icon='paper-plane'
                click={() => { this.validateFields(); }} />

              <Warning
                visible={showIntegrityModal}
                onClose={() => this.setState({ showIntegrityModal: false})}>

                {transFormat('form.interest.policyWarning', vehicle.dealershipName)}
              </Warning>
            </div>
          </div>

          <div className='dso-object-notification-of-interest-data-column'>
            <div className='dso-object-notification-of-interest-sellers'>
              {vehicle.seller && vehicle.seller.map((seller, index) =>
                <Seller key={seller.email + index} seller={seller} objectType={vehicle.type} />)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
