import * as React from 'react';
import { InputProps, createDefaultModelInput, Input } from './input';
import { CheckoutModel } from '../model';

interface TextAreaProps<T extends CheckoutModel> extends InputProps<T> {
  placeholder?: string;
}

export function TextArea<T extends CheckoutModel>(props: TextAreaProps<T>) {
  const model = props.model[props.id]! || createDefaultModelInput(props);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;

    props.change(props.id, {
      ...model,
      value,
      dirty: value !== model.initialValue
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (props.blur) {
      props.blur(props.id);
    }
  };

  return (
    <Input {...props}>
      <textarea
        data-checkout-key={props.id}
        className='empori-input'
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        required={props.required}
        disabled={props.disabled}
        value={model.value}>
      </textarea>
    </Input>
  );
}