export interface SchemaType {
  handle: (key: string, value: string) => {};
}

export type QueryStringSchema<T> = Record<keyof T, SchemaType>;

interface FuncCallback<T> {
  (key: string, value: string): T;
}

export function func<T>(funcCallback: FuncCallback<T>): SchemaType {
  return {
    handle(key, value) {
      return funcCallback(key, value);
    }
  };
}

export function string(defaultValue?: () => string) {
  return func((_, value) => value ?? defaultValue?.());
}

export function number(defaultValue?: () => number) {
  return func((_, value) => {
    let num = +value;

    if (isNaN(num)) {
      return defaultValue?.() ?? 0;
    }

    return num;
  });
}

export function bool() {
  return func((_, value) => {
    let val = false;
    const boolVal = value?.toLowerCase();
    const boolNum = +boolVal;

    if (!isNaN(boolNum)) {
      val = !!boolNum;
    } else if (boolVal === 'true') {
      val = true;
    }

    return val;
  });
}

export function object(obj: any, defaultValue?: () => any) {
  return func((_, value) => {
    if (value in obj) {
      return obj[value];
    }

    if (defaultValue) {
      return defaultValue();
    }

    return null;
  });
}