import * as React from 'react';
import { autobind, debounce } from '../../decorator';
import { className, KeyCodes, formatNumber } from '../../util';
import { FaIcon } from '../../icon';
import * as core from '../../model/core';
import * as noUiSlider from 'nouislider';
import { translate, translateFormat } from '../../lang';

export interface OptionItemData {
  label: string;
  value: any;
}

interface SelectOptionProps {
  label: string;
  listLabel?: string | null;
  hasValueSelected?: boolean;
}

interface SelectOptionState {
  open: boolean;
}

export class SelectOption extends React.Component<SelectOptionProps, SelectOptionState> {

  private optionElm?: HTMLElement;

  constructor(props: SelectOptionProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleElementAwareCloseClick);
    document.addEventListener('keyup', this.handleClose);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleElementAwareCloseClick);
    document.removeEventListener('keyup', this.handleClose);
  }

  @autobind
  handleElementAwareCloseClick(e: MouseEvent) {
    if (!this.state.open || !this.optionElm) {
      return;
    }

    let elm = document.elementFromPoint(e.clientX, e.clientY);

    if (elm) {
      do {
        if (elm === this.optionElm) {
          return;
        }

        elm = elm.parentElement;
      } while (elm && elm !== document.body);

      this.handleToggle(false);
    }
  }

  @autobind
  handleClose(e: KeyboardEvent) {
    if (e.keyCode === KeyCodes.escape) {
      this.handleToggle(false);
    }
  }

  @autobind
  handleToggle(state?: boolean) {
    this.setState({ open: state ?? !this.state.open });
  }

  render() {
    const { label, listLabel, children, hasValueSelected } = this.props;
    const { open } = this.state;

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      this.handleToggle();
    };

    return (
      <div
        ref={elm => this.optionElm = elm as HTMLElement}
        className={className('dso-select-option', { 'dso-open': open })}>

        <div className='dso-select-option-label'>{label}</div>

        <div
          className={className('dso-select-option-result', { 'dso-value-selected': hasValueSelected })}
          onClick={handleClick}>

          <div className='dso-select-option-result-text'>{listLabel}</div>
          <FaIcon name={open ? 'angle-up' : 'angle-down'} />
        </div>

        <div className='dso-select-option-content'>
          {children}
        </div>
      </div>
    );
  }
}

interface MultiSelectOptionProps extends SelectOptionProps {
  items: OptionItemData[];
  selectedValues: any[];
  select: (items: OptionItemData[]) => void;
}

export class MultiSelectOption extends React.Component<MultiSelectOptionProps> {

  constructor(props: MultiSelectOptionProps) {
    super(props);
  }

  @autobind
  handleToggle(item: OptionItemData) {
    return () => {
      const { selectedValues, select } = this.props;
      const items = selectedValues.slice(0);
      const index = selectedValues.indexOf(item.value);

      if (index === -1) {
        items.push(item.value);
      } else {
        items.splice(index, 1);
      }

      select(items);
    };
  }

  getListLabel() {
    const { selectedValues, items } = this.props;

    if (!selectedValues.length) {
      return translate('filter.list.all');
    }

    const selectedItems = items
      .filter(it => selectedValues.indexOf(it.value) !== -1)
      .map(it => it.label);

    const stringList = selectedItems.join(', ');

    if (selectedValues.length === 1) {
      return stringList;
    }

    return `${selectedItems.length} st ${stringList}`;
  }

  render() {
    const { selectedValues } = this.props;

    return (
      <SelectOption {...this.props} listLabel={this.getListLabel()} hasValueSelected={selectedValues.length > 0}>
        <ul className='dso-multiselect-options'>
          {this.props.items.map(it =>
            <MultiSelectOptionItem
              key={it.value}
              {...it}
              selected={selectedValues.indexOf(it.value) !== -1}
              click={this.handleToggle(it)} />)}
        </ul>
      </SelectOption>
    );
  }
}

interface MultiSelectOptionItemProps extends OptionItemData {
  selected: boolean;
  click: (value: any) => void;
}

function MultiSelectOptionItem(props: MultiSelectOptionItemProps) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    props.click(props.value);
  };

  return (
    <li className={className('dso-multiselect-option', { 'dso-selected': props.selected })} onClick={handleClick}>
      {props.label}
    </li>
  );
}

interface IntervalSelectOptionProps extends SelectOptionProps {
  interval: core.FilterIntervalOption;
  selectedInterval: core.FilterIntervalOption;
  select: (interval: core.FilterIntervalOption) => void;
}

interface IntervalSelectOptionState {
  interval: core.FilterIntervalOption;
  selectedInterval: core.FilterIntervalOption;
}

export class IntervalSelectOption extends React.Component<IntervalSelectOptionProps, IntervalSelectOptionState> {

  private slider?: IntervalSlider | null;
  private hasValueSelected = false;
  private unmounted = false;

  constructor(props: IntervalSelectOptionProps) {
    super(props);

    this.state = {
      interval: { ...this.props.interval },
      selectedInterval: {
        ...this.props.selectedInterval,
        min: this.props.selectedInterval.min ?? this.props.interval.min,
        max: this.props.selectedInterval.max ?? this.props.interval.max
      }
    };
  }

  componentDidUpdate(prevProps: IntervalSelectOptionProps) {
    const newSelectedInterval = this.props.selectedInterval;
    const oldSelectedInterval = prevProps.selectedInterval;

    // If interval is changed from props.
    if (newSelectedInterval.min !== oldSelectedInterval.min ||
        newSelectedInterval.max !== oldSelectedInterval.max) {

      const selectedInterval = {
        ...prevProps.selectedInterval,
        min: newSelectedInterval.min ?? this.props.interval.min,
        max: newSelectedInterval.max ?? this.props.interval.max
      };

      // If new interval is changed from stored state interval.
      if (selectedInterval.min !== this.state.selectedInterval.min ||
          selectedInterval.max !== this.state.selectedInterval.max) {

        this.setState({ selectedInterval });

        // Update slider element interval aswell since its values are handled externally.
        if (this.slider) {
          this.slider.setInterval(selectedInterval);
        }
      }
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  getListLabel() {
    const { selectedInterval, interval } = this.state;
    const unit = interval.unit ?? '';

    if (selectedInterval.min === interval.min && selectedInterval.max === interval.max) {
      this.hasValueSelected = false;
      return translate('filter.list.all');
    }

    this.hasValueSelected = true;

    const getValues = () => {
      const values: (string | null)[] = [null, null];

      if (selectedInterval.min !== interval.min) {
        const minVal = selectedInterval.min ?? interval.min!;
        values[0] = interval.formatNumber ? formatNumber(minVal) : minVal + '';
      }

      if (selectedInterval.max !== interval.max) {
        const maxVal = selectedInterval.max ?? interval.max!;
        values[1] = interval.formatNumber ? formatNumber(maxVal) : maxVal + '';
      }

      return values;
    };

    const [min, max] = getValues();

    if (min && max) {
      return `${min} — ${max} ${unit}`;
    }

    if (min) {
      return translateFormat('filter.list.over', min, unit);
    }

    if (max) {
      return translateFormat('filter.list.under', max, unit);
    }

    return null;
  }

  @debounce(200)
  updateSelect() {
    // Since this component is updated after debounce,
    // this will cause an infinite loop if this component is updated after unmount.
    if (this.unmounted) {
      return;
    }

    const { interval, selectedInterval } = this.state;
    const updatedInterval = { ...selectedInterval };

    if (updatedInterval.min === interval.min) {
      updatedInterval.min = null;
    }

    if (updatedInterval.max === interval.max) {
      updatedInterval.max = null;
    }

    this.props.select(updatedInterval);
  }

  @autobind
  handleIntervalUpdate(selectedInterval: core.FilterIntervalOption) {
    this.updateSelect();
    this.setState({ selectedInterval });
  }

  render() {
    const { interval, selectedInterval } = this.state;

    return (
      <SelectOption {...this.props} listLabel={this.getListLabel()} hasValueSelected={this.hasValueSelected}>
        <div className='dso-select-interval'>
          <div className='dso-select-interval-values'>
            <div className='dso-select-interval-value'>
              {interval.min == null
                ? 0
                : interval.formatNumber
                  ? formatNumber(interval.min)
                  : interval.min} {interval.unit}
            </div>

            <div className='dso-select-interval-value'>
              {interval.max == null
                ? 0
                : interval.formatNumber
                  ? formatNumber(interval.max)
                  : interval.max} {interval.unit}
            </div>
          </div>

          <IntervalSlider
            ref={slider => this.slider = slider}
            interval={interval}
            selectedInterval={selectedInterval}
            update={this.handleIntervalUpdate} />
        </div>
      </SelectOption>
    );
  }
}

interface IntervalSliderProps {
  interval: core.FilterIntervalOption;
  selectedInterval: core.FilterIntervalOption;
  update: (interval: core.FilterIntervalOption) => void;
}

class IntervalSlider extends React.Component<IntervalSliderProps> {

  private sliderElm?: HTMLElement;
  private slider?: noUiSlider.noUiSlider;

  componentDidMount() {
    const { interval, selectedInterval } = this.props;
    const hasMin = interval.min != null;
    const hasMax = interval.max != null;

    if (this.sliderElm) {
      const minVal = interval.min ?? 0;
      const maxVal = interval.max ?? 0;

      try {
        this.slider = noUiSlider.create(this.sliderElm, {
          start: this.getInterval(selectedInterval),
          connect: hasMin !== hasMax ? [true, false] : true,
          step: interval.step ?? 1,
          range: {
            min: minVal,
            max: maxVal
          }
        });

        this.slider.on('update', values => {
          let [min, max] = values;

          if (hasMax && !hasMin) {
            max = min;
          }

          this.props.update({
            ...interval,
            min: hasMin ? +min : null,
            max: hasMax ? +max : null
          });
        });
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  componentWillUnmount() {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  private getInterval(selectedInterval: core.FilterIntervalOption) {
    const values: number[] = [];

    if (selectedInterval.min != null) {
      values.push(selectedInterval.min);
    }

    if (selectedInterval.max != null) {
      values.push(selectedInterval.max);
    }

    return values;
  }

  setInterval(selectedInterval: core.FilterIntervalOption) {
    if (this.slider) {
      this.slider.set(this.getInterval(selectedInterval));
    }
  }

  render() {
    return (
      <div className='dso-select-interval-elm' ref={elm => this.sliderElm = elm as HTMLElement}></div>
    );
  }
}