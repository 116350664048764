import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { emporiModule } from './module';
import { App, ViewSectionSettings } from './app';
import { QueryObjectsApp, DsoQuerySettings as DsoQuerySettings } from './queryObjectsApp';
import { endpoint } from './api/vehicleFilterApi';
import { ViewHandlerType, setViewHandlerType, getViewHandler } from './view/handler';
import { getEnumValue } from './util';
import { objectToQueryString } from './query';

export interface EssentialSettings {
  element: string | Element;
  endpoint?: string;
  urlType?: string | ViewHandlerType;
  pageReload?: boolean;
}

interface DsoSettings extends EssentialSettings {
  sectionSettings?: ViewSectionSettings;
}

export function loadEssentialSettings<T extends EssentialSettings>(settings?: T) {
  if (!settings) {
    throw 'Settings object is required';
  }

  const element = typeof settings.element === 'string'
    ? document.querySelector(settings.element)
    : settings.element;

  if (!element) {
    throw 'Root element could not be found';
  }

  if (settings.endpoint) {
    endpoint.setEndpoint(settings.endpoint);
  }

  return { element, settings };
}

function parseSetUrlType(type?: string | ViewHandlerType) {
  let urlType = ViewHandlerType.Search;

  if (type != null) {
    urlType = typeof type === 'string'
      ? (getEnumValue(ViewHandlerType, type) as any) as ViewHandlerType
      : type;
  }

  setViewHandlerType(urlType);
}

function assignDefaultSettings(loadedSettings: DsoSettings) {
  if (getViewHandler().viewType() === ViewHandlerType.Search) {
    if (!loadedSettings.sectionSettings?.filter?.objectLink) {
      let settings = (loadedSettings.sectionSettings || (loadedSettings.sectionSettings = {}));
      let filter = (settings.filter || (settings.filter = {}));

      if (!filter.objectLink) {
        filter.objectLink = object => {
          return objectToQueryString({ item: object.friendlyId ?? object.uniqueId });
        };
      }
    }
  }
}

emporiModule('dealershipOnlineLoaded', {

  /**
   * Render the main full DSO with filter and/or object page.
   *
   * @param settings Settings for the DSO.
   */
  dealershipOnline(settings?: DsoSettings) {
    const { element, settings: loadedSettings } = loadEssentialSettings(settings);

    parseSetUrlType(loadedSettings.urlType);
    assignDefaultSettings(loadedSettings);

    ReactDOM.render(
      <App
        sectionSettings={loadedSettings.sectionSettings}
        pageReload={loadedSettings.pageReload} />,
      element);
  },

  /**
   * Render objects view with custom object query.
   *
   * @param settings Settings to render.
   */
  dealershipOnlineQuery(settings?: DsoQuerySettings) {
    const { element, settings: loadedSettings } = loadEssentialSettings(settings);
    ReactDOM.render(<QueryObjectsApp settings={loadedSettings} />, element);
  }
});