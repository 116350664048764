import * as React from 'react';
import { VehicleExtended, FileResource } from '../../model/core';
import { FaIcon } from '../../icon';
import { translate as trans } from '../../lang';

function fileSize(bytes: number) {
  const exp = Math.log(bytes) / Math.log(1024) | 0;
  const result = (bytes / Math.pow(1024, exp)).toFixed(2);

  return result + ' ' + (exp === 0 ? 'bytes': 'KMGTPEZY'[exp - 1] + 'B');
}

function fileIcon(name: string) {
  let str = name;

  switch(true) {
    case /.pdf/.test(str):
      str = 'file-pdf';
      break;
    case /.jpg|.png/.test(str):
      str = 'image';
      break;
    default:
      str = 'file-alt';
      break;
  }

  return str;
}

interface Props {
  vehicle: VehicleExtended;
}

export function FileResources({ vehicle }: Props) {
  const { files } = vehicle;

  if (!files || !files.length) {
    return null;
  }

  return (
    <div className='dso-files'>
      <h2>{trans('object.files.header')}</h2>
      <ul>
        {files.map(file =>
          <File key={file.name} file={file} />)}
      </ul>
    </div>
  );
}

interface FileProps {
  file: FileResource;
}

function File({ file }: FileProps) {
  return (
    <li>
      <a href={file.url} target='_blank'>
        <FaIcon name={fileIcon(file.name)} />
        <strong>{file.name}</strong>
        <span className='dso-file-size'>{fileSize(file.size ?? 0)}</span>
      </a>
    </li>
  );
}
