import * as webApi from './webapi';
import { autobind } from '../decorator';

export class Endpoint {

  private endpoint = '';

  constructor(defaultEndpoint?: string) {
    this.setEndpoint(defaultEndpoint ?? '');
  }

  setEndpoint(endpoint: string) {
    this.endpoint = endpoint;
  }

  getEndpoint() {
    return this.endpoint;
  }

  @autobind
  get<T>(url: string) {
    return webApi.get<T>(`${this.endpoint}/${url}`);
  }

  @autobind
  post<T>(url: string, payload?: any) {
    return webApi.post<T>(`${this.endpoint}/${url}`, payload || {});
  }

  @autobind
  upload<T>(url: string, files: File[], progressCb?: webApi.UploadProgressCallback) {
    return webApi.upload<T>(`${this.endpoint}/${url}`, files, progressCb);
  }

  @autobind
  getBlob(url: string, fileType: string, payload?:any) {
    return webApi.getBlob(`${this.endpoint}/${url}`, fileType, payload);
  }
}