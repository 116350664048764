import * as React from 'react';

interface FaIconProps {
  name: string;
  cls?: string;
  fas?: boolean;
}

export function FaIcon(props: FaIconProps) {
  let cls = `${props.fas ? 'fas' : 'fa'} fa-${props.name} dso-icon`;

  if (props.cls) {
    cls += ' ' + props.cls;
  }

  return <i className={cls}></i>;
}