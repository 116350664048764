import * as React from 'react';
import * as noUiSlider from 'nouislider';
import { formatNumber, className } from '../../util';
import { Portal } from '../../portal';
import { translate as trans, translateFormat as transFormat } from '../../lang';
import { FaIcon } from '../../icon';
import * as api from '../../api/vehicleFilterApi';

interface CapLoanProps {
  financingAvailable: boolean;
  months: number | null;
}

export function CapLoanRange(age: number, vehicleYear: number, nowYear: Date) {

  if (age === 0) { return null; }
  if (!vehicleYear) { return null; }

  let currentYear = nowYear.getFullYear();
  let yearDiff = currentYear - vehicleYear;

  if (yearDiff > age) {
    return {
      financingAvailable: false,
      months: null
    };
  }

  if (age > yearDiff) {
    let months = (age - yearDiff) * 12;
    return {
      financingAvailable: true,
      months: months
    };
  }

  return null;
};

interface Range {
  min: number;
  max: number;
  step?: number;
}

interface PaymentCalculatorProps {
  price: number;
  interest: number;
  months: Range;
  startMonth: number;
  downPayment: Range;
  initialDownPayment?: number;
  minMonthlyCost?: number;
  lang: string;
  stateFee: number;
  setupFee: number;
  invoiceFee: number;
  vehicleMaxAge: number;
  vehicleYear: number;
  buyOnline: boolean;

  downPaymentChange?: (value: number) => void;
  monthChange?: (value: number) => void;
  updateEffectiveRate: (value: number) => void;
  triggerBuyWindow: (value: boolean) => void;
}

interface PaymentCalculatorState {
  downPayment: number;
  month: number;
  effectiveRate: number;
  showInfoModal: boolean;
  vehicleMaxAge: CapLoanProps | null;
}

export class PaymentCalculator extends React.Component<PaymentCalculatorProps, PaymentCalculatorState> {

  private downPaymentSliderElm?: HTMLElement;
  private downPaymentSlider?: noUiSlider.noUiSlider;
  private loanTimeElm?: HTMLElement;
  private loanTimeSlider?: noUiSlider.noUiSlider;

  private costLimitReached = false;
  private lastCostLimit = false;
  private monthlyCost = 0;
  private downPayment = 0;
  private showEffectiveRate = false;

  constructor(props: PaymentCalculatorProps) {
    super(props);

    this.state = {
      downPayment: (props.initialDownPayment ?? props.downPayment.min) / 100,
      month: props.startMonth,
      effectiveRate: 0,
      showInfoModal: false,
      vehicleMaxAge: CapLoanRange(this.props.vehicleMaxAge, this.props.vehicleYear, new Date())
    };

    this.showEffectiveRate = (props.lang === 'nb');
  }

  private initSliders() {
    if (this.downPaymentSliderElm) {
      this.downPaymentSlider = noUiSlider.create(this.downPaymentSliderElm, {
        start: this.state.downPayment,
        connect: [true, false],
        range: {
          min: Math.max(this.props.downPayment.min / 100, 0),
          max: Math.min(this.props.downPayment.max / 100, 1)
        }
      });

      this.downPaymentSlider.on('update', values => {
        const downPayment = +values[0];
        this.setState({ downPayment });

        if (this.props.downPaymentChange) {
          this.props.downPaymentChange(downPayment * 100);
        }
      });

      this.downPaymentSlider.on('change', values => {
        this.updateEffectiveRate();
      });
    }

    if (this.loanTimeElm) {
      const vehicleMaxAge = this.state.vehicleMaxAge;

      this.loanTimeSlider = noUiSlider.create(this.loanTimeElm, {
        start: this.props.startMonth,
        connect: [true, false],
        step: this.props.months.step ?? 1,
        range: {
          min: this.props.months.min ?? 1,
          max: Math.max(
            vehicleMaxAge && vehicleMaxAge.months && vehicleMaxAge.months > 0
              ? vehicleMaxAge.months
              : this.props.months.max, 1)
        }
      });

      this.loanTimeSlider.on('update', values => {
        const month = +values[0];
        this.setState({ month });

        if (this.props.monthChange) {
          this.props.monthChange(month);
        }
      });

      this.loanTimeSlider.on('change', values => {
        this.updateEffectiveRate();
      });
    }
  }

  private async updateEffectiveRate() {
    if (!this.showEffectiveRate) {
      return;
    }

    try {
      const effectiveRate = await api.getEffectiveRate({
        nrOfMonthlyPayments: this.state.month,
        monthlyPayment: this.monthlyCost,
        invoiceFee: this.props.invoiceFee,
        totalCost: this.props.price,
        downPayment: this.downPayment,
        setupFee: this.props.setupFee,
        stateFee: this.props.stateFee
      });

      this.setState({ effectiveRate });
    } catch (ex) {
      console.error(ex.exceptionMessage);
    } finally {
      if (this.state.effectiveRate) {
        const effectiveRate = this.state.effectiveRate ?? 0;
        this.props.updateEffectiveRate(effectiveRate);
      }
    }
  }

  componentDidMount() {
    try {
      this.initSliders();
      this.updateEffectiveRate();
    } catch (ex) {
      console.error(ex);
    }
  }

  componentDidUpdate() {
    if (this.lastCostLimit === this.costLimitReached) {
      return;
    }

    const updateSliderLimit = (slider: noUiSlider.noUiSlider, max: number) => {
      try {
        slider.updateOptions({
          padding: this.costLimitReached ? [0, +slider.options.range.max - max] as any : 0
        }, true);
      } catch (ex) {
        console.error(ex);
      }
    };

    if (this.downPaymentSlider) {
      updateSliderLimit(this.downPaymentSlider, this.state.downPayment);
    }

    if (this.loanTimeSlider) {
      updateSliderLimit(this.loanTimeSlider, this.state.month);
    }

    this.lastCostLimit = this.costLimitReached;
  }

  render() {
    const downPayment = this.state.downPayment * this.props.price;
    const months = this.state.month;
    const santanderInterest = (this.props.interest / 12) * 0.01;
    const totalLoan = (this.props.price - downPayment);
    const monthlyCost = totalLoan * (santanderInterest / (1 - Math.pow( (1 + santanderInterest), (-months) )) );
    const totalInterestCost = (monthlyCost * months) - totalLoan;
    const totalCreditCost = ((monthlyCost + this.props.invoiceFee) * months)
      + this.props.setupFee + this.props.stateFee - totalLoan;

    this.costLimitReached = this.props.minMonthlyCost != null && monthlyCost < this.props.minMonthlyCost;

    this.monthlyCost = monthlyCost;
    this.downPayment = downPayment;

    const { vehicleMaxAge } = this.state;

    return (
      <div className='dso-payment-calc'>

        {(vehicleMaxAge === null) || (vehicleMaxAge && vehicleMaxAge.financingAvailable) ? <>

          <div className='dso-payment-montly-cost'>
            {transFormat('payment.monthlyCost', formatNumber(monthlyCost))}
          </div>

          <CalculationSummary
            show={this.showEffectiveRate}
            downPaymentPercent={this.state.downPayment * 100}
            nominalRate={this.props.interest}
            effectiveRate={this.state.effectiveRate}
            loanAmount={totalLoan}
            months={months}
            totalCreditCost={totalCreditCost} />

          <div className={className('dso-payment-calc-limit', { 'dso-visible': this.costLimitReached })}>
            <span><FaIcon name='exclamation-triangle' />
              {transFormat('payment.calcInfo.limit', formatNumber(this.props.minMonthlyCost!))}
            </span>
          </div>

          <Slider
            label={trans('payment.downPayment.title')}
            value={transFormat('payment.downPayment.value', formatNumber(downPayment))}
            elm={elm => this.downPaymentSliderElm = elm as HTMLElement} />

          <Slider
            label={trans('payment.loanTime.title')}
            value={transFormat('payment.loanTime.value', formatNumber(this.state.month))}
            elm={elm => this.loanTimeElm = elm as HTMLElement} />

          <CalculationInfo
            show={!this.showEffectiveRate}
            onCalcInfoClick={() =>
              this.props.buyOnline
                ? this.props.triggerBuyWindow(this.props.buyOnline)
                : this.setState({ showInfoModal: true })}/>

        </>:<div className='dso-financing-not-available'>
          <span>{trans('payment.financing.notAvaiable')}</span>
        </div>}

        <Portal
          visible={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}>

          <div className='dso-portal-content-title'>{trans('payment.calcInfo.title')}</div>
          <div className='dso-portal-content-calculation'>
            <div className='dso-portal-content-calculation-field'>
              <div>{transFormat('payment.calcInfo.downPayment', this.props.downPayment.min)}</div>
              <span>{formatNumber(downPayment)} {trans('payment.default.currency')}</span>
            </div>
            <div className='dso-portal-content-calculation-field'>
              <div>{trans('payment.calcInfo.loan')}</div>
              <span>{formatNumber(this.props.price - downPayment)} {trans('payment.default.currency')}</span>
            </div>
            <div className='dso-portal-content-calculation-field'>
              <div>{trans('payment.calcInfo.interestCost')}</div>
              <span>{formatNumber(totalInterestCost)} {trans('payment.default.currency')}</span>
            </div>
            <div className='dso-portal-content-calculation-field total'>
              <div>{trans('payment.calcInfo.total')}</div>
              <span>{formatNumber(this.props.price + totalInterestCost)} {trans('payment.default.currency')}</span>
            </div>
            <div className='dso-portal-content-calculation-summary'>
              {transFormat('payment.calcInfo.summary',
                formatNumber(this.props.price - downPayment),
                this.state.month,
                formatNumber(monthlyCost),
                this.props.interest)}
            </div>
          </div>
        </Portal>
      </div>
    );
  }
}

interface SliderProps {
  elm: (elm: HTMLElement) => void;
  label: string;
  value: string;
}

function Slider(props: SliderProps) {
  return (
    <div className='dso-payment-slider'>
      <div className='dso-payment-slider-label'>{props.label}</div>
      <div className='dso-payment-slider-value'>{props.value}</div>
      <div className='dso-payment-slider-elm' ref={elm => props.elm(elm as HTMLElement)}></div>
    </div>
  );
}

interface CalculationSummaryProps {
  downPaymentPercent: number;
  nominalRate: number;
  effectiveRate: number;
  loanAmount: number;
  months: number;
  totalCreditCost: number;
  show: boolean;
}

export function CalculationSummary(props: CalculationSummaryProps) {
  if (!props.show || props.effectiveRate <= 0) {
    return null;
  }
  return (
    <div className='dso-payment-calc-summary-container'
      dangerouslySetInnerHTML={{
        __html: transFormat('payment.calcSummary.summary',
          trans('payment.default.currency'),
          formatNumber(props.downPaymentPercent),
          formatNumber(props.nominalRate, 2),
          formatNumber(props.effectiveRate, 2),
          formatNumber(props.loanAmount),
          formatNumber(props.months),
          formatNumber(props.totalCreditCost),
          formatNumber((props.totalCreditCost + props.loanAmount))
        )
      }}
    />
  );
}

interface CalculationInfoProps {
  show: boolean;
  onCalcInfoClick: () => void;
}

function CalculationInfo(props: CalculationInfoProps) {
  if (!props.show) {
    return null;
  }
  return (
    <div className='dso-payment-calc-info-container'>
      <a href='#' className='dso-payment-calc-info' onClick={e => {
        e.preventDefault();
        props.onCalcInfoClick();
      }}>{trans('payment.calcInfo.title')}</a>
    </div>
  );
}