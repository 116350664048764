/*
 * Generated from Typing.TypeScriptDefinitionQueue
 */

export interface CreateBooking extends PersonalBooking {
  deliveryDate: Date;
  creditTimeMonths: number;
  downPayment: number;
}

export interface PersonalBooking {
  articleNr: string;
  orgNr: string;
  email: string;
  phone: string;
  exchange: boolean;
  exchangeRegNr: string;
  exchangeMilage: string;
  exchangeMessage: string;
}

export interface CreateBookingResponse {
  hasError: boolean;
  error: string;
  approved: boolean;
  bookingId: string;
  score?: ScoreDecision;
}

export enum ScoreDecision {
  pending = 1,
  approved = 2,
  denied = 3,
  unknown = -1
}

export interface SignCheckResponse {
  hasStatus: boolean;
  authStatus: AuthenticationStatus;
}

export enum AuthenticationStatus {
  unset = 0,
  ok = 1,
  wrong = 2,
  aborted = 3
}

export interface TermsRequest {
  creditAmount: number;
  downPayment?: number;
  interestRate?: number;
  months?: number;
  deliveryDate?: Date;
}

export interface TermsResponse {
  creditCost?: number;
  effectiveRate?: number;
  totalDebt?: number;
  totalInterest?: number;
  totalAdminFee?: number;
  perMonth: TermMonthPayment[];
}

export interface TermMonthPayment {
  payments: number;
  amount: number;
}

export interface ArticleTermsResponse {
  articleNr: string;
  title: string;
  price: number;
  terms: TermsResponse;
}

