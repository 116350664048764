import * as React from 'react';
import { CheckoutModel, ModelInput, CheckoutModelData } from './model';
import { Row } from './common';
import { CheckBox } from './input/checkbox';
import { Text } from './input/text';
import { TextArea } from './input/textArea';

interface Props<T extends CheckoutModel> {
  model: CheckoutModelData<T>;
  change: (key: keyof T, model: ModelInput) => void;
  blur: (key: keyof T) => void;
  disabled: boolean;
}

export function ExchangeForm<T extends CheckoutModel>(props: Props<T>) {
  const { model, change, blur, disabled } = props;
  const showExchange = model.exchange?.value === 'true';

  return (
    <div className='empori-exchange-form'>
      <Row>
        <CheckBox<T>
          label='Jag har ett inbyte'
          id='exchange'
          model={model}
          change={change}
          blur={blur}
          disabled={disabled}
          required={false} />
      </Row>

      {showExchange && <>
        <Row>
          <Text<T>
            label='Reg.nr'
            placeholder='ABC12X'
            id='exchangeRegNr'
            model={model}
            change={change}
            blur={blur}
            required={false}
            disabled={disabled} />

          <Text<T>
            label='Miltal'
            id='exchangeMilage'
            type='number'
            model={model}
            change={change}
            blur={blur}
            required={false}
            disabled={disabled} />
        </Row>

        <Row>
          <TextArea<T>
            label='Ev. meddelande'
            id='exchangeMessage'
            model={model}
            change={change}
            blur={blur}
            required={false}
            disabled={disabled} />
        </Row>
      </>}
    </div>
  );
}