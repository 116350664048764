import * as React from 'react';
import { InputProps, createDefaultModelInput, Input } from './input';
import { CheckoutModel } from '../model';

interface TextProps<T extends CheckoutModel> extends InputProps<T> {
  type?: string;
  placeholder?: string;
  regex?: string;
  autoComplete?: string;
}

export function Text<T extends CheckoutModel>(props: TextProps<T>) {
  const model = props.model[props.id]! || createDefaultModelInput(props);
  const regex = props.regex ? new RegExp(props.regex) : null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (regex && !regex.test(value)) {
      return;
    }

    props.change(props.id, {
      ...model,
      value,
      dirty: value !== model.initialValue
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.blur) {
      props.blur(props.id);
    }
  };

  return (
    <Input {...props}>
      <input
        data-checkout-key={props.id}
        className='empori-input'
        type={props.type || 'text'}
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete={props.autoComplete}
        required={props.required}
        disabled={props.disabled}
        pattern={props.regex}
        value={model.value} />
    </Input>
  );
}

