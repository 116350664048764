import * as React from 'react';
import { Text } from './input/text';
import { CashModel, ModelInput, CashModelData } from './model';
import { CheckBox } from './input/checkbox';
import { translate as trans, LangKey } from '../../lang';
import { ExchangeForm } from './exchangeForm';

interface Props {
  model: CashModelData;
  change: (key: keyof CashModel, model: ModelInput) => void;
  blur: (key: keyof CashModel) => void;
  createBooking: () => void;
  disabled: boolean;
  descKey?: LangKey;
  showExchange?: boolean;
}

export function CashForm(props: Props) {
  const { model, change, blur, disabled, descKey, showExchange } = props;

  return (
    <div className='empori-checkout-form'>
      <div
        className='empori-form-desc'
        dangerouslySetInnerHTML={{__html: trans(descKey ?? 'finance.form.infoHtml')}}>
      </div>

      <Row>
        <Text<CashModel>
          label={trans('finance.form.firstname')}
          id='firstname'
          autoComplete='given-name'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled} />

        <Text<CashModel>
          label={trans('finance.form.lastname')}
          id='lastname'
          autoComplete='family-name'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled} />
      </Row>

      <Row>
        <Text<CashModel>
          label={trans('finance.form.pnr')}
          id='orgNr'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled}
          placeholder={trans('finance.form.pnr.placeholder')} />

        <Text<CashModel>
          label={trans('finance.form.address')}
          id='address'
          autoComplete='address-line1'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled}
          placeholder={trans('finance.form.address.placeholder')} />
      </Row>

      <Row>
        <Text<CashModel>
          label={trans('finance.form.city')}
          id='city'
          autoComplete='address-level2'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled} />

        <Text<CashModel>
          label={trans('finance.form.zipcode')}
          id='zipcode'
          autoComplete='postal-code'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled}
          placeholder={trans('finance.form.zipcode.placeholder')} />
      </Row>

      <Row>
        <Text<CashModel>
          label={trans('finance.form.email')}
          id='email'
          type='email'
          model={model}
          autoComplete='email'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />

        <Text<CashModel>
          label={trans('finance.form.phone')}
          id='phone'
          model={model}
          autoComplete='tel'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />
      </Row>

      <Row>
        <Text<CashModel>
          label={trans('finance.form.deliveryDate')}
          id='deliveryDate'
          model={model}
          type='date'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />
      </Row>

      {showExchange && <ExchangeForm {...props} />}

      <Row>
        <div className='empori-gdpr-container'>
          <CheckBox<CashModel>
            label={trans('finance.form.gdprLabel')}
            id='gdpr'
            model={model}
            change={change}
            blur={blur}
            disabled={disabled}
            required={true}
            text={trans('finance.form.gdprTerms')} />
        </div>
      </Row>

      <Row>
        <input
          className='cash-submit'
          type='submit'
          disabled={disabled}
          onClick={e => {
            e.preventDefault();
            props.createBooking();
          }}
          value={trans('finance.form.reserve')} />
      </Row>

    </div>
  );
}

const Row: React.FC = props =>
  <div className='empori-checkout-row'>{props.children}</div>;