type HttpMethod = 'GET' | 'POST';

function transformJson(this: any, key: string, value: any) {
  if (key && key.length >= 2 && key.indexOf('@') === 0) {
    this[key.substring(1)] = new Date(value);
    delete this[key];
    return;
  }

  return value;
}

function replacerJson(this: any, key: string, value: any) {
  if (value && typeof value === 'object' && !(value instanceof Date)) {
    Object.keys(value).forEach(objKey => {
      const objVal = value[objKey];

      if (objVal instanceof Date) {
        value['@' + objKey] = objVal.toJSON();
      }
    });
  }

  return value;
}

export function request<T>(method: HttpMethod, url: string, body?: any) {
  return new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.addEventListener('error', error => {
      reject(error);
    });

    xhr.addEventListener('load', () => {
      try {
        const { response, status } = xhr;

        if (status !== 200) {
          try {
            reject(JSON.parse(response));
          } catch {
            reject(response);
          }
          return;
        }

        if (!response) {
          resolve(undefined);
          return;
        }

        const json = JSON.parse(response, transformJson);
        resolve(json);
      } catch (ex) {
        reject(ex);
      }
    });

    xhr.open(method, url, true);

    if (body) {
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body, replacerJson));
    } else {
      xhr.send();
    }
  });
}

export async function get<T>(url: string): Promise<T> {
  return await request<T>('GET', url);
}

export async function post<T>(url: string, body: any): Promise<T> {
  return await request<T>('POST', url, body);
}

export interface UploadProgress {
  loaded: number;
  total: number;
  percentage: number;
}

export interface UploadProgressCallback {
  (progress: UploadProgress): void;
}

export async function upload<T>(url: string, files: File[], progressCb?: UploadProgressCallback): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.addEventListener('error', error => {
      reject(error);
    });

    if (progressCb) {
      xhr.addEventListener('progress', event => {
        const { loaded, total } = event;
        const percentage = (event.loaded / event.total) * 100;

        progressCb({
          loaded,
          total,
          percentage
        });
      });
    }

    xhr.addEventListener('load', () => {
      try {
        const { response, status } = xhr;

        if (status !== 200) {
          try {
            reject(JSON.parse(response));
          } catch {
            reject(response);
          }
          return;
        }

        if (!response) {
          resolve(undefined);
          return;
        }

        const json = JSON.parse(response, transformJson);
        resolve(json);
      } catch (ex) {
        reject(ex);
      }
    });

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    xhr.open('POST', url, true);
    xhr.send(formData);
  });
}


export function getBlob(url: string, fileType: string, body?: any) {
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.addEventListener('error', error => {
      reject(error);
    });

    xhr.addEventListener('load', () => {
      try {
        const { response, status } = xhr;

        if (status !== 200) {
          try {
            reject(JSON.parse(response));
          } catch {
            reject(response);
          }
          return;
        }

        if (!response) {
          resolve(undefined);
          return;
        }

        //const json = JSON.parse(response, transformJson);
        //resolve(json);
        const file = new Blob(
          [xhr.response], 
          {type: fileType});
        resolve(file);
      } catch (ex) {
        reject(ex);
      }
    });

    xhr.open('POST', url, true);

    if (body) {
      xhr.responseType = "blob"; //added for getBlob
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body, replacerJson));
    } else {
      xhr.send();
    }
  });
}