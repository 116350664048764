import * as React from 'react';
import * as core from '../../model/core';
import { className } from '../../util';
import { VehicleIcon } from '../vehicleIcon';
import { translateAny } from '../../lang';

interface FilterCategoryProps extends core.FilterCategory {
  selected: boolean;
  click: () => void;
  vehicleType: number;
}

export function FilterCategory(props: FilterCategoryProps) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.click();
  };

  return (
    <a href='#'
      onClick={handleClick}
      className={className('dso-filter-category', { 'dso-selected': props.selected })}>

      <VehicleIcon name={props.icon} cls='dso-filter-category-icon' type={props.vehicleType} />

      <div className='dso-filter-category-title'>
        {translateAny('filter.cat.' + props.id.toLowerCase(), props.title)}
      </div>

    </a>
  );
}