import * as core from '../model/core';
import * as api from '../api/vehicleFilterApi';

export interface InitialPriceCalcProps {
  price: number;
  salePrice: number;
  calcValues: core.ICostCalculation;
}

export function InitialPriceCalc(props: InitialPriceCalcProps) {

  const isCampaign = !!props.salePrice && props.salePrice > 0 && props.salePrice !== props.price;
  const price = props.price;
  const salePrice = props.salePrice || 0;
  const shownPrice = isCampaign ? salePrice : price;

  const months = props.calcValues.loanPaymentYearsMaximum * 12;
  const interest = (props.calcValues.loanInterest / 12) * 0.01;
  const downPayment = shownPrice * (props.calcValues.initialDownPayment * 0.01);
  const totalLoan = shownPrice - downPayment;
  const monthlyCost = totalLoan * (interest / (1 - Math.pow( (1 + interest), (-months) )) );
  const totalInterestCost = (monthlyCost * months) - totalLoan;
  const totalCreditCost = ((monthlyCost + props.calcValues.invoiceFee) * months)
      + props.calcValues.setupFee + props.calcValues.stateFee - totalLoan;

  return {
    price: shownPrice,
    orgPrice: props.price,
    isCampaign: isCampaign,
    months: months,
    interest: interest,
    downPayment: downPayment,
    totalLoan: totalLoan,
    monthlyCost: monthlyCost,
    totalInterestCost: totalInterestCost,
    totalCreditCost: totalCreditCost,
    invoiceFee: props.calcValues.invoiceFee,
    setupFee: props.calcValues.setupFee,
    stateFee: props.calcValues.stateFee
  };
}