import * as React from 'react';
import { className } from '../../util';

interface CheckboxProps {
  checked: boolean;
  label: string;
  toggle: () => void;
}

export function Checkbox(props: CheckboxProps) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.toggle();
  };

  return (
    <div className={className('dso-checkbox', { 'dso-checked': props.checked })} onMouseDown={handleClick}>
      <div className='dso-checkbox-slider'>
        <div className='dso-checkbox-progress'></div>
      </div>

      <label>
        {/* An empty input mainly for accessibility. Not neccessary for function. */}
        {/* <input type='checkbox' checked={props.checked} /> */}
        <span className='dso-checkbox-label'>{props.label}</span>
      </label>
    </div>
  );
}