import { sv } from './sv';
import { en } from './en';
import { nb } from './nb';
import { de } from './de';
import { formatString, isDev } from '../util';

const languages = { en, sv, nb, de };

type Langs = keyof typeof languages;
const defaultLang: Langs = 'sv';
let currLang: Langs = 'sv';

export type LangKey = keyof typeof sv;

export function setLanguage(lang: string) {
  currLang = lang as Langs;

  if (!languages[currLang]) {
    console.warn(`Unknown language "${currLang}"; using default language "${defaultLang}" instead.`);
    currLang = defaultLang;
  }
}

export function translate(key: LangKey, defaultValue?: any): string {
  const lang = (languages as any)[currLang];
  let val = lang[key];

  if (val) {
    return val;
  }

  if (isDev) {
    console.groupCollapsed(`[${currLang}] Missing translation for key "${key}"`);
    console.trace();
    console.groupEnd();
  }

  val = defaultValue ?? (languages as any)[defaultLang][key];
  lang[key] = val;

  return val || key;
}

export function translateAny(key: string, defaultValue?: any) {
  return translate(key as any, defaultValue);
}

export function translateFormat(key: LangKey, ...args: any[]) {
  return formatString(translate(key), ...args);
}
