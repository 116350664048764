import * as React from 'react';

export interface ViewTogglerProps {
  setView: (listview: boolean) => void;
  listView: boolean;
}

export function ViewToggler({listView, setView}: ViewTogglerProps ) {

  const handleToggle = () => {

    // Toggle the values
    if (listView) {
      localStorage.setItem('dsoViewToggle', 'grid');
      setView(false);
    } else {
      localStorage.setItem('dsoViewToggle', 'list');
      setView(true);
    }

  };

  return (
    <div className='dso-view-toggler' onClick={handleToggle}>
      <div className={`dso-view-toggle list ${listView ? 'toggled' : ''}`}>
        <i className='fa fa-list'></i></div>
      <div className={`dso-view-toggle grid ${listView ? '' : 'toggled'}`}>
        <i className='fa fa-th'></i></div>
      <div className={`dso-view-toggle-background ${listView ? '' : 'toggled'}`}></div>
    </div>
  );
}