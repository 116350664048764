import * as React from 'react';
import { className, getEnumValue, getEnumStringValue } from '../../util';
import * as core from '../../model/core';
import { translate } from '../../lang';

export interface SortObject {
  order: core.ObjectOrder;
  direction?: core.OrderDirection;
}

interface OrderOptionsProps {
  vehicleType?: core.VehicleType;
  onSort: (sorting: SortObject) => void;
  selectedSorting: SortObject | null;
}

export function OrderOptions(props: OrderOptionsProps) {
  return (
    <div className='dso-order-options'>
      <span>{translate('filter.sortResult')}</span>
      <OrderOption
        click={props.onSort}
        sorting={{ order: core.ObjectOrder.created }}
        title={translate('filter.sort.created')}
        selectedSorting={props.selectedSorting} />

      <OrderOption
        click={props.onSort}
        sorting={{ order: core.ObjectOrder.price, direction: core.OrderDirection.asc }}
        title={translate('filter.sort.priceAsc')}
        selectedSorting={props.selectedSorting} />

      <OrderOption
        click={props.onSort}
        sorting={{ order: core.ObjectOrder.price, direction: core.OrderDirection.desc }}
        title={translate('filter.sort.priceDesc')}
        selectedSorting={props.selectedSorting} />

      <OrderOption
        click={props.onSort}
        sorting={{ order: core.ObjectOrder.title, direction: core.OrderDirection.asc }}
        title={translate('filter.sort.titleAsc')}
        selectedSorting={props.selectedSorting} />

      {props.vehicleType === core.VehicleType.car && <>
        <OrderOption
          click={props.onSort}
          sorting={{ order: core.ObjectOrder.year, direction: core.OrderDirection.desc }}
          title={translate('filter.sort.yearDesc')}
          selectedSorting={props.selectedSorting} />

        <OrderOption
          click={props.onSort}
          sorting={{ order: core.ObjectOrder.mileage, direction: core.OrderDirection.asc }}
          title={translate('filter.sort.mileageAsc')}
          selectedSorting={props.selectedSorting} />

        <OrderOption
          click={props.onSort}
          sorting={{ order: core.ObjectOrder.effect, direction: core.OrderDirection.desc }}
          title={translate('filter.sort.effectDesc')}
          selectedSorting={props.selectedSorting} />
      </>}
    </div>
  );
}

interface OrderOptionProps {
  sorting: SortObject;
  title: string;
  selectedSorting: SortObject | null;
  click: (sorting: SortObject) => void;
}

export function OrderOption(props: OrderOptionProps) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.click(props.sorting);
  };

  const selected = props.selectedSorting !== null
    && props.sorting.order === props.selectedSorting.order
    && props.sorting.direction === props.selectedSorting.direction;

  const cls = className('dso-order-option', { 'dso-selected': selected });

  return <a href='#' className={cls} onClick={handleClick}>{props.title}</a>;
}

const sortOrderDelimiter = '.';

export function parseSortOrder(sortString: string | undefined): SortObject | null {
  if (!sortString) {
    return null;
  }

  const [orderStr, dirStr] = sortString.split(sortOrderDelimiter);
  const order = getEnumValue(core.ObjectOrder, orderStr);
  const dir = getEnumValue(core.OrderDirection, dirStr);

  if (order != null) {
    return {
      order: (order as any) as core.ObjectOrder,
      direction: (dir as any) as core.OrderDirection
    };
  }

  return null;
}

export function sortOrderToString(sortObject: SortObject | null): string {
  if (!sortObject) {
    return '';
  }

  const sortOrder: string[] = [getEnumStringValue(core.ObjectOrder, sortObject.order)!];

  if (sortObject.direction != null) {
    sortOrder.push(getEnumStringValue(core.OrderDirection, sortObject.direction)!);
  }

  return sortOrder.join(sortOrderDelimiter);
}