import * as React from 'react';
import * as core from '../../model/core';
import { FaIcon } from '../../icon';
import { translate as trans, translateAny as transAny, translateFormat as transFormat } from '../../lang';
import { className } from '../../util';

interface VehicleObjectSpecProps {
  value: string | number;
  icon: string;
}

function VehicleObjectSpec(props: VehicleObjectSpecProps) {
  const { value, icon } = props;
  return (
    <div className='dso-object-spec'>
      <div className='dso-object-spec-value'>
        <FaIcon name={icon} />{value}
      </div>
    </div>
  );
}

interface ThumbnailSpecProps {
  vehicle: core.Vehicle;
}

export function VehicleThumbnailSpec(props: ThumbnailSpecProps) {
  const { vehicle } = props;

  return(
    <div key={vehicle.uniqueId} className='dso-object-specs-filter-container'>

      {/* Cars */}
      {vehicle.type === core.VehicleType.car && <>
        {vehicle.milage != null && <>
          <VehicleObjectSpec
            key={'car-milage'}
            value={vehicle.milage + ' ' + trans('object.specItem.milageUnit').toLowerCase()}
            icon={'road'}/>
        </>}
        {vehicle.effect != null && vehicle.effect > 0 && <>
          <VehicleObjectSpec
            key={'car-effect'}
            value={vehicle.effect + ' ' + trans('filter.input.effect.unit').toLowerCase()}
            icon={'dashboard'} />
        </>}
      </>}

      {/* Mc/Atv */}
      {vehicle.type === core.VehicleType.mcAtv && <>
        {vehicle.category !== null && <>
          <VehicleObjectSpec
            key={'mcatv-category'}
            value={transAny('filter.cat.' + vehicle.category.toLowerCase(), vehicle.category)}
            icon={'cube'} />
        </>}
        {vehicle.milage != null && <>
          <VehicleObjectSpec
            key={'mcatv-milage'}
            value={vehicle.milage + ' ' + trans('object.specItem.milageUnit').toLowerCase()}
            icon={'road'}/>
        </>}
        {vehicle.specifications
          .filter(item => item.title.includes('Motortyp'))
          .map(spec => spec.value !== null && <>
            <VehicleObjectSpec
              key={'mcatv-motortype'}
              value={spec.value}
              icon={'cogs'} />
        </>)}
        {vehicle.effect != null && vehicle.effect > 0 && <>
          <VehicleObjectSpec
            key={'mcatv-effect'}
            value={vehicle.effect + ' ' + trans('filter.input.effect.unit').toLowerCase()}
            icon={'dashboard'} />
        </>}
      </>}

      {/* Snow mobiles */}
      {vehicle.type === core.VehicleType.snowmobile && <>
        {vehicle.specifications
          .filter(item => item.title.includes('Motortyp'))
          .map(spec => spec.value !== null && <>
            <VehicleObjectSpec
              key={'snowmobile-motortype'}
              value={spec.value}
              icon={'cogs'} />
        </>)}
        {vehicle.effect != null && vehicle.effect > 0 && <>
          <VehicleObjectSpec
            key={'snowmobile-effect'}
            value={vehicle.effect + ' ' + trans('filter.input.effect.unit').toLowerCase()}
            icon={'dashboard'} />
        </>}
      </>}

      {/* Trailers */}
      {vehicle.type === core.VehicleType.trailer && <>
        {vehicle.specifications
          .filter(findArr => findArr.title.includes('Totalvikt'))
          .map(spec => spec.value !== '0 kg' &&
            <VehicleObjectSpec
              key={'trailer-weight'}
              value={spec.value}
              icon={'balance-scale'} />
          )}
        {vehicle.specifications
          .filter(findArr => findArr.title.includes('Längd'))
          .map(spec => spec.value !== '0 cm' &&
            <VehicleObjectSpec
              key={'trailer-length'}
              value={spec.value}
              icon={'expand'} />
          )}
      </>}

      {/* Caravans */}
      {vehicle.type === core.VehicleType.caravan && <>
        {vehicle.beds > 0 && <>
          <VehicleObjectSpec
            key={'caravan-beds'}
            value={vehicle.beds}
            icon={'bed'} />
        </>}
        {vehicle.layout !== null && <>
          <VehicleObjectSpec
            key={'caravan-layout'}
            value={transAny('filter.input.layouts.' + vehicle.layout.toLowerCase())}
            icon={'expand'} />
        </>}
        {vehicle.specifications
          .filter(item => item.title.includes('Totalvikt'))
          .map(spec => spec.value !== '0 kg' &&
            <VehicleObjectSpec
              key={'caravan-total-weight'}
              value={spec.value}
              icon={'balance-scale'} />
          )}
      </>}

      {/* Mobile homes */}
      {vehicle.type === core.VehicleType.mobileHome && <>
        {vehicle.milage > 0 && <>
          <VehicleObjectSpec
            key={'mobile-home-milage'}
            value={vehicle.milage + ' ' + trans('object.specItem.milageUnit').toLowerCase()}
            icon={'road'} />
        </>}
        {vehicle.essentialSpecs
          .filter(item => item.title.includes('Växelåda'))
          .map(spec => spec.value !== null && <>
            <VehicleObjectSpec
              key={'mobile-home-gear'}
              value={spec.value === 'Automatic'
                ? trans('object.specItem.gearAuto')
                : trans('object.specItem.gearManual')}
              icon={'code-fork'} />
        </>)}
        {vehicle.specifications
          .filter(item => item.title.includes('Totalvikt'))
          .map(spec => spec.value !== '0 kg' &&
            <VehicleObjectSpec
              key={'mobile-home-total-weight'}
              value={spec.value}
              icon={'balance-scale'} />
          )}
        {vehicle.layout !== null && <>
          <VehicleObjectSpec
            key={'mobile-home-layout'}
            value={transAny('filter.input.layouts.' + vehicle.layout.toLowerCase())}
            icon={'expand'} />
        </>}
      </>}

      {/* Boats */}
      {vehicle.type === core.VehicleType.boat && <>
        {vehicle.category !== null && <>
          <VehicleObjectSpec
            key={'boat-category'}
            value={transAny('filter.cat.' + vehicle.category.toLowerCase(), vehicle.category)}
            icon={'cube'} />
        </>}
        {vehicle.specifications
          .filter(findArr => findArr.title.includes('Längd'))
          .map(spec => spec.value !== '0 cm' &&
            <VehicleObjectSpec
              key={'boat-length'}
              value={spec.value}
              icon={'expand'} />
          )}
        {vehicle.passengers > 0 && <>
          <VehicleObjectSpec
            key={'boat-passengers'}
            value={vehicle.passengers}
            icon={'users'} />
        </>}
        {vehicle.effect != null && vehicle.effect > 0 && <>
          <VehicleObjectSpec
            key={'boat-effect'}
            value={vehicle.effect + ' ' + trans('filter.input.effect.unit').toLowerCase()}
            icon={'dashboard'} />
        </>}
      </>}

      {/* Jet skis */}
      {vehicle.type === core.VehicleType.seascooter && <>
        {vehicle.specifications
          .filter(item => item.title.includes('Motortyp'))
          .map(spec => spec.value !== null && <>
            <VehicleObjectSpec
              key={'jetski-motortype'}
              value={spec.value}
              icon={'cogs'} />
        </>)}
        {vehicle.effect != null && vehicle.effect > 0 && <>
          <VehicleObjectSpec
            key={'jetski-effect'}
            value={vehicle.effect + ' ' + trans('filter.input.effect.unit').toLowerCase()}
            icon={'dashboard'} />
        </>}
      </>}
    </div>
  );
}