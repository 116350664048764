/* eslint-disable id-blacklist */
import { string, object, setLocale, ObjectSchema, ValidationError } from 'yup';
import { InterestForm } from '../../../model/core';

setLocale({
  mixed: {
    required: 'Mixed'
  },
  string: {
    email: 'Email'
  }
});

export const interestFormSchemaAll = object<InterestForm>({
  firstName: string().required(),
  lastName:  string().required(),
  email:     string().required().email(),
  phone:     string().required(),
  message:   string(),
  uniqueId:  string().required()
});

export const interestFormSchemaLite = object<InterestForm>({
  firstName: string(),
  lastName:  string(),
  email:     string().required().email(),
  phone:     string().required(),
  message:   string(),
  uniqueId:  string().required()
});

export const interestFormSchemaCompact = object<InterestForm>({
  firstName: string(),
  lastName:  string(),
  email:     string().email(),
  phone:     string(),
  message:   string(),
  uniqueId:  string().required()
});

export function validateFields<T extends object | null | undefined = {}>(form: T, schema: ObjectSchema<T>) {

  const result: Partial<T> = {};
  let errorThrown = false;

  Object.keys(form ?? {}).forEach(key => {
    const value = (form as any)[key] as string | undefined;

    try {
      schema.validateSyncAt(key, { [key]: value } as any);
    } catch (ex) {
      const error = ex as ValidationError;
      errorThrown = true;

      if (error.message) {
        (result as any)[key] = error.message;
      }
    }
  });

  return { result, errorThrown };
}