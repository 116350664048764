import * as webapi from '../../../api/webapi';
import {
  CreateBooking,
  CreateBookingResponse,
  ContractSignResponse,
  SignatureMethod,
  SignBookingResponse,
  ArticleResponse,
  DigitalSignStatusResponseValue,
  InfoTextResponse,
  CreateBookingCalculation
} from '../../santander.core';

const endpoint = '/santanderApi/v1/';

const get = async <T>(url: string) => await webapi.get<T>(endpoint + url);
const post = async <T>(url: string, body: any) => await webapi.post<T>(endpoint + url, body);

export async function getArticle(articleNr: string) {
  return await get<ArticleResponse>(`article/${articleNr}`);
}

export async function getArticleInfoTexts(articleNr: string, calc?: Partial<CreateBookingCalculation>) {
  return await post<InfoTextResponse>(`article/${articleNr}/info`, calc ?? {});
}

export async function createFinancing(booking: Partial<CreateBooking>) {
  return await post<CreateBookingResponse>('financing', booking);
}

export async function getBooking(bookingId: string) {
  return await get<CreateBookingResponse>(`booking/${bookingId}`);
}

export async function getBookingSign(bookingId: string) {
  return await get<ContractSignResponse>(`booking/${bookingId}/sign`);
}

export async function getBookingSignUrl(bookingId: string, method: SignatureMethod) {
  return await get<SignBookingResponse>(`booking/${bookingId}/sign/${method}`);
}

export async function getSignStatus(bookingId: string) {
  return await get<DigitalSignStatusResponseValue>(`booking/${bookingId}/signstatus`);
}

export async function reserveBooking(bookingId: string) {
  return await post<boolean>(`booking/${bookingId}/reserve`, {});
}