import * as React from 'react';
import * as core from '../../../model/core';
import * as validation from './validation';
import { autobind } from '../../../decorator';
import * as api from '../../../api/vehicleFilterApi';
import { InterestInput, DsoCheckbox, ActionButton, InterestInputMessage } from './input';
import { Warning } from '../../../warning';
import { translate as trans, translateFormat as transFormat } from '../../../lang';
import * as gtm from '../../../gtm';

interface InterestFormCompactProps {
  vehicle: core.VehicleExtended;
}

interface InterestFormCompactState {
  showEmail: boolean;
  showPhone: boolean;
  showIntegrityModal: boolean;
  integrityPolicy: boolean;
  interestForm: core.InterestForm;
  interestFormValidation: Partial<core.InterestForm>;
  showInputFieldModal: boolean;
  formPosted: boolean;
}

export class InterestFormCompact extends React.Component<InterestFormCompactProps, InterestFormCompactState> {

  public element: HTMLElement | null = null;

  constructor(props: InterestFormCompactProps) {
    super(props);

    this.state = {
      showEmail: false,
      showPhone: false,
      showIntegrityModal: false,
      integrityPolicy: false,
      interestForm: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        uniqueId: props.vehicle.uniqueId
      },
      interestFormValidation: {},
      showInputFieldModal: false,
      formPosted: false
    };
  }

  async validateFields() {
    const schema = validation.interestFormSchemaCompact;
    const { interestForm, integrityPolicy } = this.state;

    if (interestForm.firstName.indexOf(' ') > -1) {
      // TODO: use state
      interestForm.lastName = interestForm.firstName.substr(interestForm.firstName.indexOf(' '));
      interestForm.firstName = interestForm.firstName.substr(0, interestForm.firstName.indexOf(' '));
    }

    const { result, errorThrown } = validation.validateFields(interestForm, schema);
    this.setState({ interestFormValidation: result });
    if (!this.state.showEmail && !this.state.showPhone) {
      this.setState({showInputFieldModal: true});
    } else {
      if (integrityPolicy && !errorThrown) {
        const formPosted = await this.postForm();

        if (formPosted) {
          gtm.interestFormSubmit();
        }

        this.setState({ formPosted });
      } else {
        this.setState({ showIntegrityModal: !integrityPolicy });
      }
    }
  }

  @autobind
  async postForm() {
    return await api.postInterest(this.state.interestForm);
  }

  @autobind
  handleChange(key: keyof core.InterestForm) {
    return (text: string) => {
      this.setState({
        interestForm: {
          ...this.state.interestForm,
          [key]: text
        },
        interestFormValidation: {
          ...this.state.interestFormValidation,
          [key]: ''
        }
      });
    };
  }

  render() {
    const { vehicle } = this.props;
    const {
      interestForm,
      interestFormValidation,
      integrityPolicy,
      showIntegrityModal,
      showEmail,
      showPhone,
      formPosted
    } = this.state;

    if (formPosted) {
      return (
        <div className='dso-object-notification-of-interest-container-compact'>
          <h2>{trans('form.interest.title')}</h2>
          <div className='dso-object-notification-of-interest-inner-container'>
            <div className='dso-object-notification-of-interest-row'>
              <div className='dso-object-notification-of-interest-form-posted-container'>
                {trans('form.interest.submitted')}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='dso-object-notification-of-interest-container-compact' ref={elm => this.element = elm}>
        <div className='dso-object-notification-of-interest-inner-container'>
          <div className='dso-object-notification-of-interest-row'>
            <div className='dso-object-notification-of-interest-header-container'>
              {trans('form.interest.title')}
            </div>
          </div>

          <div className='dso-object-notification-of-interest-row'>
            <div className='dso-object-notification-of-interest-info-text'>
              {trans('form.interest.infoHtml')}
            </div>
          </div>

          <div className='dso-object-notification-of-interest-row'>
            <div className='dso-object-notification-of-interest-contact-choices'>
              <div className='dso-object-notification-of-interest-contact-choice'>
                <DsoCheckbox
                  toggle={() => this.setState({ showEmail: !showEmail })}
                  checked={showEmail}
                  cssClass='checkboxAlt'
                  id='contactViaEmail'>
                  {trans('form.interest.contactEmail')}
                </DsoCheckbox>
              </div>

              <div className='dso-object-notification-of-interest-contact-choice'>
                <DsoCheckbox
                  toggle={() => this.setState({ showPhone: !showPhone })}
                  checked={showPhone}
                  cssClass='checkboxAlt'
                  id='contactViaPhone'>
                  {trans('form.interest.contactEmail')}
                </DsoCheckbox>
              </div>
            </div>
          </div>

          <div className='dso-object-notification-of-interest-row'>
            <div className='dso-object-notification-of-interest-column'>
              <InterestInput
                name={trans('form.interest.name')}
                change={this.handleChange('firstName')}
                error={interestFormValidation.firstName}
                value={interestForm.firstName}
                cssClass='dso-input-container-alt'
                autoComplete='name'
                visible={true} />

              <InterestInput
                name={trans('finance.form.email')}
                change={this.handleChange('email')}
                error={interestFormValidation.email}
                value={interestForm.email}
                cssClass='dso-input-container-alt dso-default-hidden'
                autoComplete='email'
                visible={showEmail} />

              <InterestInput
                name={trans('finance.form.phone')}
                change={this.handleChange('phone')}
                error={interestFormValidation.phone}
                value={interestForm.phone}
                cssClass='dso-input-container-alt dso-default-hidden'
                autoComplete='tel'
                visible={showPhone} />
            </div>
          </div>

          <div className='dso-object-notification-of-interest-row'>
            <div className='dso-object-notification-of-interest-column'>
              <InterestInputMessage
                name={trans('finance.form.message')}
                change={this.handleChange('message')}
                error={interestFormValidation.message}
                value={interestForm.message}
                cssClass='dso-input-container-alt'
                visible={true} />
            </div>
          </div>

          <div className='dso-object-notification-of-interest-row'>
            <div className='dso-object-notification-of-interest-customer-accept-terms'>
              <DsoCheckbox
                toggle={() => this.setState({ integrityPolicy: !integrityPolicy })}
                checked={integrityPolicy}
                cssClass='checkboxAlt'
                id='integrityPolicy'>

                {transFormat('form.interest.policy', vehicle.dealershipName)}
              </DsoCheckbox>
            </div>
          </div>

          <div className='dso-object-notification-of-interest-row'>
            <div className='dso-object-notification-of-interest-send-button'>
              <ActionButton
                label={trans('form.interest.send')}
                click={() => { this.validateFields(); }} />

              <Warning
                visible={showIntegrityModal}
                onClose={() => this.setState({ showIntegrityModal: false })}>
                {transFormat('form.interest.policyWarning', vehicle.dealershipName)}
              </Warning>
              <Warning
                visible={this.state.showInputFieldModal}
                onClose={() => this.setState({ showInputFieldModal: false })}>
                {transFormat('form.interest.policyWarningChoise', vehicle.dealershipName)}
              </Warning>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
