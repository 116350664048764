import * as api from '../../../api/webapi';
import {
  CreateBooking,
  CreateBookingResponse,
  SignCheckResponse,
  TermsRequest,
  TermsResponse,
  ArticleTermsResponse
} from '../../ecster.core';

const url = '/ecsterApi/finance/v1';

export async function createBooking(booking: CreateBooking) {
  return api.post<CreateBookingResponse>(`${url}/booking/create`, booking);
}

export async function applyBooking(bookingId: string) {
  return api.post<CreateBookingResponse>(`${url}/booking/${bookingId}/apply`, {});
}

export async function bookingSignUrl(bookingId: string) {
  return api.get<string>(`${url}/booking/${bookingId}/signurl`);
}

export async function bookingSignCheck(bookingId: string) {
  return api.get<SignCheckResponse>(`${url}/booking/${bookingId}/signcheck`);
}

export async function getArticleTerms(articleNr: string) {
  return api.get<ArticleTermsResponse>(`${url}/article/${articleNr}/terms`);
}
