/* eslint-disable id-blacklist */
import { addMethod, string, object, StringSchema, setLocale, ValidationError } from 'yup';
import { validateOrgNum, isValidRegNr } from '../../../util';
import { FinanceModel, ModelInput, CashModel, CheckoutModel, PaymentMethod } from '../model';
import { translate } from '../../../lang';

setLocale({
  mixed: {
    required: translate('form.interest.error.mandatory')
  },
  string: {
    email: translate('form.interest.error.email')
  }
});

addMethod(string, 'validRegNr', function (this: StringSchema<string | undefined>, message?: string) {
  return this.test('validRegNr', message || 'Ogiltigt regnr.', (val: string) => !val || isValidRegNr(val));
});

addMethod(string, 'validOrgNr', function (this: StringSchema, message?: string) {
  return this.test('validOrgNr', message || 'Ogiltigt nummer angivet', (val: string) => validateOrgNum(val));
});

addMethod(string, 'nonRequired', function (this: StringSchema, regex: RegExp, message: string) {
  return this.test('nonRequired', message, (value: string) => {
    if (value && value.trim().length > 0) {
      return regex.test(value);
    }

    return true;
  });
});

declare module 'yup' {

  interface StringSchema {
    validRegNr(this: StringSchema<string | undefined>, message?: string): StringSchema<string | undefined>;
    validOrgNr(this: StringSchema, message?: string): StringSchema;
    nonRequired(this: StringSchema, regex: RegExp, message: string): StringSchema;
  }
}

export const checkoutSchema = {
  finance: object<FinanceModel>({
    orgNr:            string().required().validOrgNr(),
    email:            string().required().email(),
    phone:            string().required(),
    deliveryDate:     string().required().matches(/^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/, 'Ogiltigt datum'),
    gdpr:             string().required().equals(['true'], 'Medgivande krävs'),
    consent:          string().required().equals(['true'], 'Medgivande krävs på alla punkter'),
    exchange:         string().optional(),
    exchangeRegNr:    string().optional().validRegNr(),
    exchangeMilage:   string().optional(),
    exchangeMessage:  string().optional()
  }),

  cash: object<CashModel>({
    orgNr:            string().required().validOrgNr(),
    email:            string().required().email(),
    phone:            string().required(),
    deliveryDate:     string().required().matches(/^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/, 'Ogiltigt datum'),
    firstname:        string().required(),
    lastname:         string().required(),
    address:          string().required(),
    city:             string().required(),
    zipcode:          string().required(),
    gdpr:             string().required().equals(['true'], 'Medgivande krävs'),
    exchange:         string().optional(),
    exchangeRegNr:    string().optional().validRegNr(),
    exchangeMilage:   string().optional(),
    exchangeMessage:  string().optional()
  })
};

export type CheckoutKey = keyof typeof checkoutSchema;

const paymentCheckoutType: { [key: number]: CheckoutKey } = {
  [PaymentMethod.finance]: 'finance',
  [PaymentMethod.cash]: 'cash'
};

/**
 * Validate a field based on its input model and assign the validation error message to the model.
 *
 * @param paymentType Payment type of the checkout model.
 * @param key Field key to check against in the model.
 * @param model Model input data.
 */
export function validateField<T extends CheckoutModel>(
  paymentType: PaymentMethod,
  key: keyof T,
  model: ModelInput) {

  const checkoutKey = paymentCheckoutType[paymentType];

  try {
    // Validate a single field.
    checkoutSchema[checkoutKey].validateSyncAt(key as string, { [key]: model.value } as any);
    model.error = null;
  } catch (e) {
    let error = e as ValidationError;

    if (error.path === key) {
      model.error = error.message;
    }

    return false;
  }

  return true;
}

/**
 * Check if some validation error is present in a field input model.
 * Ignores the validation error message.
 *
 * @param paymentType Payment type of the checkout model.
 * @param key Field key to check against in the model.
 * @param model Model input data.
 */
export function validateFieldSilent<T extends CheckoutModel>(
  paymentType: PaymentMethod,
  key: keyof T,
  model: ModelInput) {

  const checkoutKey = paymentCheckoutType[paymentType];

  try {
    // Validate a single field.
    checkoutSchema[checkoutKey].validateSyncAt(key as string, { [key]: model.value } as any);
  } catch (e) {
    return false;
  }

  return true;
}