import * as React from 'react';
import * as core from '../../model/core';
import { VehicleObject } from '../filter/vehicleObject';
import { translate as trans, translateFormat as transFormat } from '../../lang';

interface SimilarObjectsProps {
  objects: core.Vehicle[] | null | undefined;
  objectClick: (object: core.Vehicle) => void;
  objectLink?: (object: core.Vehicle) => string | undefined | null;
  hasStatus?: boolean;
  objectType?: number;
  storageVehicles: string;
  updateStorage: (value: string) => void;
  listView: boolean | undefined;
  showSuggestedMonthCost: boolean;
}

export function SimilarObjects(props: SimilarObjectsProps) {

  const setListView = localStorage.getItem('dsoViewToggle') == null
    ? !props.listView
    : localStorage.getItem('dsoViewToggle') === 'list';

  if (!props.objects || !props.objects.length) {
    return null;
  }

  return (
    <div className='dso-object-simmilar-objects'>
      <h2>{transFormat('object.similarTitle', trans(
        props.objectType === core.VehicleType.boat ||
        props.objectType === core.VehicleType.seascooter
          ? 'general.object'
          : 'general.vehicle'
      ).toLocaleLowerCase())}</h2>
      <div className={`dso-article-objects ${!setListView ? 'dso-article-box-view' : '' }`}>
        {props.objects.map(obj =>
          <VehicleObject
            key={obj.uniqueId}
            link={props.objectLink?.(obj)}
            object={obj}
            select={props.objectClick}
            storageVehicles={props.storageVehicles}
            updateStorage={(str) => props.updateStorage!(str)}
            showSuggestedMonthCost={props.showSuggestedMonthCost} />)}
      </div>
    </div>
  );
}