import { debounce as doDebounce } from './util';

export function autobind(target: any, propKey: string): TypedPropertyDescriptor<any> {
  const func = target[propKey];

  return {
    get() {
      const bound = func.bind(this);

      Object.defineProperty(this, propKey, {
        configurable: true,
        enumerable: false,
        writable: true,
        value: bound
      });

      return bound;
    }
  };
}

export interface DebounceSettings {
  immediate?: boolean;
  autoBind?: boolean;
}

export function debounce(wait: number, { autoBind, immediate }: DebounceSettings = {}) {
  return function (target: any, propKey: string): TypedPropertyDescriptor<any> {
    const func = target[propKey];

    return {
      get() {
        let debFunc = doDebounce(func, wait, immediate);

        if (autoBind) {
          debFunc = debFunc.bind(this);
        }

        Object.defineProperty(this, propKey, {
          configurable: true,
          enumerable: false,
          writable: true,
          value: debFunc
        });

        return debFunc;
      }
    };
  };
}
