import * as React from 'react';
import { Text } from './input/text';
import { FinanceModelData, FinanceModel, ModelInput, CheckoutModel, PaymentMethod } from './model';
import { CheckBox } from './input/checkbox';
import { translate as trans } from '../../lang';
import { autobind } from '../../decorator';
import { InputProps, Input } from './input/input';
import { className } from '../../util';
import { validateFieldSilent } from './input/validation';
import { ExchangeForm } from './exchangeForm';
import { Row } from './common';

interface Props {
  model: FinanceModelData;
  change: (key: keyof FinanceModel, model: ModelInput) => void;
  blur: (key: keyof FinanceModel) => void;
  createBooking: () => void;
  disabled: boolean;
  hideInputs?: boolean;
  showExchange?: boolean;
}

export function EcsterForm(props: Props) {
  const { model, change, blur, disabled, hideInputs, showExchange } = props;

  const submitButtonDisabled = disabled ||
    !validateFieldSilent<FinanceModel>(PaymentMethod.finance, 'gdpr', model.gdpr!) ||
    !validateFieldSilent<FinanceModel>(PaymentMethod.finance, 'consent', model.consent!);

  return (
    <div className='empori-checkout-form'>
      <div
        className='empori-form-desc'
        dangerouslySetInnerHTML={{ __html: trans('checkout.ecster.financeDesc') }}>
      </div>

      <Row>
        <Text<FinanceModel>
          label={trans('finance.form.pnr')}
          id='orgNr'
          model={model}
          change={change}
          blur={blur}
          required={true}
          disabled={disabled}
          placeholder={trans('finance.form.pnr.placeholder')} />

        <Text<FinanceModel>
          label={trans('finance.form.email')}
          id='email'
          type='email'
          model={model}
          autoComplete='email'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />
      </Row>

      <Row>
        <Text<FinanceModel>
          label={trans('finance.form.phone')}
          id='phone'
          model={model}
          autoComplete='tel'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />

        <Text<FinanceModel>
          label={trans('finance.form.deliveryDate')}
          id='deliveryDate'
          model={model}
          type='date'
          change={change}
          blur={blur}
          disabled={disabled}
          required={true} />
      </Row>

      {showExchange && <ExchangeForm {...props} />}

      <Row>
        <div className='empori-gdpr-container'>
          <CheckBox<FinanceModel>
            label={trans('finance.form.gdprLabel')}
            id='gdpr'
            model={model}
            change={change}
            blur={blur}
            disabled={disabled}
            required={true}
            text={trans('finance.form.gdprTerms')} />

          <Consent
            textGroup={[{
              key: 'terms',
              text: trans('checkout.ecster.financeTermsHtml')
            }]}
            label='Medgivande Ecster'
            id='consent'
            model={model}
            change={change}
            disabled={disabled}
            required={true} />
        </div>
      </Row>

      <Row>
        <input
          className={className('bankid-input', { 'disable-inputs': hideInputs, 'disable-button': submitButtonDisabled })}
          type='submit'
          disabled={submitButtonDisabled}
          onClick={e => {
            e.preventDefault();
            props.createBooking();
          }}
          value={trans('checkout.ecster.submitButton')} />

        <div className='empori-checkout-vendors'>
          <img className='bankid' src='//cdn.empori.se/vendor/bankid/bankid-logo.png' />
          <img className='ecster-logo' src='//cdn.empori.se/vendor/ecster/ecster_logo.svg' />
        </div>
      </Row>

    </div>
  );
}

interface TextGroup {
  key: string;
  text: string;
}

interface ConsentProps extends InputProps<FinanceModel> {
  textGroup: TextGroup[];
}

interface ConsentState {
  model: Partial<ConsentModel>;
}

interface ConsentObj {
  [key: string]: ModelInput | undefined;
}

interface ConsentModel extends CheckoutModel {
  texts: ConsentObj;
}

class Consent extends React.Component<ConsentProps, ConsentState> {

  constructor(props: ConsentProps) {
    super(props);
    this.state = {
      model: {}
    };
  }

  @autobind
  handleChange(key: string, model: ModelInput) {
    this.setState({
      model: {
        ...this.state.model,
        texts: {
          ...this.state.model.texts,
          [key]: model
        }
      }
    }, () => {
      const keys = this.props.textGroup.map(tg => this.state.model.texts?.[tg.key]?.value);

      this.props.change(this.props.id, {
        ...model,
        value: (keys.length === this.props.textGroup.length && keys.every(k => k === 'true')) + ''
      });
    });
  }

  render() {
    return (
      <Input {...this.props} cls='empori-consent-container'>
        {this.props.textGroup.map(tg =>
          <CheckBox<ConsentModel>
            key={tg.key}
            label={tg.text}
            id={tg.key as any}
            model={this.state.model.texts ?? {} as any}
            change={this.handleChange}
            disabled={this.props.disabled}
            text={tg.text} />
        )}
      </Input>
    );
  }
}
