import * as React from 'react';
import { PaymentMethod } from './model';
import { translate } from '../../lang';
import { BuyOnlineType } from '../../model/core';

interface PaymentTogglerProps {
  paymentMethod: PaymentMethod;
  buyType: BuyOnlineType;
  select: (payment: PaymentMethod) => void;
}

export function PaymentToggler(props: PaymentTogglerProps) {
  if (props.buyType !== (BuyOnlineType.cash | BuyOnlineType.finance)) {
    return null;
  }

  const handleClick = () => {
    props.select(props.paymentMethod === PaymentMethod.finance
      ? PaymentMethod.cash
      : PaymentMethod.finance);
  };

  return (
    <div className='empori-payment-toggler' onClick={handleClick}>
      <div className='empori-payment-toggler-cash'>{translate('checkout.payment.cash')}</div>
      <div className='empori-payment-toggler-financing'>{translate('checkout.payment.finance')}</div>
      <div className='empori-payment-toggler-background'></div>
    </div>
  );
}